import { Theme } from "@mui/material";
import styled from "styled-components";
import { withStyles, Container } from "@material-ui/core";
import List from "@material-ui/core/List";

interface StyledContainerProps {
  isSubstituteOverlay?: boolean;
}

export const StyledListItemDiv = styled.div`
  margin: 0;
  width: 55%;
  float: left;
  padding-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledListItemValueDiv = styled.div`
  margin: 0;
  width: 25%;
  float: right;
  padding-bottom: 4px;
`;

export const StyledTitleDiv = styled.div`
  padding-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const useStyles = (theme: Theme) => {
  return {
    listItem: {
      paddingRight: "20px",
    },
    listItemSelected: {
      color: theme.palette.primary.main,
      paddingRight: "20px",
    },
    closeStyle: {
      color: theme.palette.background.default,
    },
    analyticsTitleStyle: {
      paddingBottom: 5,
    },
  };
};
export const StyledContainer = withStyles((theme) => ({
  root: (props: StyledContainerProps) => ({
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.65)",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: props.isSubstituteOverlay ? "0" : "5px", // Dynamic margin based on isPublished
    width: "auto",
    backdropFilter: "blur(0.2px)",
    display: "flex",
    justifyContent: "end",
    zIndex: 10,
  }),
}))(Container);

export const StyledList = withStyles({
  root: {
    position: "absolute",
    top: 20,
    left: 0,
    right: 5,
    width: "100%",
    color: "#fff",
    listStyle: "none",
    paddingLeft: 20,
    paddingRight: 20,
  },
})(List);
