import {
  FETCH_PRODUCT_BADGES_LIST,
  QUERY_PRODUCT_BADGE,
  ProductBadgeActions,
  LoadProductBadgesResponseAction,
  ProductBadgeData,
  UPDATE_BADGE_PRECEDENCE,
  DELETE_BADGE,
  DeleteBadgeAction,
  UpdateBadgePrecedenceAction,
  RESET_PRODUCT_BADGE_STATE,
  GET_PRODUCT_BADGE,
  LoadBadgeAction,
  UPDATE_PRODUCT_BADGE,
  UpdateProductBadgeAction,
  SET_SELECTED_STORE,
  SetSelectedStoreAction,
  RESET_SELECTED_STORE,
  CREATE_PRODUCT_BADGE,
  CreateProductBadgeAction,
  LOAD_PRODUCT_BADGES_LIST,
  ProductBadgeAssignments,
  LoadQueryProductBadgeAction,
  SET_PRODUCT_BADGE_ASSIGNMENTS,
  SetProductBadgeAssignmentsAction,
  UPDATE_PRODUCT_BADGE_ASSIGNMENTS,
} from "./ProductBadgeTypes";

export interface ProductBadgeState {
  badges: ProductBadgeData[];
  deleteBadgeId: string | null;
  updatedBadgePrecedence: number | null;
  updateBadgeId: string | undefined;
  selectedStore: string | null;
  isLoading: boolean;
  productBadgeAssignments: ProductBadgeAssignments[];
  productBadgeAssignmentsLoading: boolean;
}

const initialState: ProductBadgeState = {
  badges: [],
  deleteBadgeId: null,
  updatedBadgePrecedence: null,
  updateBadgeId: undefined,
  selectedStore: null,
  isLoading: false,
  productBadgeAssignments: [],
  productBadgeAssignmentsLoading: false,
};

export const ProductBadgeReducer = (
  state: ProductBadgeState = initialState,
  action: ProductBadgeActions,
): ProductBadgeState => {
  switch (action.type) {
    case LOAD_PRODUCT_BADGES_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_PRODUCT_BADGES_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_PRODUCT_BADGES_LIST.FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_PRODUCT_BADGES_LIST.SUCCESS:
      const { payload } = action as LoadProductBadgesResponseAction;
      const badges = payload.badges;
      return {
        ...state,
        badges: badges,
        isLoading: false,
      };
    case UPDATE_BADGE_PRECEDENCE.REQUEST:
      const updateAction = action as UpdateBadgePrecedenceAction;
      const { badgeId, precedence } = updateAction.payload;
      return {
        ...state,
        updatedBadgePrecedence: precedence,
        updateBadgeId: badgeId,
      };
    case UPDATE_BADGE_PRECEDENCE.SUCCESS:
      return {
        ...state,
        badges: state.badges.map((badge) =>
          badge.badgeId === state.updateBadgeId
            ? {
                ...badge,
                precedence: state.updatedBadgePrecedence ?? badge.precedence,
              }
            : badge,
        ),
        updateBadgeId: undefined,
      };
    case UPDATE_BADGE_PRECEDENCE.FAILURE:
      return {
        ...state,
        updatedBadgePrecedence: null,
        updateBadgeId: undefined,
      };
    case DELETE_BADGE.REQUEST:
      const deleteRequestAction = action as DeleteBadgeAction;
      const deleteBadgeId = deleteRequestAction.payload.badgeId;
      return {
        ...state,
        deleteBadgeId: deleteBadgeId,
        isLoading: true,
      };
    case DELETE_BADGE.SUCCESS:
      const filterBadges = state.badges?.filter(
        (badge) => badge.badgeId !== state.deleteBadgeId,
      );
      return {
        ...state,
        badges: filterBadges,
        deleteBadgeId: null,
        isLoading: false,
      };
    case DELETE_BADGE.FAILURE:
      return {
        ...state,
        deleteBadgeId: null,
        isLoading: false,
      };
    case GET_PRODUCT_BADGE.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PRODUCT_BADGE.SUCCESS: {
      const badgeDetails = action as LoadBadgeAction;
      return {
        ...state,
        badges: state.badges.map((badge) =>
          badge.badgeId === badgeDetails.payload.badgeId
            ? badgeDetails.payload
            : badge,
        ),
        isLoading: false,
      };
    }
    case GET_PRODUCT_BADGE.FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case CREATE_PRODUCT_BADGE.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_PRODUCT_BADGE.SUCCESS:
      const newBadgeDetails = action as CreateProductBadgeAction;
      return {
        ...state,
        badges: [...state.badges, newBadgeDetails.payload],
        isLoading: false,
      };
    case CREATE_PRODUCT_BADGE.FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_PRODUCT_BADGE.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_PRODUCT_BADGE.SUCCESS:
      const updatedBadgeDetails = action as UpdateProductBadgeAction;
      return {
        ...state,
        badges: state.badges.map((badge) =>
          badge.badgeId === updatedBadgeDetails.payload.badgeId
            ? updatedBadgeDetails.payload
            : badge,
        ),
        isLoading: false,
      };
    case UPDATE_PRODUCT_BADGE.FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SET_SELECTED_STORE:
      const { storeId } = action as SetSelectedStoreAction;
      return {
        ...state,
        selectedStore: storeId,
      };
    case RESET_SELECTED_STORE:
      return {
        ...state,
        selectedStore: null,
      };
    case QUERY_PRODUCT_BADGE.SUCCESS: {
      const { payload } = action as LoadQueryProductBadgeAction;
      const productBadgeAssignments = [
        ...state.productBadgeAssignments,
        ...payload.productBadgeAssignments,
      ];
      return {
        ...state,
        productBadgeAssignments: productBadgeAssignments,
      };
    }
    case UPDATE_PRODUCT_BADGE_ASSIGNMENTS.REQUEST:
      return {
        ...state,
        productBadgeAssignmentsLoading: true,
      };
    case SET_PRODUCT_BADGE_ASSIGNMENTS: {
      const { payload } = action as SetProductBadgeAssignmentsAction;
      const { badges, productId } = payload;
      const productBadgeAssignments =
        state.productBadgeAssignments?.map((badgeAssignment) =>
          badgeAssignment.productId === productId
            ? { ...badgeAssignment, badges: badges.map((badge) => badge) }
            : { ...badgeAssignment },
        ) ?? [];
      return {
        ...state,
        productBadgeAssignments: productBadgeAssignments,
        productBadgeAssignmentsLoading: false,
      };
    }
    case UPDATE_PRODUCT_BADGE_ASSIGNMENTS.FAILURE:
      return {
        ...state,
        productBadgeAssignmentsLoading: false,
      };
    case RESET_PRODUCT_BADGE_STATE:
      return initialState;
    default:
      return state;
  }
};
