import {
  ColorProduct,
  ProductSubDetailsData,
  fetchSingleProductColorPayload,
} from "../product/ProductTypes";
import { Store } from "../store-list/StoreListTypes";

export const CATEGORY_CHANGE = "CATEGORY_CHANGE";
export const CANCEL_CATALOG_PAGE_LOADING = "CANCEL_CATALOG_PAGE_LOADING";
export const LOAD_PRODUCT_LIST = "LOAD_PRODUCT_LIST";
export const FETCH_PRODUCT_LIST = {
  START: "FETCH_PRODUCT_LIST_START",
  REQUEST: "FETCH_PRODUCT_LIST_REQUEST",
  SUCCESS: "FETCH_PRODUCT_LIST_SUCCESS",
  FAILURE: "FETCH_PRODUCT_LIST_FAILURE",
  STOP: "FETCH_PRODUCT_LIST_STOP",
};
export const FETCH_PRODUCTS_COLORS = {
  REQUEST: "FETCH_PRODUCTS_COLORS_REQUEST",
  SUCCESS: "FETCH_PRODUCTS_COLORS_SUCCESS",
  FAILURE: "FETCH_PRODUCTS_COLORS_FAILURE",
};
export const CHANGE_PRODUCT_LIST_SEQUENCE = "CHANGE_PRODUCT_LIST_SEQUENCE";
export const UPDATE_PRODUCT_LIST_SEQUENCE = {
  REQUEST: "UPDATE_PRODUCT_LIST_SEQUENCE_REQUEST",
  SUCCESS: "UPDATE_PRODUCT_LIST_SEQUENCE_SUCCESS",
  FAILURE: "UPDATE_PRODUCT_LIST_SEQUENCE_FAILURE",
};

export const LOAD_ASYNC_REQUEST_SERVICE_URL = "LOAD_ASYNC_REQUEST_SERVICE_URL";
export const UPDATE_PRODUCT_SEQUENCE_LIST = {
  REQUEST: "UPDATE_PRODUCT_SEQUENCE_LIST_REQUEST",
  SUCCESS: "UPDATE_PRODUCT_SEQUENCE_LIST_SUCCESS",
  FAILURE: "UPDATE_PRODUCT_SEQUENCE_LIST_FAILURE",
};
export const GET_PRODUCT_SEQUENCE_LIST = {
  REQUEST: "GET_PRODUCT_SEQUENCE_LIST_REQUEST",
  SUCCESS: "GET_PRODUCT_SEQUENCE_LIST_SUCCESS",
  DONE: "GET_PRODUCT_SEQUENCE_LIST_DONE",
  FAILURE: "GET_PRODUCT_SEQUENCE_LIST_FAILURE",
};
export const CHANGE_PINNED_PRODUCTS = "CHANGE_PINNED_PRODUCTS";
export const UPDATE_PINNED_PRODUCTS = {
  REQUEST: "UPDATE_PINNED_PRODUCTS_REQUEST",
  SUCCESS: "UPDATE_PINNED_PRODUCTS_SUCCESS",
  FAILURE: "UPDATE_PINNED_PRODUCTS_FAILURE",
};
export const UPDATE_PRODUCT_LIST_IN_STATE = "UPDATE_PRODUCT_LIST_IN_STATE";
export const UPDATE_PRODUCT_LIST_SEQUENCE_MODE =
  "UPDATE_PRODUCT_LIST_SEQUENCE_MODE";
// export const RESET_PRODUCT_SEQUENCE_STATE = "RESET_PRODUCT_SEQUENCE_STATE";
export const RESET_PRODUCT_SEQUENCE = "RESET_PRODUCT_SEQUENCE";
export const RESET_PRODUCT_LIST = "RESET_PRODUCT_LIST";
export const UPDATE_NUMBER_OF_PINNED_PRODUCTS =
  "UPDATE_NUMBER_OF_PINNED_PRODUCTS";
export const SET_PINNED_PRODUCTS_MODE = "SET_PINNED_PRODUCTS_MODE";
export const FETCH_SORTING_LIST = {
  REQUEST: "FETCH_SORTING_LIST_REQUEST",
  SUCCESS: "FETCH_SORTING_LIST_SUCCESS",
  FAILURE: "FETCH_SORTING_LIST_FAILURE",
};
export const LOAD_SORTING_LIST = "LOAD_SORTING_LIST";
export const SET_SORTING_RULE = "SET_SORTING_RULE";
export const LOAD_SORTED_PRODUCTS = "LOAD_SORTED_PRODUCTS";

export const FETCH_SORTED_PRODUCTS = {
  REQUEST: "FETCH_SORTED_PRODUCTS_REQUEST",
  SUCCESS: "FETCH_SORTED_PRODUCTS_SUCCESS",
  FAILURE: "FETCH_SORTED_PRODUCTS_FAILURE",
};

export const SET_ACTIVE_SORTING_RULE = "SET_ACTIVE_SORTING_RULE";
export const UPDATE_PRODUCT_SEQUENCE = "UPDATE_PRODUCT_SEQUENCE";

export const GET_PRODUCT_SEQUENCE = "GET_PRODUCT_SEQUENCE";
export const RESET_SEQUENCE_REQUESTED_BY_BOOST_BURY =
  "RESET_SEQUENCE_REQUEST_BY_BOOST_BURY";

export const REFRESH_PRODUCT = {
  REQUEST: "REFRESH_PRODUCT_REQUEST",
  SUCCESS: "REFRESH_PRODUCT_SUCCESS",
  FAILURE: "REFRESH_PRODUCT_FAILURE",
};

export const FETCH_PRODUCT = {
  REQUEST: "FETCH_PRODUCT_REQUEST",
  SUCCESS: "FETCH_PRODUCT_SUCCESS",
  FAILURE: "FETCH_PRODUCT_FAILURE",
};

export const FETCH_PRODUCT_IDS = {
  REQUEST: "FETCH_PRODUCT_IDS_REQUEST",
  SUCCESS: "FETCH_PRODUCT_IDS_SUCCESS",
  FAILURE: "FETCH_PRODUCT_IDS_FAILURE",
};

export const FETCH_PREV_PRODUCT_IDS = {
  REQUEST: "FETCH_PREV_PRODUCT_IDS_REQUEST",
  SUCCESS: "FETCH_PREV_PRODUCT_IDS_SUCCESS",
  FAILURE: "FETCH_PREV_PRODUCT_IDS_FAILURE",
};

export const FETCH_NEXT_PRODUCT_IDS = {
  REQUEST: "FETCH_NEXT_PRODUCT_IDS_REQUEST",
  SUCCESS: "FETCH_NEXT_PRODUCT_IDS_SUCCESS",
  FAILURE: "FETCH_NEXT_PRODUCT_IDS_FAILURE",
};

export const REFRESH_PRODUCT_COLOR = {
  REQUEST: "REFRESH_PRODUCT_COLOR_REQUEST",
  SUCCESS: "REFRESH_PRODUCT_COLOR_SUCCESS",
  FAILURE: "REFRESH_PRODUCT_COLOR_FAILURE",
};

export const FETCH_WATCHED_PRODUCT_LIST = {
  REQUEST: "FETCH_WATCHED_PRODUCT_LIST_REQUEST",
  SUCCESS: "FETCH_WATCHED_PRODUCT_LIST_SUCCESS",
  FAILURE: "FETCH_WATCHED_PRODUCT_LIST_FAILURE",
};

export const ADD_PRODUCT_TO_WATCH_LIST = {
  REQUEST: "ADD_PRODUCT_TO_WATCH_LIST_REQUEST",
  SUCCESS: "ADD_PRODUCT_TO_WATCH_LIST_SUCCESS",
  FAILURE: "ADD_PRODUCT_TO_WATCH_LIST_FAILURE",
};

export const REMOVE_PRODUCT_FROM_WATCH_LIST = {
  REQUEST: "REMOVE_PRODUCT_FROM_WATCH_LIST_REQUEST",
  SUCCESS: "REMOVE_PRODUCT_FROM_WATCH_LIST_SUCCESS",
  FAILURE: "REMOVE_PRODUCT_FROM_WATCH_LIST_FAILURE",
};

export const LOCK_PRODUCT = {
  REQUEST: "LOCK_PRODUCT_REQUEST",
  SUCCESS: "LOCK_PRODUCT_SUCCESS",
  FAILURE: "LOCK_PRODUCT_FAILURE",
};

export const UNLOCK_PRODUCT = {
  REQUEST: "UNLOCK_PRODUCT_REQUEST",
  SUCCESS: "UNLOCK_PRODUCT_SUCCESS",
  FAILURE: "UNLOCK_PRODUCT_FAILURE",
};

export const FETCH_LOCKS_FOR_CATEGORY = {
  REQUEST: "FETCH_LOCKS_FOR_CATEGORY_REQUEST",
  SUCCESS: "FETCH_LOCKS_FOR_CATEGORY_SUCCESS",
  FAILURE: "FETCH_LOCKS_FOR_CATEGORY_FAILURE",
};
export const LOAD_PRODUCT_POSITION_SERVICE_URL =
  "LOAD_PRODUCT_POSITION_SERVICE_URL";

export const LOCK_PRODUCT_COMPLETED = "LOCK_PRODUCT_COMPLETED";

export const UNLOCK_PRODUCT_COMPLETED = "UNLOCK_PRODUCT_COMPLETED";

export const BUSINESS_RULES_SERVICE_URL = "BUSINESS_RULES_SERVICE_URL";

export const RESET_LOCKS_IN_CATEGORY = "RESET_LOCKS_IN_CATEGORY";

export const SET_LOCKED_UNLOCKED_PRODUCTS = "SET_LOCKED_UNLOCKED_PRODUCTS";

export const RESET_SAVED_LOCKED_UNLOCKED_PRODUCTS =
  "RESET_SAVED_LOCKED_UNLOCKED_PRODUCTS";

export const REVERT_PRODUCT_SEQUENCE = "REVERT_PRODUCT_SEQUENCE";
export type ProductListActionTypes =
  | LoadProductsFromCategoryResponseAction
  | UpdateProductSequencesResponseAction
  | updateProductListInStateAction
  | updateProductListSequenceModeAction
  | ResetProductList
  | UpdateNumberOfPinnedProductsAction
  | SetPinnedProductsModeAction
  | SetSortingRuleAction
  | UpdateProductListSequenceAction
  | LoadSortingRulesListResponseAction;

export interface ChangeCategoryAction {
  type: typeof CATEGORY_CHANGE;
}

/**
 * Load category to product types
 */
export interface LoadProductsFromCategoryAction {
  payload: GetProductListPayload;
  type: typeof LOAD_PRODUCT_LIST;
}

export interface LoadProductsFromCategoryResponseAction {
  payload: GetProductsFromCategoryResponsePayload;
  type: typeof FETCH_PRODUCT_LIST.SUCCESS;
}

export interface GetProductsFromCategoryResponsePayload {
  // count: number;
  results: CategoryToProductData[];
  categoryId?: string;
  // total: number;
  // storeId: string;
  storeListIds: Store[];
}

export interface GetProductListPayload {
  catalogId: string;
  categoryId: string;
  localeCode: string | null;
  storeId: string;
  productIds?: string[];
  next?: string;
  count?: number;
  total?: number;
  totalPagesFetched?: Number;
  storeListIds: Store[]; // TODO Remove this...  store specific values should be derived at the selector level.
  isRefreshingAllProducts?: boolean; // ideally, refreshing all should be a separate action rather than a flag
}

export interface GetSortedListPayload {
  catalogId: string;
  categoryId: string;
  localeId: string;
  storeId: string;
  currencyCode: string;
}
export interface CategoryToProductData {
  // descriptions?: PublishedObj[];
  // isPublished?: boolean;
  // overrides?: Overrides[];
  // id: string;
  // code: string;
  // name: string;
  // thumbnail: string;
  // position: number;
  // isPublished: boolean;
  // typeCode: string;
  // updatedAt: string;
  // translations: {
  //   localeCode: string;
  //   name: string;
  // };
  // sequence: number;
  productId: string;
  cachedProduct: ProductSubDetailsData;
  // product: {
  //   productId?: string;
  //   code: string;
  //   typeCode: string;
  //   name: string;
  //   thumbnail: string;
  //   isPublished: true;
  //   translations: [
  //     {
  //       localeCode: string;
  //       name: string;
  //       thumbnail: string;
  //       isPublished: true;
  //       url: string;
  //     }
  //   ];
  //   url: string;
  //   stock: number;
  //   stockValue: number;
  //   listPrice: number;
  // colors: [
  //   {
  //     sequence: 0;
  //     name: string;
  //     swatchImage: string;
  //     thumbnail: string;
  //     code: string;
  //     colorId: string;
  //     translations: [
  //       {
  //         localeCode: string;
  //         name: string;
  //         swatchImage: string;
  //         thumbnail: string;
  //       }
  //     ];
  //   }
  // ];
}

export interface WatchedProductListData {
  productId: string;
  storeId: string;
  watchers: string[];
}

export interface WatchedProductListResponse {
  results: WatchedProductListData[];
}

/**
 * Update product list sequence types
 */
export interface UpdateProductListSequenceAction {
  payload: UpdateSequencePayload;
  type: typeof CHANGE_PRODUCT_LIST_SEQUENCE;
}
export interface UpdateProductSequenceAction {
  payload: UpdateProductSequencePayload;
  type: typeof UPDATE_PRODUCT_SEQUENCE;
}

export interface GetProductSequenceAction {
  payload: GetProductSequencePayload;
  type: typeof GET_PRODUCT_SEQUENCE;
}

export interface GetProductSequencePayload {
  requestId: string;
  isUpdateFunctionInvoked?: boolean;
}

export interface UpdateProductSequences {
  payload: UpdateSequencePayload;
  type: typeof UPDATE_PRODUCT_LIST_SEQUENCE;
}

export interface UpdateProductSequencesResponseAction {
  products: ProductSequenceData[];
  type: typeof UPDATE_PRODUCT_LIST_SEQUENCE.SUCCESS;
}

export interface UpdateSequencePayload {
  catalogId: string;
  categoryId: string;
  productSequences: ProductSequenceParams[];
}
export interface UpdateProductSequencePayload {
  categoryId: string;
  productIds: string[];
}

export interface ProductSequenceParams {
  productId: string;
  sequence?: number;
}

export interface UpdateProductSequenceResponse {
  type: string;
  payload: UpdateProductSequenceResponsePayload;
  error: string;
}

export interface UpdateProductSequenceResponsePayload {
  data: UpdateCategoryToProduct;
  variables: UpdateProductSequenceVariables;
}

export interface UpdateProductSequenceVariables {
  catalogId: string;
  categoryId: string;
  productId: string;
}

export interface UpdateCategoryToProduct {
  [name: string]: AffectedRows;
  insert_SyncLog: AffectedRows;
}

export interface AffectedRows {
  affected_rows: number;
}

/**
 * Update product list in state types
 */
export interface updateProductListInStateAction {
  payload: UpdateProductInStatePayload;
  type: typeof UPDATE_PRODUCT_LIST_IN_STATE;
}

export interface UpdateProductInStatePayload {
  products: ProductSequenceData[];
  isSaveAction: boolean;
}

/**
 * Update sequence mode in state types
 */
export interface updateProductListSequenceModeAction {
  sequenceMode: number;
  type: typeof UPDATE_PRODUCT_LIST_SEQUENCE_MODE;
}

/**
 * Reset product list state types
 */
export interface ResetProductList {
  type: typeof RESET_PRODUCT_LIST;
}

export interface ResetProductSequence {
  type: typeof RESET_PRODUCT_SEQUENCE;
}

export interface RevertProductSequence {
  type: typeof REVERT_PRODUCT_SEQUENCE;
}

export interface ProductSequenceData {
  productId: string;
  sequence?: number;
  isInitialPositionAvailable?: boolean;
  isRecentlyAdded?: boolean;
  isLocked?: boolean;
}

export interface ProductIdsData {
  productIds: string[];
  categoryId: string;
  count: number;
  total: number;
  next?: string;
}

export interface ProductListSequenceResponse {
  accountId: string;
  requestId: string;
  response: Response;
}

interface Response {
  statusCode: number;
  body: string;
}

export interface ProductPublishedData {
  descriptions?: PublishedObj[];
  isPublished: boolean;
  overrides?: Overrides[];
  storeSpecific?: Object;
  imageGroups?: {
    images: {
      imageId: string;
      thumbnail: string;
    }[];
    variationAttributes: {
      attributeId: string;
      values: {
        value: string;
      }[];
    }[];
  }[];
  categorySpecific?: {
    categoryId: string;
    leadingColorId?: string;
    leadingImageId?: string;
  }[];
  isUpdatingPublishFlag?: boolean;
  code?: string;
  variationBaseId?: string;
  thumbnail?: string;
  name?: string;
}

export interface PublishedObj {
  isPublished: boolean;
}
export interface Overrides {
  isPublished: boolean | null;
  storeId: string;
}

export interface UpdateNumberOfPinnedProductsAction {
  payload: {
    numberOfPinnedProducts: number;
  };
  type: typeof UPDATE_NUMBER_OF_PINNED_PRODUCTS;
}

export interface UpdatePinnedProductsAction {
  payload: UpdatePinnedProductsPayload;
  type: typeof CHANGE_PINNED_PRODUCTS;
}

export interface UpdatePinnedProductsPayload {
  storeId: string;
  catalogId: string;
  categoryId: string;
  pinnedProducts: string[];
}

export interface UpdatePinnedProductsResponse {
  type: string;
  payload: UpdatePinnedProductResponsePayload;
  error: string;
}

export interface UpdatePinnedProductResponsePayload {
  data: UpdatePinnedProducts;
  variables: UpdatePinnedProductVariables;
}

export interface UpdatePinnedProducts {
  [name: string]: AffectedRows;
  insert_SyncLog: AffectedRows;
}

export interface UpdatePinnedProductVariables {
  storeId: string;
  catalogId: string;
  categoryId: string;
  productId: string;
}

export interface PinnedProductsData {
  PinnedProduct: PinnedProductData[];
}

export interface PinnedProductData {
  productId: string;
}

export type ProductId = string;

export interface SetPinnedProductsModeAction {
  isPinnedProductsMode: boolean;
  type: typeof SET_PINNED_PRODUCTS_MODE;
}

/**
 * Set sorting rule types
 */

export interface SetSortingRuleAction {
  rule: string;
  type: typeof SET_SORTING_RULE;
}

export interface LoadSortingRulesListFromSortAction {
  type: typeof LOAD_SORTING_LIST;
}

export interface LoadSortedProductsListFromSortAction {
  rule: string;
  selectedSortPeriod?: string;
  timeZone?: string;
  type: typeof LOAD_SORTED_PRODUCTS;
}
export interface Attributes {
  attr: string;
  value: string;
  points: number;
}
export interface FormulaNode {
  metric: string;
  order: string;
  weight: number;
}

export interface SortingPointsLUTNode {
  attr: string;
  value: string;
  points: number;
}
export interface MultiCriteriaNode {
  ruleType: string;
  strategy?: string;
  sortingPointsLUT: SortingPointsLUTNode[];
  dateRange?: string;
  timezone?: string;
  formula: FormulaNode[];
}

export interface BusinessRulesListDetails {
  ruleType: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  ruleId: string;

  // only applicable if ruleType == "WS": {
  dateRange?: string;
  timezone?: string;
  formula: FormulaNode[];
  // }

  // only applicable if ruleType == "AS": {
  strategy?: string;
  sortingPointsLUT: SortingPointsLUTNode[];
  // }

  // only applicable if ruleType == "MCS": {
  criteria: MultiCriteriaNode[];
}

export interface SortingRulesListPayload {
  results: BusinessRulesListDetails[];
}
export interface LoadSortingRulesListResponseAction {
  type: typeof FETCH_SORTING_LIST;
  total: number;
  payload: SortingRulesListPayload;
}

export interface productColorsDataPayload {
  results: productColorsData[];
}

export interface productColorsData {
  productId: string;
  cachedProduct: cachedColorProduct;
}

export interface cachedColorProduct {
  cachedAt: string;
  product: ColorProduct;
}

export interface LoadProductColorsAction {
  type: typeof REFRESH_PRODUCT_COLOR.REQUEST;
  payload: fetchSingleProductColorPayload;
}

export interface addRemoveProductInWatchListPayload {
  productId: string;
  storeId: string;
  watcher: string;
}

export interface watchProductAction {
  type: typeof ADD_PRODUCT_TO_WATCH_LIST.REQUEST;
  payload: addRemoveProductInWatchListPayload;
}

export interface unWatchProductAction {
  type: typeof REMOVE_PRODUCT_FROM_WATCH_LIST.REQUEST;
  payload: addRemoveProductInWatchListPayload;
}

export interface LockProductAction {
  type: typeof LOCK_PRODUCT.REQUEST;
  payload: lockedProductsPayload;
}

export interface UnLockProductAction {
  type: typeof UNLOCK_PRODUCT.REQUEST;
  payload: unlockedProductsPayload;
}

export interface GetLocksInCategoryAction {
  type: typeof FETCH_LOCKS_FOR_CATEGORY.REQUEST;
  payload: getLocksInCategoryPayload;
}

export interface lockUnLockProductPayload {
  productId: string;
  catalogId: string;
  categoryId: string;
  position: number;
  isLocked?: boolean;
}

export interface getLocksInCategoryPayload {
  catalogId: string;
  categoryId: string;
}

export interface LocksInCategory {
  catalogId: string;
  categoryId: string;
  position: number;
  productId: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface LocksInCategoryResponse {
  results: LocksInCategory[];
}

export interface LockProductCompletedAction {
  type: typeof LOCK_PRODUCT_COMPLETED;
  payload: LocksInCategory;
}

export interface UnLockProductCompletedAction {
  type: typeof UNLOCK_PRODUCT_COMPLETED;
  payload: UnLockProductResponsePayload;
}

export interface UnLockProductResponsePayload {
  catalogId: string;
  categoryId: string;
  position: number;
  productId: string;
}

export interface lockedProductsPayload {
  lockedProducts: lockUnLockProductPayload[];
}

export interface unlockedProductsPayload {
  unlockedProducts: lockUnLockProductPayload[];
}
