import type { Components } from "@mui/material";
import {
  BACKGROUND_COLOR,
  BLACK,
  DARK_GREY,
  DARK_GREYISH,
  PRIMARY_COLOR,
  RED,
  SECONDARY_DARK,
} from "../palette";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    sidebarTitle: true;
    sidebarTreeTitle: true;
    header: true;
    text: true;
    subHeader: true;
    highlightedText: true;
    title: true;
    price: true;
    listPrice: true;
    discountedPrice: true;
    body3: true;
    body4: true;
    heading3_regular: true;
    analytics: true;
    analyticsList: true;
    badges: true;
  }
}

export const MuiTypography: Components["MuiTypography"] = {
  variants: [
    {
      props: { variant: "sidebarTitle" } /* component props */,
      style: {
        color: "#404041",
        display: "block",
      },
    },
    {
      props: { variant: "sidebarTreeTitle" },
      style: {
        fontSize: "14px",
        "&:hover": {
          color: "#78a6be",
        },
      },
    },
    {
      props: { variant: "header" },
      style: {
        fontSize: "1.25em",
        fontWeight: "bold",
        color: "#404041",
        textAlign: "center",
        paddingBottom: "6px",
      },
    },
    {
      props: { variant: "subHeader" },
      style: {
        fontSize: "14px",
        fontWeight: 700,
        fontFamily: "Helvetica",
        color: DARK_GREYISH,
        textAlign: "center",
        paddingBottom: "6px",
      },
    },
    {
      props: { variant: "text" },
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#709bb1",
      },
    },
    {
      props: { variant: "highlightedText" },
      style: {
        fontSize: "14px",
        color: "#f18308",
      },
    },
    {
      props: { variant: "subtitle1" },
      style: {
        fontSize: "18px",
        color: BLACK,
        fontWeight: 400,
        fontFamily: "Helvetica",
      },
    },
    {
      props: { variant: "subtitle1" },
      style: {
        fontSize: "18px",
        color: BLACK,
        fontWeight: 400,
        fontFamily: "Helvetica",
      },
    },
    {
      props: { variant: "subtitle2" },
      style: {
        fontSize: "14px",
        color: "#333333",
        fontWeight: 400,
        fontFamily: "Helvetica",
      },
    },
    {
      props: { variant: "title" },
      style: {
        fontSize: 14,
        color: BLACK,
        fontWeight: 700,
        fontFamily: "Helvetica",
      },
    },
    {
      props: { variant: "price" },
      style: {
        fontSize: 14,
        color: BLACK,
        fontWeight: 400,
        fontFamily: "Helvetica",
      },
    },
    {
      props: { variant: "listPrice" },
      style: {
        fontSize: 14,
        color: BLACK,
        fontWeight: 400,
        fontFamily: "Helvetica",
        textDecoration: "line-through",
      },
    },
    {
      props: { variant: "discountedPrice" },
      style: {
        fontSize: 14,
        color: RED,
        fontWeight: 700,
        fontFamily: "Helvetica",
      },
    },
    {
      props: { variant: "body3" },
      style: {
        fontSize: 12,
        color: SECONDARY_DARK,
        fontWeight: 400,
        fontFamily: "Helvetica",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "85%",
        display: "inline-block",
      },
    },
    {
      props: { variant: "heading3_regular" },
      style: {
        fontSize: 16,
        color: DARK_GREY,
        fontWeight: 400,
        fontFamily: "Helvetica",
      },
    },
    {
      props: { variant: "analytics" },
      style: {
        fontSize: 14,
        color: PRIMARY_COLOR,
        fontWeight: 700,
        fontFamily: "Helvetica",
      },
    },
    {
      props: { variant: "analyticsList" },
      style: {
        fontSize: 12,
        fontWeight: 700,
        fontFamily: "Helvetica",
        color: BACKGROUND_COLOR,
      },
    },
    {
      props: { variant: "badges" },
      style: {
        fontSize: "14px !important",
        color: BACKGROUND_COLOR,
        fontWeight: 700,
        fontFamily: "Helvetica",
      },
    },
  ],
};
