import {
  FETCH_PRODUCT_LIST,
  LoadProductsFromCategoryResponseAction,
  ProductSequenceData,
  UPDATE_PRODUCT_LIST_IN_STATE,
  updateProductListInStateAction,
  UPDATE_PRODUCT_LIST_SEQUENCE_MODE,
  updateProductListSequenceModeAction,
  RESET_PRODUCT_LIST,
  RESET_PRODUCT_SEQUENCE,
  UPDATE_NUMBER_OF_PINNED_PRODUCTS,
  UpdateNumberOfPinnedProductsAction,
  UPDATE_PINNED_PRODUCTS,
  SET_PINNED_PRODUCTS_MODE,
  SetPinnedProductsModeAction,
  SET_SORTING_RULE,
  SetSortingRuleAction,
  BusinessRulesListDetails,
  FETCH_SORTING_LIST,
  LoadSortingRulesListResponseAction,
  FETCH_SORTED_PRODUCTS,
  FETCH_PRODUCT_IDS,
  LOAD_SORTED_PRODUCTS,
  UPDATE_PRODUCT_SEQUENCE_LIST,
  GET_PRODUCT_SEQUENCE_LIST,
  FETCH_PRODUCT,
  UPDATE_PRODUCT_SEQUENCE,
  WatchedProductListData,
  FETCH_WATCHED_PRODUCT_LIST,
  WatchedProductListResponse,
  ADD_PRODUCT_TO_WATCH_LIST,
  REMOVE_PRODUCT_FROM_WATCH_LIST,
  addRemoveProductInWatchListPayload,
  REVERT_PRODUCT_SEQUENCE,
  ProductIdsData,
  ProductListSequenceResponse,
  RESET_SEQUENCE_REQUESTED_BY_BOOST_BURY,
  FETCH_LOCKS_FOR_CATEGORY,
  LocksInCategory,
  LocksInCategoryResponse,
  LOCK_PRODUCT_COMPLETED,
  UNLOCK_PRODUCT_COMPLETED,
  UnLockProductResponsePayload,
  RESET_LOCKS_IN_CATEGORY,
  lockUnLockProductPayload,
  RESET_SAVED_LOCKED_UNLOCKED_PRODUCTS,
  SET_LOCKED_UNLOCKED_PRODUCTS,
} from "./ProductListTypes";
import { SEQUENCE_MODE_TYPE } from "../../utils/Constants";
import {
  ADD_PRODUCT_IN_CATEGORY,
  LoadSortedProductsAction,
  REMOVE_PRODUCT_IN_CATEGORY,
} from "../product/ProductTypes";
import { DELETE_PRODUCT_BY_CATEGORIES } from "../remove-product-categories/RemoveProductCategoriesTypes";

export interface ProductListState {
  isFetched: boolean;
  isFetching: boolean; // flag to indicate that product list is loading (or refreshing) to be used to show the loading progress/spinner
  isSequenceUpdated: boolean;
  isSequenceLoading: boolean;
  isSquenceUpdateRequest: boolean;
  productSequences: ProductSequenceData[]; // why do we need this, when we have ProductReducer to store products?
  editSequenceList: ProductSequenceData[]; // why do we need this, when we have ProductReducer to store products?
  sequenceMode: number;
  pinnedProducts: PinnedProducts;
  isPinnedProductsMode: boolean;
  sortingRule: string | null;
  sortingRulesList: BusinessRulesListDetails[];
  isSortingRulesListLoaded: boolean;
  isProductIdsFetched: boolean;
  isFetchingProductIds: boolean;
  productIds: string[]; // the saved product sequence (should probably rename to savedSequence)
  categoryId?: string;
  isRefreshingAllProducts?: boolean;
  isFetchingSortedProducts: boolean;
  requestId: string | null;
  allPagesFetched?: boolean;
  addingRemovingProductIdsList?: string[];
  isUpdateFunctionInvoked?: boolean;
  updatedStateProductIds: string[]; // the working (i.e. unsaved) product sequence (should probably rename to workingSequence)
  isProductAddedToCategoryFromClipBoard?: boolean;
  isUpdateProductSequenceError?: boolean;
  watchedProductList: WatchedProductListData[];
  originalEditSequenceList: ProductSequenceData[];
  originalProductSequences: ProductSequenceData[];
  originalUpdatedStateProductIds: string[];
  productIdsData: ProductIdsData;
  total?: number;
  productSequenceList: ProductListSequenceResponse;
  isBoostBurySequenceRequested: boolean;
  locksInCategory: LocksInCategory[];
  savedLockedProductsInCategory: lockUnLockProductPayload[];
  savedUnlockedProductsInCategory: lockUnLockProductPayload[];
  locksRemoveInCategory: LocksInCategory[];
}

const initialState: ProductListState = {
  isFetched: false,
  isFetching: false,
  isSequenceUpdated: false,
  isSequenceLoading: false,
  isSquenceUpdateRequest: false,
  productSequences: [],
  editSequenceList: [],
  sequenceMode: SEQUENCE_MODE_TYPE.VIEW,
  isPinnedProductsMode: false,
  pinnedProducts: {
    numberOfPinnedProducts: 0,
    pinnedProductIds: [],
  },
  sortingRule: null,
  sortingRulesList: [],
  isSortingRulesListLoaded: false,
  isProductIdsFetched: false,
  isFetchingProductIds: false,
  productIds: [],
  categoryId: "",
  isRefreshingAllProducts: false,
  isFetchingSortedProducts: false,
  requestId: null,
  allPagesFetched: false,
  addingRemovingProductIdsList: [],
  isUpdateFunctionInvoked: false,
  updatedStateProductIds: [],
  isProductAddedToCategoryFromClipBoard: false,
  isUpdateProductSequenceError: false,
  watchedProductList: [],
  originalEditSequenceList: [],
  originalProductSequences: [],
  originalUpdatedStateProductIds: [],
  productIdsData: {
    productIds: [],
    categoryId: "",
    next: "",
    count: 0,
    total: 0,
  },
  productSequenceList: {
    accountId: "",
    requestId: "",
    response: {
      statusCode: 0,
      body: "",
    },
  },
  isBoostBurySequenceRequested: false,
  locksInCategory: [],
  savedLockedProductsInCategory: [],
  savedUnlockedProductsInCategory: [],
  locksRemoveInCategory: [],
};

export interface PinnedProducts {
  numberOfPinnedProducts: number;
  pinnedProductIds: string[];
}

export const ProductListReducer = (
  state: ProductListState = initialState,
  action,
): ProductListState => {
  switch (action.type) {
    case FETCH_PRODUCT_LIST.START: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_PRODUCT_LIST.STOP: {
      return {
        ...state,
        isFetching: false,
        originalEditSequenceList: state.editSequenceList,
        originalProductSequences: state.productSequences,
      };
    }
    case FETCH_PRODUCT_LIST.SUCCESS:
      let fetchAction = action as LoadProductsFromCategoryResponseAction;

      const pagesFetched = action?.payload?.totalPagesFetched;

      return {
        ...state,
        isFetched: true,
        sequenceMode:
          action.payload.isRefreshingAllProducts === true
            ? state.sequenceMode
            : 1,
        categoryId: fetchAction.payload.categoryId,
        isRefreshingAllProducts: false,
        allPagesFetched:
          pagesFetched &&
          pagesFetched === Math.ceil(state.productIds.length / 4)
            ? true
            : false,
      };
    case FETCH_SORTED_PRODUCTS.REQUEST:
    case LOAD_SORTED_PRODUCTS:
      return {
        ...state,
        isFetchingSortedProducts: true,
      };
    case DELETE_PRODUCT_BY_CATEGORIES.DONE: {
      const productIdToBeRemoved = action.payload.productId;
      const productList = [...state.productSequences].filter(
        (prod) => productIdToBeRemoved !== prod.productId,
      );
      const updatedListIds = productList.map((item) => item.productId);
      const updatedProductIdsData = {
        ...state.productIdsData,
        productIds: updatedListIds,
        total: updatedListIds.length,
      };
      return {
        ...state,
        productSequences: productList,
        editSequenceList: productList,
        productIds: updatedListIds,
        originalEditSequenceList: productList,
        originalProductSequences: productList,
        productIdsData: updatedProductIdsData,
      };
    }
    case FETCH_SORTED_PRODUCTS.SUCCESS:
      const fetchedProductActionObj = action as LoadSortedProductsAction;
      const productsArray = fetchedProductActionObj.payload.products;
      const productsWithScore = {};
      productsArray.forEach((p) => {
        const scoreSum = Array.isArray(p.score)
          ? p.score.reduce((sum, a) => sum + a, 0)
          : p.score;
        productsWithScore[p.productId] = scoreSum;
      });

      const productSeqCopy = [...state.productSequences];

      // Check if there are any locks for the current category
      const locksForCurrentCategory = state.locksInCategory.filter(
        (lock) => lock.categoryId === state.categoryId,
      );

      // If there are no locks in current category, just sort all products
      if (locksForCurrentCategory.length === 0) {
        const sortedProducts = productSeqCopy.sort((a, b) => {
          return (
            productsWithScore[b.productId] - productsWithScore[a.productId]
          );
        });

        const productIdsData = sortedProducts.map(
          (product) => product.productId,
        );
        const sequencedData = sortedProducts.map((item, index) => ({
          ...item,
          sequence: index + 1,
        }));

        return {
          ...state,
          originalEditSequenceList: state.editSequenceList,
          originalProductSequences: state.productSequences,
          productSequences: sequencedData,
          editSequenceList: sequencedData,
          sequenceMode: 2,
          isFetchingSortedProducts: false,
          updatedStateProductIds: productIdsData,
        };
      }

      // Original locked products logic
      const lockedPositions = new Map(
        locksForCurrentCategory.map((lock) => [
          lock.position - 1,
          lock.productId,
        ]),
      );

      // Separate locked and unlocked products
      const unlockedProducts = productSeqCopy.filter(
        (product) =>
          !Array.from(lockedPositions.values()).includes(product.productId),
      );

      // Sort only unlocked products
      unlockedProducts.sort((a, b) => {
        return productsWithScore[b.productId] - productsWithScore[a.productId];
      });

      // Merge locked and unlocked products
      const finalSortedProducts: ProductSequenceData[] = [];
      let unlockedIndex = 0;

      for (let i = 0; i < productSeqCopy.length; i++) {
        const lockedProductId = lockedPositions.get(i);
        if (lockedProductId) {
          // Insert locked product at its original position
          const lockedProduct = productSeqCopy.find(
            (p) => p.productId === lockedProductId,
          );
          if (lockedProduct) {
            finalSortedProducts[i] = lockedProduct;
          }
        } else {
          // Insert next unlocked product
          finalSortedProducts[i] = unlockedProducts[unlockedIndex++];
        }
      }

      const productIdsData = finalSortedProducts.map(
        (product) => product.productId,
      );
      const sequencedData = finalSortedProducts.map((item, index) => ({
        ...item,
        sequence: index + 1,
      }));

      return {
        ...state,
        originalEditSequenceList: state.editSequenceList,
        originalProductSequences: state.productSequences,
        productSequences: sequencedData,
        editSequenceList: sequencedData,
        sequenceMode: 2,
        isFetchingSortedProducts: false,
        updatedStateProductIds: productIdsData,
      };
    case FETCH_SORTED_PRODUCTS.FAILURE: {
      return {
        ...state,
        isFetchingSortedProducts: false,
      };
    }
    case FETCH_PRODUCT_LIST.REQUEST: {
      const payload = action?.payload;
      return {
        ...state,
        isFetched: false,
        isRefreshingAllProducts: payload?.isRefreshingAllProducts
          ? true
          : false,
        allPagesFetched: false,
      };
    }
    case FETCH_PRODUCT_LIST.FAILURE: {
      const pagesFetched = action?.payload?.totalPagesFetched;
      return {
        ...state,
        isFetched: true,
        isRefreshingAllProducts: false,
        allPagesFetched:
          pagesFetched &&
          pagesFetched === Math.ceil(state.productIds.length / 4)
            ? true
            : false,
      };
    }
    case UPDATE_PRODUCT_LIST_IN_STATE: // updates the working sequence after a drag and drop, or sort
      let updateAction = action as updateProductListInStateAction;
      const sortedProducts = updateAction.payload.products.map((prod, idx) => ({
        ...prod,
        sequence: idx + 1,
      }));
      const seqProductIds = updateAction.payload.products.map(
        (product) => product.productId,
      );
      return {
        ...state,
        editSequenceList: sortedProducts,
        productSequences: updateAction.payload.isSaveAction
          ? sortedProducts
          : state.productSequences,
        updatedStateProductIds: seqProductIds,
      };
    case UPDATE_PRODUCT_LIST_SEQUENCE_MODE:
      let sequenceModeAction = action as updateProductListSequenceModeAction;
      let newState = {
        ...state,
        sequenceMode: sequenceModeAction.sequenceMode
          ? sequenceModeAction.sequenceMode
          : SEQUENCE_MODE_TYPE.VIEW,
      };
      if (sequenceModeAction.sequenceMode === SEQUENCE_MODE_TYPE.VIEW) {
        newState = {
          ...newState,
          editSequenceList: state.productSequences,
          isSequenceUpdated: true,
          pinnedProducts: {
            ...state.pinnedProducts,
            numberOfPinnedProducts:
              state.pinnedProducts.pinnedProductIds.length,
          },
        };
      }
      return newState;

    case UPDATE_PINNED_PRODUCTS.SUCCESS: {
      const pinnedProductIds = state.editSequenceList
        .map((product) => product.productId)
        .slice(0, state.pinnedProducts.numberOfPinnedProducts);
      const pinnedProducts = {
        numberOfPinnedProducts: pinnedProductIds.length,
        pinnedProductIds,
      };
      return {
        ...state,
        pinnedProducts,
      };
    }

    case UPDATE_NUMBER_OF_PINNED_PRODUCTS: {
      const updateNumberOfPinnedProductsAction =
        action as UpdateNumberOfPinnedProductsAction;
      const numberOfPinnedProducts =
        updateNumberOfPinnedProductsAction.payload.numberOfPinnedProducts;
      const pinnedProducts = {
        ...state.pinnedProducts,
        numberOfPinnedProducts,
      };
      return {
        ...state,
        pinnedProducts,
      };
    }

    case SET_PINNED_PRODUCTS_MODE: {
      const setPinnedProductsModeActionAction =
        action as SetPinnedProductsModeAction;
      const isPinnedProductsMode =
        setPinnedProductsModeActionAction.isPinnedProductsMode;
      return {
        ...state,
        isPinnedProductsMode,
      };
    }

    case SET_SORTING_RULE: {
      const setSortingRuleAction = action as SetSortingRuleAction;
      const sortingRule = setSortingRuleAction.rule;
      return {
        ...state,
        sortingRule,
      };
    }

    case RESET_PRODUCT_SEQUENCE: {
      return {
        ...state,
        isSequenceUpdated: false,
      };
    }

    case RESET_PRODUCT_LIST: {
      const pinnedProducts = state.pinnedProducts;
      const watchedProductList = [...state.watchedProductList];
      return {
        ...initialState,
        pinnedProducts,
        watchedProductList,
      };
    }
    case FETCH_SORTING_LIST.SUCCESS: {
      const sortingRulesListAction =
        action as LoadSortingRulesListResponseAction;
      const sortingRulesList = sortingRulesListAction.payload.results;
      return {
        ...state,
        sortingRulesList,
        isSortingRulesListLoaded: true,
      };
    }
    case UPDATE_PRODUCT_SEQUENCE: {
      return {
        ...state,
        isUpdateProductSequenceError: false,
      };
    }
    case UPDATE_PRODUCT_SEQUENCE_LIST.REQUEST: {
      return {
        ...state,
        isUpdateProductSequenceError: false,
        isSequenceLoading: false,
        isBoostBurySequenceRequested: true,
      };
    }
    case UPDATE_PRODUCT_SEQUENCE_LIST.SUCCESS: {
      let stateProductIds: string[] = [];
      if (state.updatedStateProductIds.length > 0) {
        //Displaying updated products when we do the refresh all after saving the product sorting/sequence
        stateProductIds = [...Object.values(state.updatedStateProductIds)];
      } else {
        stateProductIds = [...Object.values(state.productIds)];
      }

      return {
        ...state,
        requestId: action.payload.requestId,
        isUpdateFunctionInvoked: true,
        productIds: stateProductIds,
      };
    }
    case UPDATE_PRODUCT_SEQUENCE_LIST.FAILURE: {
      return {
        ...state,
        isUpdateProductSequenceError: true,
        isUpdateFunctionInvoked: false,
        isBoostBurySequenceRequested: false,
      };
    }
    case GET_PRODUCT_SEQUENCE_LIST.SUCCESS: {
      const productSequenceRes = action.payload;
      return {
        ...state,
        isSequenceLoading: false,
        productSequenceList: productSequenceRes,
        isSquenceUpdateRequest: false,
      };
    }
    case GET_PRODUCT_SEQUENCE_LIST.DONE: {
      return {
        ...state,
        isSequenceLoading: true,
        isSquenceUpdateRequest: true,
        isUpdateFunctionInvoked: false,
        sequenceMode: 1,
        originalEditSequenceList: state.editSequenceList,
        originalProductSequences: state.productSequences,
      };
    }
    case GET_PRODUCT_SEQUENCE_LIST.FAILURE: {
      return {
        ...state,
        isUpdateFunctionInvoked: false,
      };
    }
    case FETCH_PRODUCT_IDS.REQUEST: {
      const payload = action.payload;
      const Ids = payload.productIds ? state.productIds : [];
      return {
        ...state,
        productIds: payload.next ? state.productIds : Ids,
        productSequences: [],
        editSequenceList: [],
        isFetchingProductIds: payload.productIds ? false : true,
        isProductIdsFetched: false,
        isRefreshingAllProducts: payload.isRefreshingAllProducts ? true : false,
        allPagesFetched: false,
        total: undefined,
      };
    }
    case FETCH_PRODUCT_IDS.SUCCESS: {
      const payload: ProductIdsData = action.payload;
      const productIdData = payload.productIds;
      const pIds =
        state.productIds.length > 0
          ? state.productIds.concat(productIdData)
          : productIdData;
      return {
        ...state,
        productIds: pIds,
        productSequences: [
          ...state.productSequences,
          ...pIds.map((productId) => ({ productId })),
        ],
        editSequenceList: [
          ...state.editSequenceList,
          ...pIds.map((productId) => ({ productId })),
        ],
        updatedStateProductIds: pIds,
        isProductIdsFetched: true,
        isFetchingProductIds: false,
        isRefreshingAllProducts: false,
        allPagesFetched:
          !payload.next || productIdData?.length === 0
            ? true
            : state.allPagesFetched,
        productIdsData: payload,
        total: payload.total,
      };
    }
    case FETCH_PRODUCT_IDS.FAILURE: {
      return {
        ...state,
        isProductIdsFetched: false,
        isFetchingProductIds: false,
        isRefreshingAllProducts: false,
        allPagesFetched: true,
      };
    }
    case ADD_PRODUCT_IN_CATEGORY.REQUEST: {
      const productId = action.payload.productId;
      let updatedProductIdsList = [
        ...(state.addingRemovingProductIdsList as string[]),
      ];
      updatedProductIdsList?.push(productId);
      return {
        ...state,
        addingRemovingProductIdsList: updatedProductIdsList,
      };
    }
    case ADD_PRODUCT_IN_CATEGORY.FAILURE:
    case ADD_PRODUCT_IN_CATEGORY.SUCCESS: {
      const productId = action.payload.productId;
      let updatedProductIdsList = [
        ...(state.addingRemovingProductIdsList as string[]),
      ];
      updatedProductIdsList = updatedProductIdsList?.filter(
        (id) => id !== productId,
      );
      let updatedProductIds = [...state.productIds];
      updatedProductIds.push(productId);
      return {
        ...state,
        addingRemovingProductIdsList: updatedProductIdsList,
        productIds: updatedProductIds,
        updatedStateProductIds: updatedProductIds,
      };
    }
    case REMOVE_PRODUCT_IN_CATEGORY.REQUEST: {
      const productId = action.payload.productId;
      const updatedProductIdsList = [
        ...(state.addingRemovingProductIdsList as string[]),
      ];
      updatedProductIdsList?.push(productId);
      return {
        ...state,
        addingRemovingProductIdsList: updatedProductIdsList,
      };
    }
    case REMOVE_PRODUCT_IN_CATEGORY.SUCCESS: {
      const productIdToBeRemoved = action.payload.productId;
      const removeFromCategory = action.payload.isFromCategory;

      const isPublishedAndIsCategory = action.payload.isPublished;

      const productSequencesData = [...state.productSequences];
      const updatedProductList = productSequencesData.filter(
        (product) => product.productId !== productIdToBeRemoved,
      );
      let updatedProductIdsList = [
        ...(state.addingRemovingProductIdsList as string[]),
      ];
      updatedProductIdsList = updatedProductIdsList?.filter(
        (id) => id !== productIdToBeRemoved,
      );

      return {
        ...state,
        productSequences: updatedProductList,
        editSequenceList: updatedProductList,
        productIds: isPublishedAndIsCategory
          ? state.productIds.filter((id) => id !== productIdToBeRemoved)
          : !removeFromCategory
            ? state.productIds
            : state.productIds.filter((id) => id !== productIdToBeRemoved),
        addingRemovingProductIdsList: updatedProductIdsList,
        originalEditSequenceList: updatedProductList,
        originalProductSequences: updatedProductList,
      };
    }
    case REMOVE_PRODUCT_IN_CATEGORY.FAILURE: {
      const productIdToBeRemoved = action.payload.productId;
      let updatedProductIdsList = [
        ...(state.addingRemovingProductIdsList as string[]),
      ];
      updatedProductIdsList?.filter((id) => id !== productIdToBeRemoved);
      return {
        ...state,
        addingRemovingProductIdsList: updatedProductIdsList,
      };
    }
    case FETCH_PRODUCT.REQUEST: {
      const productData = action?.payload;
      return {
        ...state,
        isProductAddedToCategoryFromClipBoard:
          !!productData.isProductAddedFromClipBoard,
      };
    }
    case FETCH_PRODUCT.SUCCESS: {
      const productData = action?.payload;
      const updatedProductSequencesData = [...state.productSequences];
      const updatedProductIds =
        state.updatedStateProductIds.length > 0
          ? [...state.updatedStateProductIds]
          : [...state.productIds];
      if (productData && productData.productId) {
        //Check if the product is not removedProduct.
        if (!productData.isProductRemoved) {
          const existsInSequences = updatedProductSequencesData.some(
            (item) => item.productId === productData.productId,
          );
          //handle Add product case if not exist in sequence
          if (!existsInSequences) {
            updatedProductSequencesData.push({
              sequence: productData?.cachedProduct?.sequence,
              productId: productData?.productId,
            });
          }
          if (
            state.isProductAddedToCategoryFromClipBoard &&
            !updatedProductIds.includes(productData.productId)
          ) {
            updatedProductIds.push(productData.productId);
          }
        } else {
          //Remove product from sequence and updatedProductIds
          const indexToRemove = updatedProductSequencesData.findIndex(
            (item) => item.productId === productData.productId,
          );
          if (indexToRemove !== -1) {
            updatedProductSequencesData.splice(indexToRemove, 1);
          }
          const idIndexToRemove = updatedProductIds.indexOf(
            productData.productId,
          );
          if (idIndexToRemove !== -1) {
            updatedProductIds.splice(idIndexToRemove, 1);
          }
        }
      }
      let updatedProductIdsData = { ...state.productIdsData };
      if (updatedProductIds.length !== state.productIdsData.total) {
        updatedProductIdsData.productIds = updatedProductIds;
        updatedProductIdsData.total = updatedProductIds.length;
      }

      return {
        ...state,
        productSequences: updatedProductSequencesData,
        editSequenceList: updatedProductSequencesData,
        productIds: updatedProductIds,
        updatedStateProductIds: updatedProductIds,
        originalEditSequenceList: updatedProductSequencesData,
        originalProductSequences: updatedProductSequencesData,
        productIdsData: updatedProductIdsData,
      };
    }
    case REVERT_PRODUCT_SEQUENCE: {
      return {
        ...state,
        editSequenceList: state.originalEditSequenceList,
        productSequences: state.originalProductSequences,
        updatedStateProductIds: state.productIds,
        sequenceMode: 1,
      };
    }
    case FETCH_WATCHED_PRODUCT_LIST.SUCCESS: {
      const productListResponse = action.payload as WatchedProductListResponse;
      return {
        ...state,
        watchedProductList: productListResponse.results,
      };
    }
    case ADD_PRODUCT_TO_WATCH_LIST.SUCCESS: {
      const addProductToWatchListResponse =
        action.payload as addRemoveProductInWatchListPayload;
      const watchedProducts = [
        ...state.watchedProductList,
        {
          productId: addProductToWatchListResponse.productId,
          storeId: addProductToWatchListResponse.storeId,
          watchers: [addProductToWatchListResponse.watcher],
        },
      ];
      return {
        ...state,
        watchedProductList: watchedProducts,
      };
    }
    case REMOVE_PRODUCT_FROM_WATCH_LIST.SUCCESS: {
      const removeProductFromWatchListResponse =
        action.payload as addRemoveProductInWatchListPayload;
      const watchedProducts = [...state.watchedProductList]?.filter(
        (watchedProduct) =>
          watchedProduct.productId !==
          removeProductFromWatchListResponse.productId,
      );
      return {
        ...state,
        watchedProductList: watchedProducts,
      };
    }
    case RESET_SEQUENCE_REQUESTED_BY_BOOST_BURY: {
      return {
        ...state,
        isBoostBurySequenceRequested: false,
      };
    }
    case FETCH_LOCKS_FOR_CATEGORY.SUCCESS: {
      const locksInCategoryResponse = action.payload as LocksInCategoryResponse;
      const locksInCategory =
        state.locksInCategory.length > 0
          ? locksInCategoryResponse.results?.filter(
              (newLock) =>
                !state.locksInCategory.some(
                  (existingLock) =>
                    existingLock.productId === newLock.productId &&
                    existingLock.catalogId === newLock.catalogId &&
                    existingLock.categoryId === newLock.categoryId,
                ),
            )
          : locksInCategoryResponse.results;
      return {
        ...state,
        locksInCategory: [...state.locksInCategory, ...locksInCategory],
      };
    }
    case LOCK_PRODUCT_COMPLETED: {
      const lockProductResponse = action.payload as LocksInCategory;
      const existingLockIndex = state.locksInCategory.findIndex(
        (lock) =>
          lock.catalogId === lockProductResponse.catalogId &&
          lock.categoryId === lockProductResponse.categoryId &&
          lock.position === lockProductResponse.position,
      );

      let updatedLocks: LocksInCategory[];
      if (existingLockIndex !== -1) {
        // Update existing lock
        updatedLocks = [...state.locksInCategory];
        updatedLocks[existingLockIndex] = lockProductResponse;
      } else {
        // Insert new lock
        updatedLocks = [...state.locksInCategory, lockProductResponse];
      }

      return {
        ...state,
        locksInCategory: updatedLocks,
      };
    }
    case UNLOCK_PRODUCT_COMPLETED: {
      const unlockProductResponse =
        action.payload as UnLockProductResponsePayload;
      const locksInCategory = state.locksInCategory?.filter(
        (lock) =>
          !(
            lock.catalogId === unlockProductResponse.catalogId &&
            lock.categoryId === unlockProductResponse.categoryId &&
            lock.position === unlockProductResponse.position
          ),
      );
      // Check if unlock response already exists in locksRemoveInCategory
      const isDuplicate = state.locksRemoveInCategory?.some(
        (lock) =>
          lock.catalogId === unlockProductResponse.catalogId &&
          lock.categoryId === unlockProductResponse.categoryId &&
          lock.position === unlockProductResponse.position,
      );

      const locksRemoveInCategory = isDuplicate
        ? state.locksRemoveInCategory
        : [...state.locksRemoveInCategory, unlockProductResponse];

      return {
        ...state,
        locksInCategory: locksInCategory,
        locksRemoveInCategory: locksRemoveInCategory,
      };
    }
    case RESET_LOCKS_IN_CATEGORY: {
      return {
        ...state,
        locksInCategory: [],
        locksRemoveInCategory: [],
        savedLockedProductsInCategory: [],
        savedUnlockedProductsInCategory: [],
      };
    }
    case SET_LOCKED_UNLOCKED_PRODUCTS: {
      const lockedUnlockedProducts = action.payload;

      // Check if product exists in either list before filtering
      const wasExistingInLockedListBefore =
        state.savedLockedProductsInCategory?.some(
          (product) =>
            product.productId === lockedUnlockedProducts.productId &&
            product.categoryId === lockedUnlockedProducts.categoryId,
        );
      const wasLockedInCategory = state.locksInCategory.some(
        (lock) =>
          lock.productId === lockedUnlockedProducts.productId &&
          lock.categoryId === lockedUnlockedProducts.categoryId,
      );

      const wasExistingInUnlockedListBefore =
        state.savedUnlockedProductsInCategory?.some(
          (product) =>
            product.productId === lockedUnlockedProducts.productId &&
            product.categoryId === lockedUnlockedProducts.categoryId,
        );
      const wasUnlockedInCategory = state.locksRemoveInCategory?.some(
        (lock) =>
          lock.productId === lockedUnlockedProducts.productId &&
          lock.categoryId === lockedUnlockedProducts.categoryId,
      );

      // Filter out the product from both lists
      const filteredLockedProducts =
        state.savedLockedProductsInCategory?.filter(
          (product) =>
            !(
              product.productId === lockedUnlockedProducts.productId &&
              product.categoryId === lockedUnlockedProducts.categoryId
            ),
        ) ?? [];
      const filteredUnlockedProducts =
        state.savedUnlockedProductsInCategory?.filter(
          (product) =>
            !(
              product.productId === lockedUnlockedProducts.productId &&
              product.categoryId === lockedUnlockedProducts.categoryId
            ),
        ) ?? [];

      // Only add to locked list if it wasn't just removed from unlocked list or was unlocked in database
      if (
        lockedUnlockedProducts.isLocked &&
        (!wasExistingInUnlockedListBefore || wasUnlockedInCategory)
      ) {
        filteredLockedProducts.push(lockedUnlockedProducts);
      }
      // Only add to unlocked list if it wasn't just removed from locked list or was locked in database
      else if (
        !lockedUnlockedProducts.isLocked &&
        (!wasExistingInLockedListBefore || wasLockedInCategory)
      ) {
        filteredUnlockedProducts.push(lockedUnlockedProducts);
      }

      return {
        ...state,
        savedLockedProductsInCategory: filteredLockedProducts,
        savedUnlockedProductsInCategory: filteredUnlockedProducts,
        sequenceMode:
          filteredLockedProducts.length || filteredUnlockedProducts.length > 0
            ? SEQUENCE_MODE_TYPE.EDIT
            : SEQUENCE_MODE_TYPE.VIEW,
      };
    }
    case RESET_SAVED_LOCKED_UNLOCKED_PRODUCTS: {
      return {
        ...state,
        savedLockedProductsInCategory: [],
        savedUnlockedProductsInCategory: [],
        sequenceMode: SEQUENCE_MODE_TYPE.VIEW,
      };
    }
    default:
      return state;
  }
};
