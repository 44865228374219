import React, { FC, PropsWithChildren } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { Facet, FacetBucket } from "../../store/facets/FacetTypes";
import { FACET_BUCKET_MODES } from "../../utils/Constants";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AttributeData } from "../../store/category/CategoryTypes";
import { Item, ItemManagementSFCCData } from "../../store/product/ProductTypes";
import FacetBucketValueAutocomplete from "../facets/FacetBucketValueAutocomplete";
import ItemManagementTableHead from "../item-management/ItemManagementTableHead";
import { Order, stableSort, getComparator } from "../../utils/SortTableUtils";
import CheckboxComponent from "../common/Checkbox";
import ItemManagementSFCCTableHead from "../item-management-SFCC/ItemManagementSFCCTableHead";
import ProductInventorySwatch from "../product-inventory-tile/ProductInventorySwatch";
import ReportTableHead from "../reports/ReportTableHead";
import { classicTheme } from "../../styles/themes/classic";
import VariationReassignmentTableHeader from "components/variation-reassignment-modal/VariationReassignmentTableHeader";

interface Props {
  tableId: string;
  classes?: ClassNameMap;
  localeCodes?: string[];
  attributeById?: AttributeData | null;
  attributeValueId?: string;
  keyDownHandler?: (value: any, localeCode: string, localeValue: any) => void;
  setNewFacetValue?: React.Dispatch<React.SetStateAction<string>>;
  changeHandler?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  facetById?: Facet | undefined;
  facetBucketId?: any;
  selectedLocale?: string;
  bucketKeyDownHandler?: (facetBucket: FacetBucket) => void;
  setBucketThreshold?: React.Dispatch<React.SetStateAction<string>>;
  bucketChangeHandler?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: Item[];
  rowsSFCC?: ItemManagementSFCCData[];
  rowsReport?: {
    [key: string]: string | number;
  }[];
  order?: Order;
  orderBy?: string;
  handleRequestSort?: (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => void;
  selected?: {
    [key: string]: {
      [sku: string]: boolean;
    };
  };
  useSearchResults?: boolean;
  items?: Item[];
  page?: number;
  rowsPerPage?: number;
  isSelected?: (sku: string, key: string) => boolean | undefined;
  handleCheck?: (
    event: React.MouseEvent<unknown>,
    SKU: string,
    key: string,
  ) => void;
  headerLabels?: string[];
  generateRows?: (data: ItemManagementSFCCData) => JSX.Element;
  selectedInventoryAttribute?: string;
  productInventoryByAttributeMap?: {
    [key: string]: any;
  };
  productSwatches?: {
    [key: string]: any;
  };
  productId?: string;
  analyticsData?: {
    [key: string]: string | number;
  }[];
  selectedFields?: string[];
  allFields?: string[];
  defaultFields?: string[];
  row?: string[];
  isStickyHeader?: boolean;
  data?: { [key: string]: string };
  selectedRows?: string[];
}

const TableComponent: FC<Props> = (props: PropsWithChildren<Props>) => {
  const {
    classes,
    localeCodes,
    attributeById,
    attributeValueId,
    keyDownHandler,
    setNewFacetValue,
    changeHandler,
    facetById,
    facetBucketId,
    selectedLocale,
    bucketKeyDownHandler,
    setBucketThreshold,
    bucketChangeHandler,
    rows,
    rowsSFCC,
    rowsReport,
    order,
    orderBy,
    handleRequestSort,
    selected,
    useSearchResults,
    items,
    page,
    rowsPerPage,
    isSelected,
    handleCheck,
    headerLabels,
    generateRows,
    selectedInventoryAttribute,
    productInventoryByAttributeMap,
    productSwatches,
    productId,
    analyticsData,
    selectedFields,
    allFields,
    defaultFields,
    row,
    isStickyHeader,
    data,
    selectedRows,
  } = props;
  const intl = useIntl();
  const useStyles = makeStyles((theme) =>
    createStyles({
      headerRow: {
        position: "sticky",
        top: 0,
        backgroundColor: classicTheme.palette.tableHeaderRow.main,
      },
      tableRow: {
        backgroundColor: "#ffffff",
        "&:hover": {
          backgroundColor: classicTheme.palette.tableRowHover.main,
        },
      },
      tableContainer: {
        maxHeight: "450px",
        border: `1px solid ${classicTheme.palette.neutral.tableBorderColor}`,
      },
      tableCell: {
        paddingRight: "15px !important",
      },
    }),
  );
  const tableclass = useStyles();

  switch (props.tableId) {
    case "EditAttributeValuesModalTable":
      if (
        classes &&
        localeCodes &&
        attributeById &&
        attributeValueId &&
        keyDownHandler &&
        setNewFacetValue &&
        changeHandler
      )
        return (
          <TableContainer component={Paper} className={classes.table}>
            <Table stickyHeader>
              <TableHead>
                <TableRow className={tableclass.headerRow}>
                  <TableCell
                    className={`${classes.firstColum} ${classes.firstCell}`}
                  >
                    {intl.formatMessage({
                      id: "editAttributeValuesModal.tableHeader",
                      defaultMessage: "Attribute Value",
                    })}
                  </TableCell>
                  {localeCodes.map((localeCode) => (
                    <TableCell align="center" key={localeCode}>
                      {localeCode}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {attributeById &&
                  attributeById.values.map((facetValue) => {
                    if (
                      attributeValueId &&
                      attributeValueId !== facetValue.attributeValueId
                    ) {
                      return (
                        <React.Fragment key={facetValue.attributeValueId} />
                      );
                    }
                    return (
                      <TableRow key={facetValue.attributeValueId}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.firstColum}
                        >
                          {facetValue.attributeValueId}
                        </TableCell>
                        {localeCodes.map((localeCode) => {
                          const localeValue = facetValue.descriptions.find(
                            (description) =>
                              description.localeCode === localeCode,
                          );
                          return (
                            <TableCell key={localeCode}>
                              <textarea
                                className={classes.textarea}
                                aria-label="Edit facet value"
                                defaultValue={
                                  localeValue ? localeValue.displayValue : ""
                                }
                                onBlur={() =>
                                  keyDownHandler(
                                    facetValue,
                                    localeCode,
                                    localeValue,
                                  )
                                }
                                onKeyDown={(e) => {
                                  e.persist();
                                  if (e.keyCode === 13) {
                                    e.preventDefault();
                                    keyDownHandler(
                                      facetValue,
                                      localeCode,
                                      localeValue,
                                    );
                                  }
                                }}
                                onChange={changeHandler}
                                onFocus={() => {
                                  setNewFacetValue(
                                    localeValue ? localeValue.displayValue : "",
                                  );
                                }}
                              />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      break;
    case "EditFacetBucketModalTable":
      if (
        classes &&
        facetById &&
        keyDownHandler &&
        selectedLocale &&
        setNewFacetValue &&
        bucketKeyDownHandler &&
        setBucketThreshold &&
        bucketChangeHandler &&
        changeHandler &&
        attributeById
      )
        return (
          <TableContainer component={Paper} className={classes.table}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow className={tableclass.headerRow}>
                  <TableCell
                    align="center"
                    className={`${classes.firstCell} ${classes.firstColum}`}
                  >
                    {intl.formatMessage({
                      id: "editFacetBucketModal.bucketDisplayHeader",
                      defaultMessage: "Bucket Display",
                    })}
                  </TableCell>
                  <TableCell align="center">
                    {facetById &&
                      facetById.bucketMode === FACET_BUCKET_MODES.VALUES && (
                        <>
                          {intl.formatMessage({
                            id: "editFacetBucketModal.valuesHeader",
                            defaultMessage: "Values",
                          })}
                        </>
                      )}
                    {facetById &&
                      facetById.bucketMode ===
                        FACET_BUCKET_MODES.THRESHOLDS && (
                        <>
                          {intl.formatMessage({
                            id: "editFacetBucketModal.thresholdsHeader",
                            defaultMessage: "Threshold",
                          })}
                        </>
                      )}
                    {facetById &&
                      facetById.bucketMode === FACET_BUCKET_MODES.PERIODS && (
                        <>
                          {intl.formatMessage({
                            id: "editFacetBucketModal.periodsHeader",
                            defaultMessage: "Periods",
                          })}
                        </>
                      )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {facetById &&
                  facetById.buckets.map((bucket) => {
                    const bucketId = bucket.facetBucketId;
                    const localeValue = bucket.descriptions.find(
                      (description) =>
                        description.localeCode === selectedLocale,
                    );
                    if (facetBucketId && facetBucketId !== bucketId) {
                      return <React.Fragment key={bucketId} />;
                    }
                    return (
                      <TableRow key={bucketId}>
                        <TableCell
                          key={selectedLocale}
                          className={classes.firstColum}
                        >
                          <textarea
                            className={classes.textarea}
                            aria-label="Edit facet value"
                            defaultValue={
                              localeValue ? localeValue.displayValue : ""
                            }
                            onBlur={() =>
                              keyDownHandler(
                                bucket,
                                selectedLocale,
                                localeValue,
                              )
                            }
                            onKeyDown={(e) => {
                              e.persist();
                              if (e.keyCode === 13) {
                                e.preventDefault();
                                keyDownHandler(
                                  bucket,
                                  selectedLocale,
                                  localeValue,
                                );
                              }
                            }}
                            onChange={changeHandler}
                            onFocus={() => {
                              setNewFacetValue(
                                localeValue ? localeValue.displayValue : "",
                              );
                            }}
                            wrap="off"
                          />
                        </TableCell>
                        {facetById.bucketMode === FACET_BUCKET_MODES.VALUES && (
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.valueColumn}
                          >
                            <FacetBucketValueAutocomplete
                              bucket={bucket}
                              attribute={attributeById}
                            />
                          </TableCell>
                        )}
                        {(facetById.bucketMode ===
                          FACET_BUCKET_MODES.THRESHOLDS ||
                          facetById.bucketMode ===
                            FACET_BUCKET_MODES.PERIODS) && (
                          <TableCell component="th" scope="row">
                            <textarea
                              className={classes.textarea}
                              aria-label="Edit facet value"
                              defaultValue={bucket.threshold}
                              onBlur={() => {
                                bucketKeyDownHandler(bucket);
                              }}
                              onKeyDown={(e) => {
                                e.persist();
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                  bucketKeyDownHandler(bucket);
                                }
                              }}
                              onChange={bucketChangeHandler}
                              onFocus={() => {
                                setBucketThreshold(`${bucket.threshold}`);
                              }}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      break;
    case "ItemManagementTable":
      if (
        rows &&
        classes &&
        order &&
        orderBy !== undefined &&
        handleRequestSort &&
        selected &&
        items &&
        page !== undefined &&
        rowsPerPage &&
        isSelected &&
        handleCheck
      ) {
        return (
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              {rows.length === 0 ? (
                <TableBody>
                  <TableCell className={classes.noRecordsTablecell} colSpan={6}>
                    {intl.formatMessage({
                      id: "itemManagementModal.NoData",
                      defaultMessage: "No records found",
                    })}
                  </TableCell>
                </TableBody>
              ) : null}

              <ItemManagementTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                selected={selected}
                rows={useSearchResults ? rows : items}
              />
              <TableBody>
                {stableSort(
                  useSearchResults ? rows : items,
                  getComparator(order, orderBy),
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => {
                    return (
                      <TableRow
                        className={tableclass.tableRow}
                        tabIndex={-1}
                        key={data["SKU"]}
                      >
                        {Object.keys(useSearchResults ? rows[0] : items[0]).map(
                          (key: string, index: number) => {
                            if (typeof data[key] !== "boolean") {
                              return (
                                <TableCell
                                  align={"center"}
                                  key={`${data["SKU"]}_${index}`}
                                  padding="none"
                                >
                                  {data[key]}
                                </TableCell>
                              );
                            } else {
                              const isItemSelected = isSelected(
                                data["SKU"],
                                key,
                              );
                              const defaultSelected = data[key] as boolean;
                              return (
                                <TableCell
                                  align={"center"}
                                  key={`${data["SKU"]}_${index}`}
                                  padding="none"
                                >
                                  <CheckboxComponent
                                    checkboxId="ItemManagementTable"
                                    checked={
                                      isItemSelected !== undefined
                                        ? isItemSelected
                                        : defaultSelected
                                    }
                                    handleCheck={handleCheck}
                                    handleParam={[data["SKU"], key]}
                                  />
                                </TableCell>
                              );
                            }
                          },
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
      break;
    case "ItemManagementSFCCTable":
      if (
        classes &&
        order &&
        orderBy &&
        handleRequestSort &&
        headerLabels &&
        rowsSFCC &&
        page &&
        rowsPerPage &&
        generateRows
      )
        return (
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <ItemManagementSFCCTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headerLabels={headerLabels}
              />
              <TableBody>
                {rowsSFCC.length ? (
                  stableSort(
                    rowsSFCC,
                    getComparator<null | boolean | string | number, string>(
                      order,
                      orderBy,
                    ),
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data) => {
                      return generateRows(data);
                    })
                ) : (
                  <TableRow
                    className={tableclass.tableRow}
                    style={{ height: "46px" }}
                  >
                    <TableCell
                      colSpan={12}
                      padding="none"
                      align="center"
                      className={classes.confirmMsg}
                      style={{ fontSize: "18PX" }}
                    >
                      NO PRODUCT FOUND!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        );
      break;
    case "ProductInventoryTable":
      if (
        classes &&
        selectedInventoryAttribute &&
        productInventoryByAttributeMap &&
        productSwatches &&
        productId
      )
        return (
          <TableContainer className={classes.container}>
            <Table aria-label="product inv table">
              <TableHead>
                <TableRow className={tableclass.headerRow}>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.title}>
                      {selectedInventoryAttribute
                        ? selectedInventoryAttribute.toUpperCase()
                        : ""}
                    </div>
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <div className={classes.title}>INV</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productInventoryByAttributeMap[selectedInventoryAttribute] &&
                  Object.keys(
                    productInventoryByAttributeMap[selectedInventoryAttribute],
                  ).map((key) => (
                    <TableRow className={tableclass.tableRow} key={key}>
                      <TableCell className={classes.swatchTableCell}>
                        {productSwatches[key] ? (
                          <ProductInventorySwatch
                            key={`${productSwatches[key].color}-${productId}`}
                            {...productSwatches[key]}
                          />
                        ) : (
                          <span />
                        )}
                        <span>{key}</span>
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        <div className={classes.cell}>
                          {
                            productInventoryByAttributeMap[
                              selectedInventoryAttribute
                            ][key]
                          }
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      break;
    case "ReportTable":
      if (
        classes &&
        order &&
        orderBy &&
        handleRequestSort &&
        rowsReport &&
        page &&
        rowsPerPage &&
        analyticsData &&
        selectedFields &&
        allFields &&
        defaultFields &&
        row
      )
        return (
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <ReportTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rows={useSearchResults ? rowsReport : analyticsData}
                selectedFields={selectedFields}
                allFields={allFields}
              />
              <TableBody>
                {stableSort(
                  useSearchResults ? rowsReport : analyticsData,
                  getComparator(order, orderBy),
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .filter(() => selectedFields.length > 0)
                  .map((data, index) => {
                    return (
                      <TableRow
                        className={tableclass.tableRow}
                        tabIndex={index}
                        key={Object.values(data)[0]}
                      >
                        {row.map((key: string, index: number) => {
                          return (
                            <TableCell
                              align={"center"}
                              key={`${data[key]}_${index}`}
                              padding="none"
                            >
                              <div
                                className={clsx(classes.tableCellText, {
                                  [classes.leftAlign]:
                                    index < defaultFields.length,
                                })}
                              >
                                {data[key]}
                              </div>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      break;
    case "VariationReassignmentList":
      if (
        classes &&
        order &&
        orderBy !== undefined &&
        handleRequestSort &&
        items &&
        handleCheck
      ) {
        return (
          <TableContainer className={tableclass.tableContainer}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              {items.length === 0 ? (
                <TableBody>
                  <TableCell className={classes.noRecordsTablecell} colSpan={6}>
                    {intl.formatMessage({
                      id: "variationReassignment.NoData",
                      defaultMessage: "No records found",
                    })}
                  </TableCell>
                </TableBody>
              ) : null}

              <VariationReassignmentTableHeader
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rows={items}
              />
              <TableBody>
                {stableSort(items, getComparator(order, orderBy)).map(
                  (data) => {
                    return (
                      <TableRow
                        className={tableclass.tableRow}
                        tabIndex={-1}
                        key={data["SKU"]}
                      >
                        {Object.keys(items[0]).map(
                          (key: string, index: number) => {
                            return (
                              <TableCell
                                align={"center"}
                                key={`${data["SKU"]}_${index}`}
                                padding="none"
                                className={tableclass.tableCell}
                              >
                                {data[key]}
                              </TableCell>
                            );
                          },
                        )}
                        <TableCell
                          align="center"
                          padding="none"
                          className={tableclass.tableCell}
                        >
                          <CheckboxComponent
                            checkboxId="ReAssignCheckbox"
                            checked={
                              selectedRows?.includes(data["SKU"]) || false
                            }
                            handleCheck={handleCheck}
                            handleParam={[data["SKU"], "reAssign"]}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  },
                )}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
      break;
    default:
      if (data)
        return (
          <TableContainer component={Paper}>
            <Table stickyHeader={isStickyHeader ?? false}>
              <TableHead>
                <TableRow className={tableclass.headerRow}>
                  {Object.keys(data).map((header) => (
                    <TableCell>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(data).map((e) => (
                  <TableRow className={tableclass.tableRow} key={e}>
                    {Object.values(e).map((value) => (
                      <TableCell>{value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      break;
  }
  return <></>;
};

export default TableComponent;
