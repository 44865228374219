import AppState from "../AppState";

export const selectProductBadgeList = (state: AppState) => {
  return state.productBadge.badges ?? [];
};

export const productBadgeId = (state: AppState) => {
  return state.productBadge.productBadgeAssignments ?? [];
};

export const selectProductBadgeDetails = (state: AppState, badgeId: string) => {
  return state.productBadge.badges?.find((badge) => badge.badgeId === badgeId);
};

export const selectSelectedStoreId = (state: AppState) => {
  return state.productBadge.selectedStore;
};

export const selectIsLoading = (state: AppState) => {
  return state.productBadge.isLoading;
};

export const selectProductBadgeAssignmentsList = (
  state: AppState,
  productId: string,
) => {
  return (
    state.productBadge.productBadgeAssignments?.find(
      (badge) => badge.productId === productId,
    )?.badges ?? []
  );
};

export const selectProductBadgeAssignmentsLoading = (state: AppState) => {
  return state.productBadge.productBadgeAssignmentsLoading;
};
