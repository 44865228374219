import React from "react";
import { Badge, IconButton, Menu, MenuItem } from "@material-ui/core";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { accountManagement, logout } from "../../services/UserService";
import { setModalState } from "../../store/modal/ModalActions";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { loadProductAlertsSchedule } from "../../store/product-alerts-schedule-modal/ProductAlertsScheduleAction";
import { useDispatch, useSelector } from "react-redux";
import { selectProductAlertsScheduleData } from "../../store/product-alerts-schedule-modal/ProductAlertsScheduleSelectors";
import { CallerAccountInfoObject } from "../../utils/AppConfigUtils";
import { LogoutConfirmationModalId } from "../header/LogoutConfirmationModal";
//import { useHistory } from "react-router";
import Icons from "./Icons";
import { resetJobsList } from "store/businessRules-list/BusinessRulesListActions";
import { LoadProductBadgeList } from "store/product-badge/ProductBadgeActions";
import { selectProductBadgeList } from "store/product-badge/ProductSelectors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  }),
);

const SubMenuItem = ({ onClick, primaryText }) => {
  return (
    <MenuItem onClick={onClick}>
      <Typography variant="inherit" color="textSecondary">
        {primaryText}
      </Typography>
    </MenuItem>
  );
};

interface InteractiveMenuProps {
  menuId: string;
  classes?: ClassNameMap;
  handleClose?: () => void;
  subMenuOpen?: boolean;
  anchorEl?: any;
  subItems?: string[];
  profileAccessible?: boolean;
  isProductAlertsEnabled?: string;
  callerAccounts?: CallerAccountInfoObject;
  mobileMenuId?: string;
  handleMenuClose?: () => void;
  handleProfileMenuOpen?: (
    value: string,
    event: React.MouseEvent<HTMLElement>,
  ) => void;
  logoutSupported?: boolean;
  isInReportsTab?: boolean;
  shouldUpdateEditSequenceListFlag?: boolean;
  open?: boolean;
  onSubMenuItemClick?: (primaryText) => void;
  data?: { [key: string]: string };
  children?: JSX.Element;
  isProductBadgeEnabled?: string;
}

const InteractiveMenu: React.FC<InteractiveMenuProps> = (props) => {
  const {
    menuId,
    classes,
    handleClose,
    subMenuOpen,
    anchorEl,
    subItems,
    onSubMenuItemClick,
    profileAccessible,
    isProductAlertsEnabled,
    callerAccounts,
    handleMenuClose,
    mobileMenuId,
    logoutSupported,
    isInReportsTab,
    shouldUpdateEditSequenceListFlag,
    handleProfileMenuOpen,
    open,
    data,
    children,
    isProductBadgeEnabled,
  } = props;
  const intl = useIntl();
  const menuclass = useStyles();
  const dispatch = useDispatch();
  const productAlertScheduleData = useSelector(selectProductAlertsScheduleData);
  const productBadges = useSelector(selectProductBadgeList);
  //const history = useHistory();

  switch (menuId) {
    case "MenuItemWithSubItems":
      if (handleClose && anchorEl && subItems && onSubMenuItemClick)
        return (
          <Menu
            disableEnforceFocus
            anchorEl={anchorEl}
            open={subMenuOpen ?? false}
            onClose={handleClose}
            elevation={0}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {subItems.map((item, index) => (
              <SubMenuItem
                key={index}
                primaryText={item}
                onClick={() => onSubMenuItemClick(item)}
              />
            ))}
          </Menu>
        );
      break;
    case "Report":
      if (classes && anchorEl && handleClose)
        return (
          <Menu
            id="export-menu"
            open={open ?? false}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              className={classes.item}
              onClick={() => {
                console.log("PDF");
                handleClose();
              }}
            >
              PDF
            </MenuItem>
            <MenuItem
              className={classes.item}
              onClick={() => {
                console.log("Excel");
                handleClose();
              }}
            >
              Excel
            </MenuItem>
          </Menu>
        );
      break;
    case "RenderMenu":
      if (anchorEl && handleClose && callerAccounts)
        return (
          <Menu
            anchorEl={anchorEl}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={open ?? false}
            onClose={handleClose}
          >
            {profileAccessible && (
              <MenuItem
                onClick={() => {
                  accountManagement();
                  handleClose();
                }}
              >
                {intl.formatMessage({
                  id: "header.profileLabel",
                  defaultMessage: "Profile",
                })}
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                dispatch(setModalState("PreferencesModal", true));
                handleClose();
              }}
            >
              {intl.formatMessage({
                id: "header.preferencesLabel",
                defaultMessage: "Preferences",
              })}
            </MenuItem>
            {callerAccounts.accounts.length > 1 && (
              <MenuItem
                onClick={() => {
                  dispatch(
                    setModalState("AccountModalId", true, {
                      switchAccounts: true,
                    }),
                  );
                  dispatch(resetJobsList());
                  handleClose();
                }}
              >
                {intl.formatMessage({
                  id: "header.accountsLabel",
                  defaultMessage: "Switch Accounts",
                })}
              </MenuItem>
            )}
            {/* {menuItems.map((menuItem, index) => (
          <MenuItemWithSubItems
            key={menuItem.id}
            primaryText={menuItem.primaryText}
            subItems={menuItem.subItems}
            onClose={handleClose}
            onSubMenuItemClick={menuItem.onSubMenuItemClick}
          />
        ))} */}
          </Menu>
        );
      break;
    case "RenderConfigMenu":
      if (anchorEl && handleClose)
        return (
          <Menu
            anchorEl={anchorEl}
            keepMounted
            transformOrigin={{ vertical: "center", horizontal: "right" }}
            open={open ?? false}
            onClose={handleClose}
          >
            {/* <MenuItem
              onClick={() => {
                history.push("/business-rules");
                handleClose();
              }}
            >
              {intl.formatMessage({
                id: "header.businessRules",
                defaultMessage: "Business Rules",
              })}
            </MenuItem> */}
            {isProductAlertsEnabled === "true" && (
              <MenuItem
                onClick={() => {
                  productAlertScheduleData?.hour === null &&
                    dispatch(loadProductAlertsSchedule());

                  dispatch(setModalState("ProductAlertsScheduleModal", true));
                  handleClose();
                }}
              >
                {intl.formatMessage({
                  id: "header.productAlertsScheduleLabel",
                  defaultMessage: "Product Alerts Schedule",
                })}
              </MenuItem>
            )}
            {isProductBadgeEnabled === "true" && (
              <MenuItem
                onClick={() => {
                  productBadges.length === 0 &&
                    dispatch(LoadProductBadgeList());
                  dispatch(setModalState("BadgeManagementModal", true));
                  handleClose();
                }}
              >
                {intl.formatMessage({
                  id: "header.badgeManagementLabel",
                  defaultMessage: "Badge Management",
                })}
              </MenuItem>
            )}
          </Menu>
        );
      break;
    case "RenderCreateRuleMenu":
      if (anchorEl && handleClose)
        return (
          <Menu
            anchorEl={anchorEl}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={open ?? false}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                dispatch(setModalState("WeightedSortingRuleModal", true));
                handleClose();
              }}
            >
              {intl.formatMessage({
                id: "header.weightedRule",
                defaultMessage: "Weighted Sorting Rule",
              })}
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(setModalState("AttributeSortingRuleModal", true));
                handleClose();
              }}
            >
              {intl.formatMessage({
                id: "header.attributeRule",
                defaultMessage: "Attribute Sorting Rule",
              })}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
              }}
            >
              {intl.formatMessage({
                id: "header.multiCriteriaRule",
                defaultMessage: "Multi-Criteria Sorting Rule",
              })}
            </MenuItem>
          </Menu>
        );
      break;
    case "HeaderMobile":
      if (
        anchorEl &&
        handleClose &&
        mobileMenuId &&
        handleMenuClose &&
        handleProfileMenuOpen
      )
        return (
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={open ?? false}
            onClose={handleClose}
          >
            <MenuItem>
              <IconButton
                aria-label="show 11 new notifications"
                color="inherit"
              >
                <Badge badgeContent={11} color="primary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <p>
                {intl.formatMessage({
                  id: "header.notificationsLabel",
                  defaultMessage: "Notifications",
                })}
              </p>
            </MenuItem>
            <MenuItem onClick={(e) => handleProfileMenuOpen("", e)}>
              <IconButton
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <p>
                {intl.formatMessage({
                  id: "header.profileLabel",
                  defaultMessage: "Profile",
                })}
              </p>
            </MenuItem>
            {logoutSupported && (
              <MenuItem
                onClick={() => {
                  if (!isInReportsTab && shouldUpdateEditSequenceListFlag) {
                    dispatch(setModalState(LogoutConfirmationModalId, true));
                  } else {
                    logout();
                  }
                  handleMenuClose();
                }}
              >
                <IconButton
                  aria-label="Log out"
                  aria-controls="primary-search-account-menu"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <Icons iconId="SignoutIcon" />
                </IconButton>
                <p>
                  {intl.formatMessage({
                    id: "header.logOutLabel",
                    defaultMessage: "Log Out",
                  })}
                </p>
              </MenuItem>
            )}
          </Menu>
        );
      break;
    default:
      if (handleClose && anchorEl && data)
        return (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={menuclass.root}
          >
            {Object.values(data).map((e) => {
              return <MenuItem onClick={handleClose}>{e}</MenuItem>;
            })}
          </Menu>
        );
      if (handleClose && anchorEl && children)
        return (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={menuclass.root}
          >
            {children}
          </Menu>
        );
      return <></>;
  }
  return <></>;
};

export default InteractiveMenu;
