let accountId: string = "";

export const setCallerAccount = (callerAccountId: string) => {
  accountId = callerAccountId;
};

export function acquireEndpoint(
  actionTypeName: string,
  param1?: string,
  param2?: string | Record<string, string | number>,
  param3?: string,
  param4?: number,
): string {
  let endpoint;
  let envSM;

  if (param1 === "LOAD_ANALYTICS_SERVICE_URL") {
    endpoint = process.env.REACT_APP_ANALYTICS_SERVICE_URL;
  } else if (param1 === "BUSINESS_RULES_SERVICE_URL") {
    endpoint = process.env.REACT_APP_BUSINESS_RULES_SERVICE_URL;
  } else if (param1 === "LOAD_BUSINESS_RULES_SERVICE_URL") {
    endpoint = process.env.REACT_APP_BUSINESS_RULES_SERVICE_URL;
  } else if (param1 === "LOAD_PLATFORM_INDEPENDENT_URL") {
    endpoint = process.env.REACT_APP_SM_HTTP_ENDPOINT;
  } else if (param1 === "LOAD_APP_CONFIG_SERVICE_URL") {
    endpoint = process.env.REACT_APP_CONFIG_SERVICE_URL;
  } else if (param1 === "LOAD_ASYNC_REQUEST_SERVICE_URL") {
    endpoint = process.env.REACT_APP_ASYNC_REQUEST_SERVICE_URL;
  } else if (param1 === "LOAD_SOCIAL_ANALYTICS_SERVICE_URL") {
    endpoint = process.env.REACT_APP_SOCIAL_ANALYTICS_SERVICE_URL;
  } else if (param1 === "LOAD_PRODUCT_POSITION_SERVICE_URL") {
    endpoint = process.env.REACT_APP_PRODUCT_POSITION_SERVICE_URL;
  } else {
    envSM = process.env.REACT_APP_SM_ENV;
    endpoint = process.env.REACT_APP_SM_HTTP_ENDPOINT || "localhost:3000";

    // Comment sfcc & magento in env to run platform independent APIs

    if (
      envSM === "magento" &&
      actionTypeName !== "FETCH_PRODUCT_IDS_REQUEST" &&
      actionTypeName !== "FETCH_PRODUCT_LIST" &&
      actionTypeName !== "REFRESH_PRODUCT_REQUEST" &&
      actionTypeName !== "FETCH_PRODUCT_REQUEST" &&
      actionTypeName !== "FETCH_VARIATION_GROUP_PRODUCT_LIST" &&
      actionTypeName !== "VERIFY_CLIPBOARD_PRODUCT_CODES" &&
      actionTypeName !== "FETCH_PRODUCTS_FOR_FIND_PRODUCTS" &&
      actionTypeName !==
        "FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY_REQUEST" &&
      actionTypeName !== "FETCH_PRODUCTS_COLORS" &&
      actionTypeName !== "REFRESH_PRODUCT_COLOR_REQUEST" &&
      actionTypeName !== "FETCH_WATCHED_PRODUCT_LIST" &&
      actionTypeName !== "FETCH_PRODUCT_ALERTS_LIST"
    ) {
      endpoint = endpoint + "/magento/v1/";
    } else if (
      envSM === "sfcc" &&
      actionTypeName !== "FETCH_PRODUCT_IDS_REQUEST" &&
      actionTypeName !== "FETCH_PRODUCT_LIST" &&
      actionTypeName !== "REFRESH_PRODUCT_REQUEST" &&
      actionTypeName !== "FETCH_PRODUCT_REQUEST" &&
      actionTypeName !== "FETCH_VARIATION_GROUP_PRODUCT_LIST" &&
      actionTypeName !== "VERIFY_CLIPBOARD_PRODUCT_CODES" &&
      actionTypeName !== "FETCH_PRODUCTS_FOR_FIND_PRODUCTS" &&
      actionTypeName !==
        "FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY_REQUEST" &&
      actionTypeName !== "FETCH_PRODUCTS_COLORS" &&
      actionTypeName !== "REFRESH_PRODUCT_COLOR_REQUEST" &&
      actionTypeName !== "FETCH_WATCHED_PRODUCT_LIST" &&
      actionTypeName !== "FETCH_PRODUCT_ALERTS_LIST"
    ) {
      endpoint = endpoint + "/sfcc/v1/";
    } else {
      endpoint = endpoint + "/v1/";
    }
  }

  switch (actionTypeName) {
    case "FETCH_STORE_LIST":
      endpoint = endpoint + accountId + "/stores";
      break;
    case "FETCH_STORES":
      endpoint = endpoint + accountId + "/stores";
      break;
    case "FETCH_ALL_CATALOGS":
    case "FETCH_CATALOGS":
    case "FETCH_CATALOG_LIST":
      endpoint = endpoint + accountId + "/catalogs";
      break;
    case "FETCH_PRODUCT_BADGES_LIST":
    case "CREATE_PRODUCT_BADGE":
      endpoint = endpoint + accountId + "/product-badges";
      break;
    case "UPDATE_BADGE_PRECEDENCE":
      endpoint = endpoint + accountId + "/product-badges/" + param1;
      break;
    case "GET_PRODUCT_BADGE":
    case "UPDATE_PRODUCT_BADGE":
    case "DELETE_BADGE":
      endpoint = endpoint + accountId + "/product-badges/" + param1;
      break;
    case "QUERY_PRODUCT_BADGE_URL":
      endpoint = endpoint + accountId + "/product-badge-assignments/query";
      break;
    //  case "FETCH_PRODUCT_LIST":
    case "LOAD_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY":
    case "FETCH_FIND_PRODUCTS_CHILD_CATEGORIES":
    case "FETCH_FIND_PRODUCTS_FROM_CATEGORY":
    case "FETCH_PRODUCT_DETAILS":
      endpoint = endpoint + accountId + "/categories/" + param1 + "/products";
      break;
    case "ADD_PRODUCTS_TO_CATEGORIES":
    case "ADD_PRODUCT_IN_CATEGORY":
      endpoint = endpoint + accountId + "/categories/" + param1 + "/products";
      break;
    case "FETCH_CHILD_CATEGORIES":
    case "FETCH_ALL_CHILD_CATEGORIES":
    case "FETCH_CHILD_CATEGORIES_FOR_REPORT":
    case "FETCH_CHILD_CATEGORIES_FOR_FIND_PRODUCTS":
      endpoint =
        endpoint + accountId + "/categories/" + param1 + "/subcategories";
      break;
    case "FETCH_TOP_CATEGORIES":
    case "FETCH_ALL_TOP_CATEGORIES":
    case "FETCH_TOP_CATEGORIES_FOR_REPORT":
    case "FETCH_TOP_CATEGORIES_FOR_FIND_PRODUCTS":
      endpoint =
        endpoint + accountId + "/catalogs/" + param1 + "/top-categories";
      break;
    case "FETCH_PRODUCT_DETAILS_FOR_REPORT":
      endpoint = endpoint + accountId + "/products/" + param1;
      break;
    case "FETCH_PRODUCT_ANALYTICS":
      endpoint = endpoint + "/analytics/v1/" + accountId + "/products/query";
      break;
    case "FETCH_CATEGORIES_BY_PRODUCT_IDS":
    case "FETCH_CATEGORY_BY_PRODUCT_ID":
      endpoint = endpoint + accountId + "/product-categories/query";
      break;
    case "SAVE_UNIFIED_BUSINESS_RULES_DETAILS":
      endpoint =
        endpoint + "/business-rules/v1/" + accountId + "/sorting-rules";
      break;
    case "FETCH_UNIFIED_SORTING_RULES_LIST":
      endpoint =
        endpoint + "/business-rules/v1/" + accountId + "/sorting-rules";
      break;
    case "FETCH_ATTRIBUTES_LIST":
      endpoint = endpoint + accountId + "/product-attribute-definitions";
      break;
    case "FETCH_BUSINESS_RULES_DETAILS_BY_ID":
    case "UPDATE_BUSINESS_RULES_DETAILS_BY_RULE_ID":
    case "DELETE_BUSINESS_RULE_BY_RULE_ID":
      endpoint =
        endpoint +
        "/business-rules/v1/" +
        accountId +
        "/sorting-rules/" +
        param2;
      break;
    case "FETCH_SORTING_LIST":
      endpoint =
        endpoint + "/business-rules/v1/" + accountId + "/sorting-rules";
      break;
    case "FETCH_SORTED_PRODUCTS":
      endpoint =
        endpoint +
        "/business-rules/v1/" +
        accountId +
        `/sorting-rules/${param2}/category-sort`;
      break;
    case "UPDATE_PRODUCT_LIST_SEQUENCE":
    case "DELETE_PRODUCT_BY_CATEGORIES":
    case "REMOVE_PRODUCT_IN_CATEGORY":
      endpoint =
        endpoint + accountId + "/categories/" + param1 + "/products/" + param2;
      break;

    case "FETCH_JOBS_LIST":
      if (param2 === undefined) {
        endpoint = endpoint + "/business-rules/v1/" + accountId + `/job-logs`;
      } else {
        endpoint =
          endpoint +
          "/business-rules/v1/" +
          accountId +
          `/job-logs?since=${param2}`;
      }
      break;

    case "FETCH_AUTOMATION_LIST":
    case "SAVE_AUTOMATION_RULES_DETAILS":
      endpoint = endpoint + "/business-rules/v1/" + accountId + "/jobs";
      break;
    case "FETCH_PRODUCTS_VARIANTS":
      endpoint = endpoint + accountId + "/products/" + param1 + "/variants";
      break;
    case "UPDATE_PRODUCT_VARIANT_PUBLISH_STATE":
      endpoint =
        endpoint +
        accountId +
        "/products/" +
        param1 +
        "/variants/" +
        param2 +
        "/isPublished";
      break;
    case "DELETE_AUTOMATION_RULE_BY_JOB_ID":
    case "FETCH_AUTOMATION_RULES_DETAILS_BY_ID":
    case "UPDATE_AUTOMATION_RULES_DETAILS_BY_RULE_ID":
      endpoint =
        endpoint + "/business-rules/v1/" + accountId + "/jobs/" + param2;
      break;
    case "FETCH_AUTOMATION_RULES_CATEGORY_DETAILS_BY_ID":
      endpoint = endpoint + "/v1/" + accountId + "/categories/" + param2;
      break;
    case "FETCH_APP_CONFIG":
      endpoint = endpoint + "/app/v1/accounts/" + accountId + "/config";
      break;
    case "REFRESH_PRODUCT_REQUEST":
    case "FETCH_PRODUCT_REQUEST":
      endpoint =
        endpoint + accountId + "/cache/products/" + param1 + "?refresh=1";
      break;
    case "FETCH_PRODUCT_IDS_REQUEST":
    case "FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY_REQUEST":
      endpoint = `${endpoint}${accountId}/categories/${param1}/productIds?publishedOnly=true&`;

      if (param2) {
        if (typeof param2 === "string") {
          endpoint += `next=${param2}`;
        } else if (typeof param2 === "object") {
          const query = new URLSearchParams();
          Object.entries(param2 as Record<string, string | number>).forEach(
            ([name, value]) => query.append(name, value.toString()),
          );
          endpoint += query.toString();
        }
      }

      break;
    case "FETCH_PRODUCT_LIST":
      endpoint =
        endpoint +
        accountId +
        "/bulk/cache/products?productIds=" +
        param1 +
        (param2 === "true" ? "&refresh=1" : "");
      break;
    case "FETCH_PRODUCTS_DETAILS":
    case "FETCH_VARIATION_GROUP_PRODUCT_LIST":
    case "FETCH_PRODUCTS_FOR_FIND_PRODUCTS":
      endpoint =
        endpoint +
        accountId +
        "/bulk/cache/products?productIds=" +
        param1 +
        "&refresh=1";
      break;
    case "UPDATE_PRODUCT_SEQUENCE_LIST":
      endpoint =
        endpoint +
        "/async/v1/" +
        accountId +
        "/categories/" +
        param2 +
        "/products";
      break;
    case "GET_PRODUCT_SEQUENCE_LIST":
      endpoint = endpoint + "/async/v1/" + accountId + "/requests/" + param2;
      break;
    case "FETCH_VARIATION_GROUP_DETAILS_BY_GROUP_ID":
    case "DELETE_VARIATION_GROUP_BY_GROUP_ID":
    case "UPDATE_VARIATION_GROUP_BY_GROUP_ID":
      endpoint =
        endpoint +
        accountId +
        "/products/" +
        param1 +
        "/variation-groups/" +
        param2;
      break;
    case "ADD_VARIATION_GROUP_BY_GROUP_ID":
      endpoint =
        endpoint + accountId + "/products/" + param1 + "/variation-groups";
      break;
    case "FETCH_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID":
    case "FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID":
      endpoint =
        endpoint + accountId + "/products/" + param1 + "/variation-attributes";
      break;
    case "FETCH_VARIATION_GROUP_IDS":
      endpoint =
        endpoint + accountId + "/products/" + param1 + "/variation-groups";
      break;
    case "UPDATE_VARIATION_MANAGEMENT_PUBLISH_FLAG":
      endpoint = endpoint + accountId + "/products/" + param1 + "/isPublished";
      break;
    case "UPDATE_PRODUCT_ISPUBLISHED_STATE":
      endpoint = endpoint + accountId + "/products/" + param1 + "/isPublished";
      break;
    case "VERIFY_CLIPBOARD_PRODUCT_CODES":
      endpoint = endpoint + accountId + "/product-ids/" + param1;
      break;
    case "UPDATE_PRODUCT_COLOR_IDS_SEQUENCE":
      endpoint = endpoint + accountId + "/products/" + param1 + "/colorIds";
      break;
    case "UPDATE_COLOR_MANAGEMENT_PUBLISH_FLAG":
      endpoint =
        endpoint +
        accountId +
        "/products/" +
        param1 +
        "/colorIds/" +
        (typeof param2 === "string" && encodeURIComponent(param2)) +
        "/isPublished";
      break;
    case "CALL_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API":
      endpoint = endpoint + accountId + "/products/" + param1 + "/leadingImage";
      break;
    case "CALL_REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API":
      endpoint = endpoint + accountId + "/products/" + param1 + "/leadingImage";
      break;
    case "UPDATE_CATEGORY_LEADING_COLOR_FOR_PRODUCT":
      endpoint =
        endpoint +
        accountId +
        "/categories/" +
        param1 +
        "/products/" +
        param2 +
        "/leadingColor";
      break;
    case "FETCH_PENDING_JOBS_LIST":
      endpoint =
        endpoint + "/business-rules/v1/" + accountId + "/jobs?active=true";
      break;
    case "FETCH_PRODUCT_SOCIAL_ANALYTICS":
      endpoint =
        endpoint +
        "/v1/" +
        accountId +
        "/cached/products/query" +
        (param2 === "true" ? "?refresh=1" : "");
      break;
    case "FETCH_PRODUCTS_COLORS":
      endpoint =
        endpoint +
        accountId +
        "/bulk/cache/products/colors?productIds=" +
        param1 +
        (param2 === "true" ? "&refresh=1" : "");
      break;
    case "REFRESH_PRODUCT_COLOR_REQUEST":
      endpoint =
        endpoint +
        accountId +
        "/cache/products/" +
        param1 +
        "/colors?refresh=1";
      break;
    case "FETCH_ADAPTER_CONFIG":
      endpoint = endpoint + "/v1/" + accountId + "/adapter-info";
      break;
    case "FETCH_CALLER_ACCOUNTS":
      endpoint = endpoint + "/app/v1/my-accounts?include=configs&status=active";
      break;
    case "FETCH_SYSTEM_GENERATED_SORTING_RULES_LIST":
      endpoint =
        endpoint +
        "/business-rules/v1/" +
        accountId +
        "/generated-sorting-rules";
      break;
    case "FETCH_WATCHED_PRODUCT_LIST":
      endpoint =
        process.env.REACT_APP_PRODUCT_ALERTS_SERVICE_URL +
        "/product-alerts/v1/" +
        accountId +
        "/watched-products" +
        `?watcher=${param1}`;
      break;
    case "FETCH_PRODUCT_ALERTS_LIST":
      endpoint =
        process.env.REACT_APP_PRODUCT_ALERTS_SERVICE_URL +
        "/product-alerts/v1/" +
        accountId +
        `/alerts?watcher=${param1}`;
      break;
    case "ADD_PRODUCT_TO_WATCH_LIST":
      endpoint =
        process.env.REACT_APP_PRODUCT_ALERTS_SERVICE_URL +
        "/product-alerts/v1/" +
        accountId +
        "/watch-product";
      break;
    case "REMOVE_PRODUCT_FROM_WATCH_LIST":
      endpoint =
        process.env.REACT_APP_PRODUCT_ALERTS_SERVICE_URL +
        "/product-alerts/v1/" +
        accountId +
        "/unwatch-product";
      break;
    case "FETCH_PRODUCT_ALERTS_SCHEDULE_DATA":
    case "UPDATE_PRODUCT_ALERTS_SCHEDULE_INFO":
    case "DELETE_PRODUCT_ALERTS_SCHEDULE_INFO":
      endpoint =
        process.env.REACT_APP_PRODUCT_ALERTS_SERVICE_URL +
        "/product-alerts/v1/" +
        accountId +
        "/schedule";
      break;
    case "LOAD_SINGLE_PRODUCT_ANALYTICS_STATE":
      endpoint =
        endpoint + "/analytics/v1/" + accountId + "/products/" + param2;
      break;
    case "FETCH_PRODUCT_ATTRIBUTES":
      endpoint = endpoint + accountId + "/product-attribute-definitions/query";
      break;
    case "FETCH_CLUSTER_LIST":
      endpoint =
        process.env.REACT_APP_AI_CLUSTER_SERVICE_URL +
        "/v1/" +
        accountId +
        "/clusters?categoryId=" +
        param1 +
        "&storeId=" +
        param2;
      break;
    case "ADD_CLUSTER_EMBEDDINGS_BY_CATEGORY":
      endpoint =
        process.env.REACT_APP_AI_CLUSTER_SERVICE_URL +
        "/v1/" +
        accountId +
        "/async-cluster?refresh=" +
        param1;
      break;
    case "FETCH_CLUSTER_REQUEST_BY_CATEGORY":
      endpoint =
        process.env.REACT_APP_AI_CLUSTER_SERVICE_URL +
        "/v1/" +
        accountId +
        "/async-cluster/request/" +
        param1;
      break;
    case "FETCH_SIMILAR_PRODUCTS_BY_CATEGORY":
      endpoint =
        process.env.REACT_APP_AI_CLUSTER_SERVICE_URL +
        "/v1/" +
        accountId +
        "/subsitutions/products/" +
        param1;
      break;
    case "UPDATE_PRODUCT_BADGE_ASSIGNMENTS":
      endpoint =
        endpoint + accountId + "/products/" + param1 + "/badges/" + param2;
      break;
    case "REASSIGN_VARIATIONS":
      endpoint =
        endpoint + accountId + "/products/" + param1 + "/variationBaseId";
      break;
    case "LOCK_PRODUCT":
      endpoint =
        endpoint +
        "/product-position/v1/" +
        accountId +
        "/locks/catalogs/" +
        param2 +
        "/categories/" +
        param3 +
        "/positions/" +
        param4;
      break;
    case "UNLOCK_PRODUCT":
      endpoint =
        endpoint +
        "/product-position/v1/" +
        accountId +
        "/locks?catalogId=" +
        param2 +
        "&categoryId=" +
        param3 +
        "&position=" +
        param4;
      break;
    case "FETCH_LOCKS_FOR_CATEGORY":
      endpoint =
        endpoint +
        "/product-position/v1/" +
        accountId +
        "/locks?catalogId=" +
        param2 +
        "&categoryId=" +
        param3;
      break;
    case "FETCH_CATEGORY_DETAILS_BY_ID":
      endpoint = endpoint + accountId + "/categories/" + param1;
      break;
    default:
      break;
  }
  return endpoint;
}
