import React, { useRef, useContext, useCallback } from "react";
import ReactGA from "react-ga";
import styled from "styled-components";
import {
  useDrop,
  DropTargetMonitor,
  DragSourceMonitor,
  useDrag,
} from "react-dnd";

import { ProductSequenceData } from "../store/product-list/ProductListTypes";
import ProductTile, { DragItem } from "./ProductTile";
import { ViewContext } from "../context/view-context";
import { useSelector } from "react-redux";
import {
  selectIsProductDetailRefereshing,
  selectProducts,
} from "store/product/ProductSelectors";
import AppState from "store/AppState";
import {
  isRefreshingAllProducts,
  selectLockedProductInCurrentCategory,
} from "store/product-list/ProductListSelectors";
import { Skeleton } from "@mui/material";
import { selectCurrentCategory } from "store/category/CategorySelectors";
import { selectCurrentCatalogId } from "store/catalog/CatalogSelectors";

const StyledProductTileWrapper = styled.div<{
  isDragging: boolean;
  isSelected: boolean;
  isProductDetailRefreshing?: boolean;
  isRefreshingEveryProduct?: boolean;
}>`
  padding: 5px;
  cursor: move;
  & > div {
    opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
    border: ${({ isSelected }) =>
      isSelected ? " 1px dotted #599dbb" : " 1px solid #fff"};
  }
  pointer-events: ${({
    isProductDetailRefreshing,
    isRefreshingEveryProduct,
  }) => (isProductDetailRefreshing || isRefreshingEveryProduct ? "none" : "")};
`;

const StyledInsertLineLeft = styled.div<{
  isBorder: boolean;
}>`
  ${({ theme }) => `
    position: absolute;
    top: 5px;
    background-color: ${theme.palette.primary.main};
    border-radius: 5px;
    width: 4px;
    bottom: 5px;
    border: none !important;
  `};
  left: ${({ isBorder }) => (isBorder ? 0 : "-2px")};
`;

const StyledInsertLineRight = styled.div<{
  isBorder: boolean;
}>`
  ${({ theme }) => `
    position: absolute;
    top: 5px;
    background-color: ${theme.palette.primary.main};
    border-radius: 5px;
    width: 4px;
    left: auto;
    bottom: 5px;
    border: none !important;
  `};
  right: ${({ isBorder }) => (isBorder ? 0 : "-2px")};
`;

interface Props {
  style: React.CSSProperties;
  columnIndex: number;
  rowIndex: number;
  data: {
    onSelectionChange: (
      itemId: string,
      cmdKeyActive: boolean,
      shiftKeyActive: boolean,
    ) => void;
    onItemDragStart: (dragItem: DragItem) => void;
    onDragComplete: (dragItem: DragItem, index: number) => void;
    onMove: (hoverIndex: number, insertLineIndex: number) => void;
    OnQuickMoveCompleteAction: (productId: string, moveTo: number) => void;
    hoveredItemIndex: number;
    draggedItemsIds: string[];
    selectedItems: ProductSequenceData[];
    insertLineIndex: number;
    insertIndexRef: React.RefObject<{ insertIndex: number }>;
    newSequence: ProductSequenceData[];
  };
}

const ProductTileWrapper: React.FC<Props> = (props) => {
  const { style, columnIndex, rowIndex, data } = props;

  const {
    onSelectionChange,
    onItemDragStart,
    onDragComplete,
    onMove,
    OnQuickMoveCompleteAction,
    hoveredItemIndex,
    draggedItemsIds,
    newSequence,
    selectedItems,
    insertLineIndex,
    insertIndexRef,
  } = data;
  const viewContext = useContext(ViewContext);
  const ref = useRef<HTMLDivElement>(null);

  const index = rowIndex * viewContext.styles.column + columnIndex;
  const prevItem = index > 0 ? newSequence[index - 1] : null;
  const nextItem = index < newSequence.length ? newSequence[index + 1] : null;
  const product = newSequence[index] ?? {};

  const isDragging = draggedItemsIds.includes(product.productId);
  const isDraggingPrevCard =
    !!prevItem && draggedItemsIds.includes(prevItem.productId);
  const isDraggingNextCard =
    !!nextItem && draggedItemsIds.includes(nextItem.productId);
  const shouldInsertLineOnLeft =
    !isDragging &&
    !isDraggingPrevCard &&
    hoveredItemIndex === index &&
    insertLineIndex === index;
  const shouldInsertLineOnRight =
    !isDragging &&
    !isDraggingNextCard &&
    hoveredItemIndex === index &&
    insertLineIndex === index + 1;

  const selectedItemIds = selectedItems.map((item) => item.productId);
  const isSelected = selectedItemIds.includes(product.productId);
  const hasProductsSelectedFromList = selectedItemIds.length > 0;
  const isProductDetailRefreshing = useSelector((state: AppState) =>
    selectIsProductDetailRefereshing(state, product.productId),
  );
  const isRefreshingEveryProduct = useSelector(isRefreshingAllProducts);
  const isProductDetailAvailable = useSelector(
    (state: AppState) => selectProducts(state)[product.productId] !== undefined,
  );

  const categoryId = useSelector(selectCurrentCategory);
  const catalogId = useSelector(selectCurrentCatalogId);

  // Get locked status
  const lockedProductSelector = useSelector(
    (state: AppState) => (productId: string, position: number) =>
      selectLockedProductInCurrentCategory(
        state,
        categoryId,
        catalogId,
        productId,
        position,
      ),
  );

  // Helper function to start index from 1
  const getChangedPosition = useCallback((index: number) => {
    return index + 1;
  }, []);

  // Update isItemLocked to use one-based position
  const isItemLocked = useCallback(
    (productId: string, index: number) => {
      return lockedProductSelector(productId, getChangedPosition(index));
    },
    [lockedProductSelector, getChangedPosition],
  );

  const [, drag] = useDrag({
    item: {
      type: "PRODUCT",
      items: [],
      itemsDragStack: [],
      draggedItem: product,
      clientWidth: 0,
      clientHeight: 0,
    },
    canDrag: () => {
      // Prevent dragging if this product is locked
      if (isItemLocked(product.productId, index)) {
        return false;
      }
      // Check if any selected items are locked
      if (selectedItems.find((item) => item.productId === product.productId)) {
        const hasLockedItems = selectedItems.some((selectedItem) => {
          const selectedItemIndex = newSequence.findIndex(
            (seq) => seq.productId === selectedItem.productId,
          );
          return isItemLocked(selectedItem.productId, selectedItemIndex);
        });
        if (hasLockedItems) {
          return false;
        }
      }
      return true;
    },
    begin: (monitor: DragSourceMonitor) => {
      if (isItemLocked(product.productId, index)) {
        return undefined;
      }

      ReactGA.event({
        category: "Product",
        action: "Drag Products Action",
      });
      const draggedItem = product;
      let items: ProductSequenceData[];

      // Filter out locked products from selection
      if (selectedItems.find((item) => item.productId === product.productId)) {
        items = selectedItems.filter(
          (item, idx) => !isItemLocked(item.productId, idx),
        );
      } else {
        items = !isItemLocked(product.productId, index) ? [draggedItem] : [];
      }

      if (items.length === 0) {
        return undefined;
      }
      const otherItems = items.concat();
      otherItems.splice(
        items.findIndex((item) => item.productId === product.productId),
        1,
      );
      const itemsDragStack = [draggedItem, ...otherItems];
      const clientHeight = ref.current?.clientHeight ?? 0;
      const clientWidth = ref.current?.clientWidth ?? 0;
      onItemDragStart({
        type: "PRODUCT",
        items,
        itemsDragStack,
        draggedItem,
        clientWidth,
        clientHeight,
      });
      return {
        type: "PRODUCT",
        items,
        itemsDragStack,
        draggedItem,
        clientWidth,
        clientHeight,
      };
    },
    end: (item, monitor) => {
      if (item && !isItemLocked(product.productId, index)) {
        onDragComplete(monitor.getItem(), data.insertLineIndex);
      }
    },
    isDragging: (monitor) => {
      return !isItemLocked(product.productId, index) && monitor.isDragging();
    },
  });

  const [, drop] = useDrop({
    accept: "PRODUCT",
    hover(items: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current || !items || !items.draggedItem) {
        return;
      }

      const draggedItemIndex = newSequence.findIndex(
        (seq) => seq.productId === items.draggedItem.productId,
      );

      const draggedItemLocked = isItemLocked(
        items.draggedItem.productId,
        draggedItemIndex,
      );

      // Check if any items in the drag stack are locked
      const hasLockedItems = items.items.some((item) => {
        const itemIndex = newSequence.findIndex(
          (seq) => seq.productId === item.productId,
        );
        return isItemLocked(item.productId, itemIndex);
      });

      if (draggedItemLocked || hasLockedItems) {
        return;
      }

      const hoverIndex = index;
      const pointerOffset = monitor.getClientOffset();
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const midX =
        hoverBoundingRect!.left +
        (hoverBoundingRect!.right - hoverBoundingRect!.left) / 2;
      const newInsertIndex =
        pointerOffset!.x < midX ? hoverIndex : hoverIndex + 1;

      if (insertIndexRef.current) {
        insertIndexRef.current.insertIndex = newInsertIndex;
      }

      onMove(hoverIndex, newInsertIndex);
    },
    canDrop: (item: DragItem) => {
      const draggedItemIndex = newSequence.findIndex(
        (seq) => seq.productId === item.draggedItem.productId,
      );
      return !isItemLocked(item.draggedItem.productId, draggedItemIndex);
    },
  });

  drag(drop(ref));

  if (!product.productId) {
    return null;
  }

  return (
    <StyledProductTileWrapper
      style={style}
      isDragging={isDragging}
      isSelected={isSelected}
      isProductDetailRefreshing={isProductDetailRefreshing}
      isRefreshingEveryProduct={isRefreshingEveryProduct}
    >
      {shouldInsertLineOnLeft && (
        <StyledInsertLineLeft isBorder={columnIndex === 0} />
      )}
      {!isProductDetailAvailable ? (
        <Skeleton variant="rectangular" height={"100%"} />
      ) : (
        <ProductTile
          ref={ref}
          product={product}
          index={index}
          isSelected={isSelected}
          onSelectionChange={onSelectionChange}
          hasProductsSelectedFromList={hasProductsSelectedFromList}
          OnQuickMoveCompleteAction={OnQuickMoveCompleteAction}
          productView={true}
        />
      )}
      {shouldInsertLineOnRight && (
        <StyledInsertLineRight
          isBorder={columnIndex === viewContext.styles.column - 1}
        />
      )}
    </StyledProductTileWrapper>
  );
};

export default React.memo(ProductTileWrapper);
