import { SyntheticEvent, useCallback, useMemo, useRef, useEffect } from "react";
import {
  Grid,
  InputLabel,
  Typography,
  useTheme,
  CircularProgress,
} from "@mui/material";
import ButtonComponent from "components/common/ButtonComponent";
import CustomDialog from "components/common/DialogContainer";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import AppState from "store/AppState";
import { setModalState } from "store/modal/ModalActions";
import { selectIsModalOpen } from "store/modal/ModalSelectors";
import { styled } from "@mui/material/styles";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextfieldWrapper from "components/containers/Textfield/TextFieldWrapper";
import {
  createProductBadge,
  resetSelectedStore,
  updateProductBadge,
} from "store/product-badge/ProductBadgeActions";
import {
  selectProductBadgeDetails,
  selectSelectedStoreId,
} from "store/product-badge/ProductSelectors";
import { useState } from "react";
import { ProductBadgeData } from "store/product-badge/ProductBadgeTypes";
import { selectStoreListIds } from "store/store-list/StoreListSelectors";
import {
  selectAttributesListFetched,
  selectIsAttributesListLoaded,
} from "store/businessRules-list/BusinessRuleslistSelectors";
import SelectWrapper from "components/containers/Select/SelectWrapper";
import { AdapterInfoObject } from "utils/AppConfigUtils";
import { selectAdapterInfo } from "store/adapter-config/AdapterConfigSelectors";
import {
  fetchAttributesList,
  loadAttributesList,
  resetAttributesFlag,
  resetAttributesList,
  setSearchValue,
} from "store/businessRules-list/BusinessRulesListActions";
import { StyledSpan, styles } from "./BadgeManagementStyles";
import AutoComplete from "../../common/AutoComplete";
import { handleAttributeValues } from "utils/AttributeUtils";
import { handleMappedValues } from "utils/AttributeUtils";

const classes = {
  divActionBtn: "divActionBtn",
};

const StyledDiv = styled("div")((theme) => ({
  [`&.${classes.divActionBtn}`]: {
    width: "100%",
    textAlign: "end",
  },
}));

interface SelectType {
  label: string;
  value: string;
}

interface MappedValue {
  label: string | undefined;
  values: SelectType[] | undefined;
}

function BadgeFormModal({ isEditMode, badgeId }) {
  const intl = useIntl();
  const validationSchema = Yup.object({
    label: Yup.string().required(
      intl.formatMessage({
        id: "badgeForm.badgeRequired",
        defaultMessage: "Badge is required",
      }),
    ),
    attributeId: Yup.string().required(
      intl.formatMessage({
        id: "badgeForm.attributeRequired",
        defaultMessage: "Attribute is required",
      }),
    ),
    valueId: Yup.string().required(
      intl.formatMessage({
        id: "badgeForm.valueRequired",
        defaultMessage: "Value is required",
      }),
    ),
    storeId: Yup.string().required(
      intl.formatMessage({
        id: "badgeForm.storeRequired",
        defaultMessage: "Store is required",
      }),
    ),
    precedence: Yup.number()
      .required(
        intl.formatMessage({
          id: "badgeForm.precedenceRequired",
          defaultMessage: "Precedence is required",
        }),
      )
      .min(0),
  });

  const dispatch = useDispatch();
  const theme = useTheme();
  const useStyles = styles(theme);
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "BadgeFormModal"),
  );
  const productBadgeDetails = useSelector((state: AppState) =>
    selectProductBadgeDetails(state, badgeId),
  );
  const attributesList = useSelector(selectAttributesListFetched);
  const storesList = useSelector(selectStoreListIds);
  const selectedStore = useSelector(selectSelectedStoreId);
  const [mappedValues, setMappedValues] = useState<MappedValue[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const adapterInfo: AdapterInfoObject = useSelector(selectAdapterInfo);
  const isAttributesLoaded = useSelector(selectIsAttributesListLoaded);
  const isQueryAPIAvailable = adapterInfo?.operations?.includes(
    "POST /v1/{accountId}/product-attribute-definitions/query",
  ) as boolean;
  const initialFetchRef = useRef(false);

  const handleClose = () => {
    setInitialState(initialValues);
    dispatch(setModalState("BadgeFormModal", false));
    dispatch(resetAttributesList());
    dispatch(resetSelectedStore());
    setMappedValues([]);
    initialFetchRef.current = false;
    return;
  };

  const initialValues: ProductBadgeData = useMemo(() => {
    return {
      badgeId: "",
      label: "",
      attributeId: "",
      valueId: "",
      storeId: "",
      precedence: 0,
    };
  }, []);

  const [initialState, setInitialState] = useState(initialValues);

  const handleAttributesValues = useCallback(
    (value: string | null) => {
      handleAttributeValues(value, attributesList, setMappedValues);
    },
    [attributesList],
  );

  const handleMappedValuesForBadge = (attributeLabel: string) => {
    return handleMappedValues(attributeLabel, mappedValues, attributesList);
  };

  // Handle form submission
  const handleSubmit = (values) => {
    const selectedAttribute = attributesList.find(
      (attribute) => attribute.label === values.attributeId,
    );

    const badgeDetails: ProductBadgeData = {
      label: values.label,
      attributeId: selectedAttribute?.attributeId || values.attributeId,
      valueId: values.valueId,
      storeId: values.storeId ?? null,
      precedence: values.precedence,
    };
    if (!isEditMode) {
      dispatch(createProductBadge(badgeDetails));
    } else {
      badgeDetails.badgeId = values.badgeId;
      dispatch(updateProductBadge(badgeDetails));
    }
    handleClose();
  };

  const handleAttributes = useCallback(
    (value: string) => {
      if (
        !productBadgeDetails ||
        (productBadgeDetails && Object.keys(productBadgeDetails).length === 0)
      )
        dispatch(resetAttributesList());
      if (value !== "") {
        setIsLoading(true);
        dispatch(setSearchValue(value));
        dispatch(
          fetchAttributesList({
            startWithKeyword: value,
            storeId: productBadgeDetails?.storeId ?? selectedStore ?? undefined,
          }),
        );
      }
    },
    [dispatch, productBadgeDetails, selectedStore],
  );

  useEffect(() => {
    if (isAttributesLoaded) {
      setIsLoading(false);
      dispatch(resetAttributesFlag());
    }
  }, [isAttributesLoaded, dispatch]);

  useEffect(() => {
    if (
      !initialFetchRef.current &&
      isEditMode &&
      isQueryAPIAvailable &&
      productBadgeDetails?.attributeId &&
      isModalOpen &&
      attributesList.length === 0
    ) {
      handleAttributes(productBadgeDetails.attributeId);
      initialFetchRef.current = true;
    }
  }, [
    isModalOpen,
    attributesList.length,
    handleAttributes,
    isEditMode,
    isQueryAPIAvailable,
    productBadgeDetails?.attributeId,
  ]);

  useEffect(() => {
    if (!isEditMode) {
      setInitialState(initialValues);
      setMappedValues([]);
    }
  }, [isEditMode, initialValues]);

  useEffect(() => {
    if (isEditMode && productBadgeDetails && isModalOpen && badgeId !== "") {
      if (!isQueryAPIAvailable) {
        setInitialState(productBadgeDetails);
        if (attributesList.length === 0 && !initialFetchRef.current) {
          initialFetchRef.current = true;
          dispatch(
            loadAttributesList({
              storeId: productBadgeDetails.storeId,
            }),
          );
        } else {
          handleAttributesValues(productBadgeDetails.attributeId);
        }
      } else if (isQueryAPIAvailable && attributesList.length > 0) {
        handleAttributesValues(productBadgeDetails.attributeId);
        setInitialState(productBadgeDetails);
      }
    }
  }, [
    productBadgeDetails,
    dispatch,
    attributesList.length,
    isQueryAPIAvailable,
    handleAttributesValues,
    isModalOpen,
    badgeId,
    isEditMode,
  ]);

  const getBadgeFormModalTitle = () => {
    return (
      <Typography variant="inherit">
        {!isEditMode
          ? intl.formatMessage({
              id: "badgeForm.addTitle",
              defaultMessage: "Add Badge",
            })
          : intl.formatMessage({
              id: "badgeForm.updateTitle",
              defaultMessage: "Update Badge",
            })}
      </Typography>
    );
  };

  const getBadgeFormModalContent = () => {
    return (
      <div style={useStyles.formModalStyle}>
        {isEditMode &&
        attributesList.length === 0 &&
        mappedValues.length === 0 ? (
          <div style={useStyles.overlay}>
            <StyledSpan>
              <Typography
                variant="subHeader"
                sx={useStyles.fetchingBadgeDetails}
              >
                {intl.formatMessage({
                  id: "badgeFormModal.fetchMessage",
                  defaultMessage: " Fetching Badge Details...",
                })}
              </Typography>
            </StyledSpan>
            <CircularProgress />
          </div>
        ) : (
          <Formik
            enableReinitialize
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(formik) => (
              <Form style={{ ...useStyles.form }}>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  sx={useStyles.grid}
                >
                  <Grid item xs={12} sm={4}>
                    <InputLabel style={useStyles.label}>
                      {intl.formatMessage({
                        id: "badgeForm.badgeLabel",
                        defaultMessage: "Badge Label",
                      })}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextfieldWrapper
                      label={intl.formatMessage({
                        id: "badgeForm.badge",
                        defaultMessage: "Badge",
                      })}
                      type="string"
                      id="label"
                      name="label"
                      variant="outlined"
                      fullWidth
                      size={"small"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputLabel style={useStyles.label}>
                      {intl.formatMessage({
                        id: "badgeForm.store",
                        defaultMessage: "Store",
                      })}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <SelectWrapper
                      name="storeId"
                      label={intl.formatMessage({
                        id: "badgeForm.store",
                        defaultMessage: "Store",
                      })}
                      options={storesList.map((e) => ({
                        label: e.code,
                        value: e.storeId,
                      }))}
                      defaultValue={selectedStore ?? formik.values.storeId}
                      currentModalValue={""}
                      fullWidth
                      size="small"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputLabel id="attributeLabel" style={useStyles.label}>
                      {" "}
                      {intl.formatMessage({
                        id: "badgeForm.attribute",
                        defaultMessage: "Attribute",
                      })}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <AutoComplete
                      value={
                        attributesList?.find(
                          (attribute) =>
                            attribute.attributeId === formik.values.attributeId,
                        )?.label ??
                        formik.values.attributeId ??
                        ""
                      }
                      onChange={(_: SyntheticEvent, newValue) => {
                        formik.setFieldValue("attributeId", newValue);
                        handleAttributesValues(newValue);
                      }}
                      onInputChange={
                        isQueryAPIAvailable ? handleAttributes : undefined
                      }
                      options={attributesList?.map(
                        (attribute) => attribute.label,
                      )}
                      label={intl.formatMessage({
                        id: "badgeForm.attribute",
                        defaultMessage: "Attribute",
                      })}
                      name="attributeId"
                      loading={!isQueryAPIAvailable ? false : isLoading}
                      disabled={
                        !isQueryAPIAvailable
                          ? !attributesList.length
                          : selectedStore
                            ? false
                            : true
                      }
                      noOptionsText={
                        !isQueryAPIAvailable ? "" : "Type to search"
                      }
                      isAttributeForm={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputLabel style={useStyles.label}>
                      {" "}
                      {intl.formatMessage({
                        id: "badgeForm.value",
                        defaultMessage: "Value",
                      })}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <SelectWrapper
                      name="valueId"
                      label={intl.formatMessage({
                        id: "badgeForm.value",
                        defaultMessage: "Value",
                      })}
                      options={handleMappedValuesForBadge(
                        formik.values.attributeId,
                      )}
                      currentModalValue={""}
                      defaultValue={formik.values.valueId}
                      fullWidth
                      size="small"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputLabel style={useStyles.label}>
                      {" "}
                      {intl.formatMessage({
                        id: "badgeForm.precedence",
                        defaultMessage: "Precedence",
                      })}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextfieldWrapper
                      type="number"
                      id="precedence"
                      name="precedence"
                      variant="outlined"
                      sx={useStyles.precedence}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                      size={"small"}
                    />
                  </Grid>
                </Grid>
                <StyledDiv className={classes.divActionBtn}>
                  <ButtonComponent
                    color="secondaryButtonColorCTABlue"
                    variant="outlined"
                    onClick={handleClose}
                  >
                    {intl.formatMessage({
                      id: "badgeFormModal.cancelActionLabel",
                      defaultMessage: "Cancel",
                    })}
                  </ButtonComponent>
                  <ButtonComponent
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    {!isEditMode
                      ? intl.formatMessage({
                          id: "badgeFormModal.addActionLabel",
                          defaultMessage: "Add",
                        })
                      : intl.formatMessage({
                          id: "badgeFormModal.updateActionLabel",
                          defaultMessage: "Update",
                        })}
                  </ButtonComponent>
                </StyledDiv>
              </Form>
            )}
          </Formik>
        )}
      </div>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={handleClose}
      fullWidth
      title={getBadgeFormModalTitle()}
    >
      {getBadgeFormModalContent()}
    </CustomDialog>
  );
}

export default BadgeFormModal;
