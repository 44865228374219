import { Theme } from "@mui/material";
import { styled } from "@mui/material/styles";

const useStyles = (theme: Theme) => {
  return {
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: theme.palette.grey[200],
      marginBottom: "24px",
      padding: "0 0 0 24px",
    },
    dialogContent: {
      padding: "0 24px",
      textAlign: "center",
    },
    button: {
      margin: theme.spacing(1),
      width: "10rem",
    },
    typography: {
      margin: theme.spacing(1),
    },
    dialogAction: {
      width: "100%",
      textAlign: "end",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "0px 42px 0px 42px",
      border: `1px solid ${theme.palette.neutral.tableBorderColor}`,
    } as const,
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
    },
    noRecordsTablecell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    targetStyle: {
      padding: "10px 0px 10px 10px",
    },
    actionButtons: {
      paddingRight: "15px",
    },
    overlay: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: 99999,
      cursor: "pointer",
    },
    loaderContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#fff",
      padding: "15px",
      borderRadius: "4px",
    },
    loaderText: {
      marginRight: 20,
      fontSize: "20px",
    },
    icon: {
      color: theme.palette.neutral.secondaryDark,
      marginRight: "0px !important",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute" as const,
      top: 20,
      width: 1,
    },
    tableCell: {
      fontSize: 16,
      fontWeight: 400,
      padding: "10px",
      fontFamily: "Helvetica",
    },
    cancelButton: {
      marginRight: "8px",
    },
  };
};

export const modalClasses = {
  dialogChildClass: "dialogChildClass",
  divActionBtn: "divActionBtn",
  dialogBlurClass: "dialogBlurClass",
  divTable: "divTable",
};

export const StyledDiv = styled("div")((theme) => ({
  [`&.${modalClasses.dialogChildClass}`]: {
    position: "absolute",
    top: "50%",
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  [`&.${modalClasses.divActionBtn}`]: {
    width: "100%",
    textAlign: "end",
    margin: "0px 35px 20px 20px",
  },
  [`&.${modalClasses.divTable}`]: {
    minHeight: "60vh",
    minWidth: "47vw",
    padding: "24px 24px 0px",
  },
}));

export default useStyles;
