import { call, put, select, takeEvery, all } from "typed-redux-saga/macro";
import { callApi } from "../../utils/SagaUtils";
import {
  CREATE_PRODUCT_BADGE,
  CreateProductBadgeAction,
  DELETE_BADGE,
  DeleteBadgeAction,
  FETCH_PRODUCT_BADGES_LIST,
  GET_PRODUCT_BADGE,
  GetBadgeAction,
  LOAD_PRODUCT_BADGES_LIST,
  LoadProductBadgeListAction,
  LoadQueryBadgeAction,
  QUERY_PRODUCT_BADGE,
  UPDATE_BADGE_PRECEDENCE,
  UPDATE_PRODUCT_BADGE,
  UpdateBadgePrecedenceAction,
  UpdateProductBadgeAction,
  QUERY_PRODUCT_BADGE_URL,
  UPDATE_PRODUCT_BADGE_ASSIGNMENTS,
  UpdateProductBadgeAssignmentsAction,
  badges,
  BADGE_CONFIGURE_MESSAGE,
} from "./ProductBadgeTypes";
import {
  deleteData,
  getData,
  patchData,
  postData,
  putData,
} from "../../services/ApiService";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../store-list/StoreListSelectors";
import { selectCurrentCatalogId } from "store/catalog/CatalogSelectors";
import { addGlobalAlertState } from "store/global-alert/GlobalAlertActions";
import { setProductBadgeAssignments } from "./ProductBadgeActions";

function* loadProductBadgeList(action: LoadProductBadgeListAction) {
  const actionType = FETCH_PRODUCT_BADGES_LIST;
  const constName = Object.keys({ FETCH_PRODUCT_BADGES_LIST })[0].toString();

  try {
    const endpoint = acquireEndpoint(constName);
    yield* call(callApi, actionType, getData, null, endpoint);
  } catch (e: any) {
    console.error(e);
    yield* put({ type: FETCH_PRODUCT_BADGES_LIST.FAILURE, message: e.message });
  }
}

export function* fetchProductQueryProduct(action: LoadQueryBadgeAction) {
  try {
    const actionType = QUERY_PRODUCT_BADGE;
    const storeId = yield* select(selectCurrentStoreId);
    const catalogId = yield* select(selectCurrentCatalogId);
    const localeCode = yield* select(selectCurrentLocale);
    const constName = Object.keys({ QUERY_PRODUCT_BADGE_URL })[0].toString();
    const endpoint = acquireEndpoint(constName);
    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };

    yield* call(
      callApi,
      actionType,
      postData,
      action.payload,
      endpoint,
      headersObj,
    );
  } catch (e: any) {
    console.error(e);
    yield* put({ type: QUERY_PRODUCT_BADGE.FAILURE, message: e.message });
  }
}

function* updateBadgePrecedence(action: UpdateBadgePrecedenceAction) {
  try {
    const actionType = UPDATE_BADGE_PRECEDENCE;
    const storeId =
      action.payload.storeId ?? (yield* select(selectCurrentStoreId));
    const constName = Object.keys({
      UPDATE_BADGE_PRECEDENCE,
    })[0].toString();
    const badgeId = action.payload.badgeId;
    const endpoint = acquireEndpoint(constName, badgeId);
    const headersObj = {
      "x-store-id": storeId,
    };

    yield* call(
      callApi,
      actionType,
      patchData,
      action.payload,
      endpoint,
      headersObj,
    );
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: UPDATE_BADGE_PRECEDENCE.FAILURE,
      message: e.message,
    });
  }
}

function* deleteBadge(action: DeleteBadgeAction) {
  try {
    const actionType = DELETE_BADGE;
    const constName = Object.keys({ DELETE_BADGE })[0].toString();
    const { badgeId } = action.payload;
    const storeId =
      action.payload.storeId ?? (yield* select(selectCurrentStoreId));
    const endpoint = acquireEndpoint(constName, badgeId);
    const headersObj = {
      "x-store-id": storeId,
    };

    const result = yield* call(
      callApi,
      actionType,
      deleteData,
      null,
      endpoint,
      headersObj,
    );
    if (result && result.type === actionType.SUCCESS) {
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "productBadgeOperations.badgeDeleteSuccess",
                defaultMessage: "Badge deleted successfully",
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: DELETE_BADGE.FAILURE,
      message: e.message,
    });
  }
}

function* getProductBadge(action: GetBadgeAction) {
  try {
    const actionType = GET_PRODUCT_BADGE;
    const constName = Object.keys({ GET_PRODUCT_BADGE })[0].toString();
    const { badgeId } = action.payload;
    const storeId =
      action.payload.storeId ?? (yield* select(selectCurrentStoreId));
    const endpoint = acquireEndpoint(constName, badgeId);
    const headersObj = {
      "x-store-id": storeId,
    };

    yield* call(callApi, actionType, getData, null, endpoint, headersObj);
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: GET_PRODUCT_BADGE.FAILURE,
      message: e.message,
    });
  }
}

function* createProductBadge(action: CreateProductBadgeAction) {
  try {
    const actionType = CREATE_PRODUCT_BADGE;
    const constName = Object.keys({ CREATE_PRODUCT_BADGE })[0].toString();
    const storeId =
      action.payload.storeId ?? (yield* select(selectCurrentStoreId));
    const endpoint = acquireEndpoint(constName);
    const headersObj = {
      "x-store-id": storeId,
    };

    const result = yield* call(
      callApi,
      actionType,
      postData,
      action.payload,
      endpoint,
      headersObj,
    );
    if (result && result.type === actionType.SUCCESS) {
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "productBadgeOperations.badgeCreateSuccess",
                defaultMessage: "Badge Created successfully",
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: CREATE_PRODUCT_BADGE.FAILURE,
      message: e.message,
    });
  }
}

function* updateProductBadge(action: UpdateProductBadgeAction) {
  try {
    const actionType = UPDATE_PRODUCT_BADGE;
    const constName = Object.keys({ UPDATE_PRODUCT_BADGE })[0].toString();
    const storeId =
      action.payload.storeId ?? (yield* select(selectCurrentStoreId));
    const { badgeId } = action.payload;
    const endpoint = acquireEndpoint(constName, badgeId);
    const headersObj = {
      "x-store-id": storeId,
    };

    const result = yield* call(
      callApi,
      actionType,
      patchData,
      action.payload,
      endpoint,
      headersObj,
    );
    if (result && result.type === actionType.SUCCESS) {
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "productBadgeOperations.badgeUpdateSuccess",
                defaultMessage: "Badge updated successfully",
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: UPDATE_PRODUCT_BADGE.FAILURE,
      message: e.message,
    });
  }
}

function* updateProductBadgeAssignments(
  action: UpdateProductBadgeAssignmentsAction,
) {
  try {
    const actionType = UPDATE_PRODUCT_BADGE_ASSIGNMENTS;
    const constName = Object.keys({
      UPDATE_PRODUCT_BADGE_ASSIGNMENTS,
    })[0].toString();
    const storeId = yield* select(selectCurrentStoreId);
    const localeCode = yield* select(selectCurrentLocale);
    const { productId, badgeIds, assigned } = action.payload;

    // Create an array of API calls for each badge ID
    const apiCalls = badgeIds.map((badgeId) => {
      const endpoint = acquireEndpoint(constName, productId, badgeId);
      const headersObj = {
        "x-store-id": storeId,
        "x-locale-code": localeCode,
      };

      return call(
        callApi,
        actionType,
        putData,
        { assigned },
        endpoint,
        headersObj,
      );
    });

    // Execute all API calls in parallel and store results
    const results = yield* all(apiCalls);

    // Filter successful results and create payload
    const successfulResults = results.filter(
      (result) => result?.type === actionType.SUCCESS,
    );
    if (successfulResults.length > 0) {
      const badges: badges[] = [];
      successfulResults.forEach((result) => {
        result.payload.badges?.forEach((badge: badges) => {
          badges.push(badge);
        });
      });
      yield* put(
        setProductBadgeAssignments({
          productId,
          badges: badges,
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: UPDATE_PRODUCT_BADGE_ASSIGNMENTS.FAILURE,
      message: e.message,
    });
  }
}

function* badgeConfigureMessage() {
  yield* put(
    addGlobalAlertState({
      alertsProps: [
        {
          descriptor: {
            id: "productBadgeOperations.badgeConfigure",
            defaultMessage:
              "Please select Badge Management from the Settings menu to configure badges",
          },
          severity: "warning",
          variant: "standard",
        },
      ],
    }),
  );
}

export function* watchLoadQueryProduct() {
  yield* takeEvery(QUERY_PRODUCT_BADGE.REQUEST, fetchProductQueryProduct);
}

export function* watchLoadProductBadgeList() {
  yield* takeEvery(LOAD_PRODUCT_BADGES_LIST, loadProductBadgeList);
}

export function* watchUpdateBadgePrecedence() {
  yield* takeEvery(UPDATE_BADGE_PRECEDENCE.REQUEST, updateBadgePrecedence);
}

export function* watchDeleteBadge() {
  yield* takeEvery(DELETE_BADGE.REQUEST, deleteBadge);
}

export function* watchGetProductBadge() {
  yield* takeEvery(GET_PRODUCT_BADGE.REQUEST, getProductBadge);
}

export function* watchCreateProductBadge() {
  yield* takeEvery(CREATE_PRODUCT_BADGE.REQUEST, createProductBadge);
}

export function* watchUpdateProductBadge() {
  yield* takeEvery(UPDATE_PRODUCT_BADGE.REQUEST, updateProductBadge);
}

export function* watchUpdateProductBadgeAssignments() {
  yield* takeEvery(
    UPDATE_PRODUCT_BADGE_ASSIGNMENTS.REQUEST,
    updateProductBadgeAssignments,
  );
}

export function* watchBadgeConfigureMessage() {
  yield* takeEvery(BADGE_CONFIGURE_MESSAGE, badgeConfigureMessage);
}
