import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Typography, useTheme } from "@mui/material";
import AppState from "../../store/AppState";
import {
  selectIsOverlaySelectedByType,
  selectOverlay,
} from "../../store/overlay/OverlaySelectors";
import { OVERLAY_TABS } from "../../utils/Constants";
import {
  selectProductBadgeAssignmentsList,
  selectProductBadgeAssignmentsLoading,
  selectProductBadgeList,
} from "store/product-badge/ProductSelectors";
import { selectCurrentStoreId } from "store/store-list/StoreListSelectors";
import { updateProductBadgeAssignments } from "store/product-badge/ProductBadgeActions";
import CheckboxComponent from "components/common/Checkbox";
import { useStyles } from "./ProductBadgesOverlayStyles";

interface Props {
  productId: string;
}

const ProductBadgeOverlay: React.FC<Props> = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { productId } = props;
  const dispatch = useDispatch();
  const currentStoreId = useSelector(selectCurrentStoreId);
  const badgeAssignmentsList = useSelector((state: AppState) =>
    selectProductBadgeAssignmentsList(state, productId),
  );
  const badgesList = useSelector(selectProductBadgeList);
  const badgeAssignmentsUpdateLoading = useSelector(
    selectProductBadgeAssignmentsLoading,
  );
  const [updatingBadge, setUpdatingBadge] = useState<{
    badgeId: string;
    productId: string;
  } | null>(null);
  const filteredBadgesList =
    badgesList?.filter(
      (badge) => badge.storeId === currentStoreId || badge.storeId === null,
    ) ?? [];
  const isBadgeOverlay = useSelector((state: AppState) =>
    selectIsOverlaySelectedByType(state, OVERLAY_TABS.PRODUCTS_BADGES),
  );
  const selectedOverlay = useSelector(selectOverlay);

  useEffect(() => {
    if (!badgeAssignmentsUpdateLoading) {
      setUpdatingBadge(null);
    }
  }, [badgeAssignmentsUpdateLoading]);

  const handleCheckboxChange = (badgeId: string | undefined) => {
    if (!badgeId) return;
    setUpdatingBadge({ badgeId, productId });
    const isCurrentlyAssigned = badgeAssignmentsList?.some(
      (assignment) => assignment.badgeId === badgeId,
    );

    if (isCurrentlyAssigned) {
      const removeBadgePayload = {
        assigned: false,
        productId,
        badgeIds: [badgeId],
      };
      dispatch(updateProductBadgeAssignments(removeBadgePayload));
    } else {
      if (badgeAssignmentsList?.length > 0) {
        const existingBadgeIds = badgeAssignmentsList.map(
          (assignment) => assignment.badgeId,
        );
        const removeBadgePayload = {
          assigned: false,
          productId,
          badgeIds: existingBadgeIds,
        };
        dispatch(updateProductBadgeAssignments(removeBadgePayload));
      }
      if (
        badgeAssignmentsList.length === 0 ||
        badgeAssignmentsList?.find(
          (assignment) => assignment.badgeId !== badgeId,
        )
      ) {
        // Then add the newly selected badge
        const addBadgePayload = {
          assigned: true,
          productId,
          badgeIds: [badgeId],
        };
        dispatch(updateProductBadgeAssignments(addBadgePayload));
      }
    }
  };

  return (
    <>
      <div style={classes.overlay}>
        <ul style={classes.listParent}>
          {isBadgeOverlay &&
            selectedOverlay === OVERLAY_TABS.PRODUCTS_BADGES && (
              <>
                {filteredBadgesList.length === 0 ? (
                  <li key={`${productId}`} style={classes.listItem}>
                    <Typography variant="badges"></Typography>
                  </li>
                ) : (
                  <>
                    {badgeAssignmentsUpdateLoading &&
                      updatingBadge &&
                      updatingBadge.productId === productId && (
                        <div style={classes.loading}>
                          <CircularProgress size={30} />
                        </div>
                      )}
                    {filteredBadgesList.map((badge) => (
                      <li
                        key={`${productId}-${badge.badgeId}`}
                        style={classes.listItem}
                      >
                        <div style={classes.listItemContainer}>
                          <CheckboxComponent
                            checkboxId="ProductBadgesOverlay"
                            checked={badgeAssignmentsList?.some(
                              (assignment) =>
                                assignment.badgeId === badge.badgeId,
                            )}
                            onChange={() => handleCheckboxChange(badge.badgeId)}
                            sx={classes.checkBox}
                          />
                          <Typography variant="badges">
                            {badge.label}
                          </Typography>
                        </div>
                      </li>
                    ))}
                  </>
                )}
              </>
            )}
        </ul>
      </div>
    </>
  );
};

export default React.memo(ProductBadgeOverlay);
