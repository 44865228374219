import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { CircularProgress, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AppState from "../../store/AppState";
import { selectProductFetched } from "../../store/product/ProductSelectors";
import {
  selectColorList,
  selectLeadingColorForProduct,
  selectisSequenceUpdated,
  selectSequenceEdited,
  selectLeadingColorImageForProductInCategory,
} from "../../store/color-management/ColorManagementSelectors";
import {
  selectIsModalOpen,
  selectModalVariable,
} from "../../store/modal/ModalSelectors";
import {
  selectCurrencyCode,
  selectCurrentStoreId,
} from "../../store/store-list/StoreListSelectors";
import {
  getColorManagementProductList,
  updateProductColorSequence,
  toggleSequenceEdited,
} from "../../store/color-management/ColorManagementActions";
import { setModalState } from "../../store/modal/ModalActions";
import {
  componentClasses,
  StyledBox,
  StyledDiv,
  StyledTypography,
} from "./ColorManagementModalStyled";
import ColorManagementProductItem from "./ColorManagementProductItem";
import { selectOverlay } from "../../store/overlay/OverlaySelectors";
import ConfirmationColorManagement from "./ConfirmationColorManagement";
import ColorItemDragLayer from "./ColorItemDragLayer";
import { selectCurrentCategory } from "../../store/category/CategorySelectors";
import { CURRENCY_MAP, VARIATION_GROUP } from "../../utils/Constants";
import CustomDialog from "../common/DialogContainer";
import ButtonComponent from "../../components/common/ButtonComponent";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { getPrice, getProductModalTitle } from "utils/ProductUtil";
export const ColorManagementModalId = "ColorManagementModal";

interface Props {
  categoryId: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    rightSection: {
      position: "sticky",
      top: 0, // Keeps the div fixed at the top within its parent container
      marginLeft: "auto", // Aligns to the right in a flex layout
      minWidth: "150px",
      width: "auto",
      height: "74vh", // Define height relative to the viewport
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        minWidth: "200px",
      },
      // Add these properties if `rightSection` should not scroll vertically
      overflowY: "auto", // Prevents vertical overflow and keeps content in view
      right: 0, // Sticks to the right side of the parent container
    },
    divActionBtn: {
      textAlign: "end",
    },
    divNewActionBtn: { display: "flex" },
  }),
);
const ColorManagement: React.FC<Props> = (props) => {
  // ======== const ======== //
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyle();
  const selectedOverlay = useSelector((state: AppState) =>
    selectOverlay(state),
  );

  // =========== State ==========

  const [insertIndex, setInsertIndex] = useState(-1);
  const [showNotPublishedText, setshowNotPublishedText] = useState(false);

  // ========== Selectors ==========
  const productId = useSelector((state: AppState) =>
    selectModalVariable(state, ColorManagementModalId, "productId"),
  );
  const fetchedProduct = useSelector((state: AppState) =>
    selectProductFetched(state, productId),
  );
  const colorData = useSelector((state: AppState) => selectColorList(state));
  const storeId = useSelector(selectCurrentStoreId);
  const currentCategoryId = useSelector(selectCurrentCategory);
  const currencyCode = useSelector(selectCurrencyCode);
  let currencySymbol = "$";
  if (CURRENCY_MAP[currencyCode]) {
    currencySymbol = CURRENCY_MAP[currencyCode];
  }
  const [hideSetUnsetColorText, setHideSetUnsetColorText] =
    useState<boolean>(false);
  const lowPrice = fetchedProduct?.lowPrice;
  const highPrice = fetchedProduct?.highPrice;
  const baseProductPrice = getPrice(lowPrice, highPrice, currencySymbol);
  const colorManagementBaseProduct = useMemo(() => {
    if (fetchedProduct?.productId) {
      return {
        code: fetchedProduct.code,
        name: fetchedProduct.name,
        thumbnail: fetchedProduct.thumbnail,
        isPublished: fetchedProduct.isPublished,
        storeSpecific:
          fetchedProduct?.storeSpecific &&
          Object.entries(fetchedProduct.storeSpecific).map((e) => {
            return { storeId: e[0], isPublished: e[1].isPublished };
          }),
        translations: fetchedProduct?.translations,
        stock: fetchedProduct.stock,
        baseProductId:
          fetchedProduct?.typeCode === VARIATION_GROUP
            ? fetchedProduct.productId
            : fetchedProduct.variationBaseId,
        colors: fetchedProduct.colors,
        categorySpecific: fetchedProduct.categorySpecific,
        hasSequenceableColors: fetchedProduct.hasSequenceableColors,
      };
    }
  }, [fetchedProduct]);

  const updatedBaseProductLeadingColor: string | null =
    useSelector((state: AppState) =>
      selectLeadingColorForProduct(
        state,
        colorManagementBaseProduct?.baseProductId ?? "",
        currentCategoryId,
      ),
    ) ?? null;
  const updatedBaseProductLeadingColorImage: string | null =
    useSelector((state: AppState) =>
      selectLeadingColorImageForProductInCategory(
        state,
        colorManagementBaseProduct?.baseProductId ?? "",
        currentCategoryId,
      ),
    ) ?? null;
  const isSequenceUpdated: boolean =
    useSelector((state: AppState) => selectisSequenceUpdated(state)) ?? true;
  const sequenceEdited: boolean =
    useSelector((state: AppState) => selectSequenceEdited(state)) ?? false;

  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, ColorManagementModalId),
  );

  // ========= Functions ==============
  const isSavable = () => {
    if (
      colorManagementBaseProduct?.colors?.length &&
      colorData?.length &&
      colorManagementBaseProduct?.colors?.length === colorData?.length &&
      colorManagementBaseProduct.colors.every(
        (value, index) => value.colorId === colorData[index].colorId,
      )
    ) {
      return false;
    } else if (!fetchedProduct) {
      return false;
    } else {
      return sequenceEdited;
    }
  };

  const handleClose = () => {
    dispatch(setModalState(ColorManagementModalId, false));
  };

  const handleSave = (colorIds: string[]) => {
    dispatch(
      updateProductColorSequence({
        productId: fetchedProduct?.productId ?? "",
        colorIds: colorIds,
      }),
    );
  };

  // Mock Confirmation Action Box on Close Icon
  const handleCloseIcon = () => {
    if (!isSavable()) {
      dispatch(setModalState(ColorManagementModalId, false));
    } else {
      dispatch(setModalState("ConfirmationColorManagement", true));
    }
  };

  useEffect(() => {
    if (
      colorData.length === 0 &&
      colorManagementBaseProduct &&
      colorManagementBaseProduct?.code
    ) {
      const productList = colorManagementBaseProduct.colors?.map((e) => {
        return { ...e, isUpdatingPublishFlag: false };
      });
      dispatch(
        getColorManagementProductList({ productList: productList ?? [] }),
      );
    }
  }, [dispatch, colorData, colorManagementBaseProduct]);

  const onItemDragStart = useCallback((dragColor) => {
    setHideSetUnsetColorText(true);
  }, []);

  const onMove = useCallback(
    (color, currentItemColorId, dropItemColorId, pointerOffset) => {
      setInsertIndex(dropItemColorId);
    },
    [],
  );

  const generateButtons = (param) => {
    const isDisabled = !colorData || colorData.length === 0;
    switch (param) {
      case "close":
      default:
        return (
          <div className={classes.divActionBtn}>
            <div></div>
            <ButtonComponent
              color="primary"
              variant="contained"
              disabled={isDisabled}
              onClick={() => handleClose()}
            >
              {intl.formatMessage({
                id: "general.close",
                defaultMessage: "Close",
              })}
            </ButtonComponent>
          </div>
        );
      case "edit":
        return (
          <div className={classes.divNewActionBtn}>
            <ButtonComponent
              color="secondaryButtonColorCTABlue"
              variant="outlined"
              disabled={isDisabled}
              onClick={() => handleCloseIcon()}
            >
              {intl.formatMessage({
                id: "general.cancel",
                defaultMessage: "Cancel",
              })}
            </ButtonComponent>
            <ButtonComponent
              color="primary"
              variant="contained"
              justifyContent="center"
              disabled={isDisabled}
              onClick={() => handleSave(colorData.map((e) => e.colorId))}
            >
              {intl.formatMessage({
                id: "general.save",
                defaultMessage: "Save",
              })}
            </ButtonComponent>
          </div>
        );
    }
  };

  const onDragComplete = useCallback(
    (data) => {
      if (
        fetchedProduct?.colors &&
        data.product &&
        data.index !== insertIndex &&
        insertIndex !== -1
      ) {
        let copiedColors = [...colorData];
        copiedColors[insertIndex] = colorData[data.index];
        copiedColors[data.index] = colorData[insertIndex];
        dispatch(getColorManagementProductList({ productList: copiedColors }));
        dispatch(toggleSequenceEdited());
      }
      setInsertIndex(-1);
      setHideSetUnsetColorText(false);
    },
    [dispatch, colorData, insertIndex, fetchedProduct],
  );

  const categoryLeadingColor = useMemo(() => {
    if (
      !updatedBaseProductLeadingColor &&
      updatedBaseProductLeadingColorImage !== "" &&
      fetchedProduct?.categorySpecific
    ) {
      const categorySpecificColorData =
        fetchedProduct?.categorySpecific?.filter(
          (leadingColor) =>
            leadingColor.categoryId === currentCategoryId &&
            leadingColor.leadingColorId,
        );
      return categorySpecificColorData.length > 0
        ? categorySpecificColorData[0].leadingColorId
        : "";
    } else {
      return updatedBaseProductLeadingColor;
    }
  }, [
    updatedBaseProductLeadingColor,
    currentCategoryId,
    fetchedProduct?.categorySpecific,
    updatedBaseProductLeadingColorImage,
  ]);

  const numberOfPublishedColors = useMemo(() => {
    return colorData && colorData.length
      ? colorData.filter(({ isPublished }) => isPublished).length
      : 0;
  }, [colorData]);

  const currentProductImage = useMemo(() => {
    if (colorData && colorData.length && numberOfPublishedColors > 0) {
      if (categoryLeadingColor) {
        const categoryLeadingImageData = colorData.find(
          (color) => color.code === categoryLeadingColor,
        );
        if (categoryLeadingImageData?.isPublished) {
          return categoryLeadingImageData.thumbnail;
        }
      }
      const nextDefaultProductData = colorData.find(
        (color) => color.isPublished,
      );
      return nextDefaultProductData?.thumbnail || "";
    } else {
      return updatedBaseProductLeadingColorImage;
    }
  }, [
    categoryLeadingColor,
    colorData,
    numberOfPublishedColors,
    updatedBaseProductLeadingColorImage,
  ]);

  const baseProductImage = !currentProductImage
    ? colorManagementBaseProduct?.thumbnail
    : currentProductImage;

  const productModalTitle = getProductModalTitle(fetchedProduct);
  const colorMgmtModalTitle = () => {
    return (
      <Typography variant="inherit">
        Color Mgmt - {productModalTitle}
      </Typography>
    );
  };

  const colorMgmtModalContent = () => {
    return (
      <>
        <ColorItemDragLayer />
        <StyledDiv className={componentClasses.paper}>
          <StyledDiv className={componentClasses.leftSection}>
            <ColorManagementProductItem
              product={colorManagementBaseProduct}
              code={colorManagementBaseProduct?.code}
              selectedOverlay={selectedOverlay}
              isColumn={false}
              baseProductId={colorManagementBaseProduct?.baseProductId}
              storeId={storeId ?? ""}
              updatedBaseProductLeadingColorImage={baseProductImage}
              baseProductPrice={baseProductPrice}
            />
          </StyledDiv>
          <StyledDiv>
            <StyledTypography
              className={componentClasses.nonPublishedText}
              showNotPublishedText={showNotPublishedText}
              variant="inherit"
            >
              {intl.formatMessage({
                id: "colorManagementModal.usePublishedColor",
                defaultMessage:
                  "Please use a color that is already published or publish the color first.",
              })}
            </StyledTypography>
            <StyledDiv className={componentClasses.mainContent}>
              <StyledBox className={componentClasses.mainSection}>
                {isSequenceUpdated ? (
                  fetchedProduct?.colors?.length &&
                  colorData?.map((product, productIndex) => {
                    const price = getPrice(
                      product.lowPrice,
                      product.highPrice,
                      currencySymbol,
                    );
                    return (
                      <ColorManagementProductItem
                        product={product}
                        code={product.code}
                        price={price !== "" ? price : baseProductPrice}
                        isColumn={true}
                        selectedOverlay={selectedOverlay}
                        index={productIndex}
                        key={productIndex.toString()}
                        onItemDragStart={onItemDragStart}
                        onMove={onMove}
                        onDragComplete={onDragComplete}
                        baseProductId={
                          colorManagementBaseProduct?.baseProductId
                        }
                        hasSequenceableColors={
                          colorManagementBaseProduct?.hasSequenceableColors
                        }
                        storeId={storeId ?? ""}
                        leadingColor={categoryLeadingColor}
                        isPublished={product?.isPublished ?? false}
                        hideSetUnsetColorText={hideSetUnsetColorText}
                        setshowNotPublishedText={setshowNotPublishedText}
                        showNotPublishedText={showNotPublishedText}
                      />
                    );
                  })
                ) : (
                  <CircularProgress style={{ margin: "auto" }} size="40px" />
                )}
              </StyledBox>
            </StyledDiv>
          </StyledDiv>
          <div className={classes.rightSection}>
            {isSavable() ? generateButtons("edit") : generateButtons("close")}
          </div>
        </StyledDiv>
        <ConfirmationColorManagement handleClose={handleCloseIcon} />
      </>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => handleCloseIcon()}
      title={colorMgmtModalTitle()}
      fullWidth
      maxWidth={"lg"}
      onClick={(e) => e.preventDefault()}
    >
      {colorMgmtModalContent()}
    </CustomDialog>
  );
};

export default React.memo(ColorManagement);
