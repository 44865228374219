export const LOAD_PRODUCT_BADGES_LIST = "LOAD_PRODUCT_BADGES_LIST";

export const FETCH_PRODUCT_BADGES_LIST = {
  REQUEST: "FETCH_PRODUCT_BADGES_LIST_REQUEST",
  SUCCESS: "FETCH_PRODUCT_BADGES_LIST_SUCCESS",
  FAILURE: "FETCH_PRODUCT_BADGES_LIST_FAILURE",
};

export const QUERY_PRODUCT_BADGE = {
  REQUEST: "QUERY_PRODUCT_BADGE_REQUEST",
  SUCCESS: "QUERY_PRODUCT_BADGE_SUCCESS",
  FAILURE: "QUERY_PRODUCT_BADGE_FAILURE",
};
export const LOAD_QUERY_PRODUCT_BADGE = "LOAD_QUERY_PRODUCT_BADGE";

export const QUERY_PRODUCT_BADGE_URL = "QUERY_PRODUCT_BADGE_URL";

export const FETCH_PRODUCT_BADGES_LIST_SUCCESS =
  "FETCH_PRODUCT_BADGES_LIST_SUCCESS";

export const UPDATE_BADGE_PRECEDENCE = {
  REQUEST: "UPDATE_BADGE_PRECEDENCE_REQUEST",
  SUCCESS: "UPDATE_BADGE_PRECEDENCE_SUCCESS",
  FAILURE: "UPDATE_BADGE_PRECEDENCE_FAILURE",
};

export const DELETE_BADGE = {
  REQUEST: "DELETE_BADGE_REQUEST",
  SUCCESS: "DELETE_BADGE_SUCCESS",
  FAILURE: "DELETE_BADGE_FAILURE",
};

export const GET_PRODUCT_BADGE = {
  REQUEST: "GET_PRODUCT_BADGE_REQUEST",
  SUCCESS: "GET_PRODUCT_BADGE_SUCCESS",
  FAILURE: "GET_PRODUCT_BADGE_FAILURE",
};

export const CREATE_PRODUCT_BADGE = {
  REQUEST: "CREATE_PRODUCT_BADGE_REQUEST",
  SUCCESS: "CREATE_PRODUCT_BADGE_SUCCESS",
  FAILURE: "CREATE_PRODUCT_BADGE_FAILURE",
};

export const UPDATE_PRODUCT_BADGE = {
  REQUEST: "UPDATE_PRODUCT_BADGE_REQUEST",
  SUCCESS: "UPDATE_PRODUCT_BADGE_SUCCESS",
  FAILURE: "UPDATE_PRODUCT_BADGE_FAILURE",
};

export const RESET_PRODUCT_BADGE_STATE = "RESET_PRODUCT_BADGE_STATE";

export const SET_SELECTED_STORE = "SET_SELECTED_STORE";

export const RESET_SELECTED_STORE = "RESET_SELECTED_STORE";

export const UPDATE_PRODUCT_BADGE_ASSIGNMENTS = {
  REQUEST: "UPDATE_PRODUCT_BADGE_ASSIGNMENTS_REQUEST",
  SUCCESS: "UPDATE_PRODUCT_BADGE_ASSIGNMENTS_SUCCESS",
  FAILURE: "UPDATE_PRODUCT_BADGE_ASSIGNMENTS_FAILURE",
};

export const SET_PRODUCT_BADGE_ASSIGNMENTS = "SET_PRODUCT_BADGE_ASSIGNMENTS";

export const BADGE_CONFIGURE_MESSAGE = "BADGE_CONFIGURE_MESSAGE";

export interface LoadProductBadgePayload {
  productIds?: string[];
}

export interface LoadQueryProductBadgeAction {
  payload: {
    productBadgeAssignments: ProductBadgeAssignments[];
  };
  type: typeof QUERY_PRODUCT_BADGE.SUCCESS;
}
export interface QueryProductBadgeAction {
  payload: ProductQueryBadges;
  type: typeof QUERY_PRODUCT_BADGE;
}
export interface LoadQueryBadgeAction {
  payload: LoadProductBadgePayload;
  type: typeof QUERY_PRODUCT_BADGE.REQUEST;
}

export interface ProductQueryBadges {
  [x: string]: any;
  productIds: string;
  badges: string[];
}

export interface ProductBadge {
  badgeId: string;
  label: string;
  attributeId: string;
  valueId: string;
  storeId: string;
  precedence: string;
}
export interface LoadProductBadgeListAction {
  type: typeof LOAD_PRODUCT_BADGES_LIST;
}

export interface UpdateBadgePrecedenceAction {
  type: typeof UPDATE_BADGE_PRECEDENCE.REQUEST;
  payload: ProductBadgeData;
}

export interface DeleteBadgeAction {
  type: typeof DELETE_BADGE.REQUEST;
  payload: {
    badgeId: string;
    storeId: string;
  };
}

export interface GetBadgeAction {
  type: typeof GET_PRODUCT_BADGE.REQUEST;
  payload: {
    badgeId: string;
    storeId: string;
  };
}

export interface LoadBadgeAction {
  type: typeof GET_PRODUCT_BADGE.SUCCESS;
  payload: ProductBadgeData;
}

export interface CreateProductBadgeAction {
  type: typeof CREATE_PRODUCT_BADGE.REQUEST;
  payload: ProductBadgeData;
}

export interface UpdateProductBadgeAction {
  type: typeof UPDATE_PRODUCT_BADGE.REQUEST;
  payload: ProductBadgeData;
}

export interface SetSelectedStoreAction {
  type: typeof SET_SELECTED_STORE;
  storeId: string;
}

export interface UpdateProductBadgeAssignmentsAction {
  type: typeof UPDATE_PRODUCT_BADGE_ASSIGNMENTS.REQUEST;
  payload: UpdateProductBadgeAssignmentsPayload;
}

export interface UpdateProductBadgeAssignmentsPayload {
  assigned: boolean;
  productId: string;
  badgeIds: string[];
}

export interface UpdateProductBadgeAssignmentsSuccessAction {
  type: typeof UPDATE_PRODUCT_BADGE_ASSIGNMENTS.SUCCESS;
  payload: ProductBadgeAssignments;
}

export interface SetProductBadgeAssignmentsAction {
  type: typeof SET_PRODUCT_BADGE_ASSIGNMENTS;
  payload: ProductBadgeAssignments;
}

export interface badges {
  badgeId: string;
}

export type ProductBadgeActions =
  | LoadQueryProductBadgeAction
  | QueryProductBadgeAction
  | LoadQueryBadgeAction
  | LoadProductBadgeListAction
  | UpdateBadgePrecedenceAction
  | DeleteBadgeAction
  | GetBadgeAction
  | LoadBadgeAction
  | CreateProductBadgeAction
  | UpdateProductBadgeAction
  | SetSelectedStoreAction
  | UpdateProductBadgeAssignmentsAction
  | UpdateProductBadgeAssignmentsSuccessAction;

export interface LoadProductBadgesResponseAction {
  payload: GetProductBadgesListResponsePayload;
  type: typeof FETCH_PRODUCT_BADGES_LIST_SUCCESS;
}

export interface GetProductBadgesListResponsePayload {
  badges: ProductBadgeData[];
}

export interface ProductBadgeData {
  badgeId?: string;
  label: string;
  attributeId: string;
  valueId: string;
  storeId: string | null;
  precedence: number;
}

export interface ProductBadgeAssignments {
  productId: string;
  badges: badges[];
}
