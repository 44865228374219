import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Zoom } from "@material-ui/core";

import {
  shouldUpdateEditSequenceList,
  selectEditSequenceList,
  shouldUpdateNewPinnedProducts,
  selectNumberOfPinnedProducts,
  getProductSequenceRequestId,
  selectIsUpdateProductSequenceInvoked,
  selectSavedLockedProducts,
  selectSavedUnlockedProducts,
  selectLockedProducts,
  selectRemovedLockedProductsInCategory,
  // selectHasNullSequences,
} from "../store/product-list/ProductListSelectors";
import {
  ProductSequenceParams,
  ProductSequenceData,
} from "../store/product-list/ProductListTypes";
import {
  updatePinnedProducts,
  updateProductSequenceList,
  getProductSequenceList,
  revertProductSequence,
  lockProduct,
  unLockProduct,
  resetSavedLockedUnlockedProducts,
} from "../store/product-list/ProductListActions";
import { selectCurrentStoreId } from "../store/store-list/StoreListSelectors";
import Tooltip from "./common/ToolTip";
import ButtonComponent from "./common/ButtonComponent";
import AppState from "store/AppState";
interface Props {
  catalogId: string;
  categoryId: string;
  setProgress: Function;
  enableSaveMode: boolean;
}

const TopPanelSaveButton: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { catalogId, categoryId, enableSaveMode } = props;

  const dispatch = useDispatch();
  const productListSequenceRequestId = useSelector(getProductSequenceRequestId);
  const shouldUpdateEditSequenceListFlag = useSelector(
    shouldUpdateEditSequenceList,
  );
  const shouldUpdateNewPinnedProductsFlag = useSelector(
    shouldUpdateNewPinnedProducts,
  );
  const numberOfPinnedProducts = useSelector(selectNumberOfPinnedProducts);
  const storeId = useSelector(selectCurrentStoreId);
  const products = useSelector(selectEditSequenceList);
  const isUpdateSeqFnInvoked = useSelector(
    selectIsUpdateProductSequenceInvoked,
  );
  const savedLockedProducts = useSelector(selectSavedLockedProducts);
  const savedUnlockedProducts = useSelector(selectSavedUnlockedProducts);
  const lockedProducts = useSelector((state: AppState) =>
    selectLockedProducts(state, categoryId, catalogId),
  );
  const removedLockedProducts = useSelector((state: AppState) =>
    selectRemovedLockedProductsInCategory(state, categoryId, catalogId),
  );
  // const hasNullSequences = useSelector(selectHasNullSequences);

  const newPinnedProducts = products
    .map((product) => product.productId)
    .slice(0, numberOfPinnedProducts);

  const saveActionHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (!shouldUpdateEditSequenceListFlag || enableSaveMode) {
      props.setProgress(true);
      const productSequencesToUpdate: ProductSequenceParams[] = [];
      const updatedProductList: ProductSequenceData[] = [];
      let prevSequence = 0;
      const productIdsArray = products.map((product) => {
        return product.productId;
      });

      // Filter out locked products that changed position or are already in lockCategory
      const filteredLockedProducts = savedLockedProducts?.filter(
        (lockedProduct) => {
          const index =
            products.findIndex((p) => p.productId === lockedProduct.productId) +
            1;

          // Check if product is already in lockCategory
          const isAlreadyLocked = lockedProducts?.some(
            (lock) =>
              lock.productId === lockedProduct.productId &&
              lock.categoryId === categoryId &&
              lock.catalogId === catalogId,
          );

          // Only keep products that maintained their original sequence and aren't already locked
          return index === lockedProduct.position && !isAlreadyLocked;
        },
      );
      const filteredRemovedLockedProducts = savedUnlockedProducts?.filter(
        (unlockedProduct) => {
          return !removedLockedProducts?.some(
            (unlock) =>
              unlockedProduct.productId === unlock.productId &&
              unlock.categoryId === categoryId &&
              unlock.catalogId === catalogId,
          );
        },
      );
      if (filteredLockedProducts.length > 0) {
        dispatch(lockProduct({ lockedProducts: filteredLockedProducts }));
      }
      if (filteredRemovedLockedProducts.length > 0) {
        dispatch(
          unLockProduct({ unlockedProducts: filteredRemovedLockedProducts }),
        );
      }
      products.forEach(function (product, index) {
        var nextIndex = index + 1;
        var thisSequence = Number(product.sequence);
        var nextSequence =
          products.length !== nextIndex
            ? Number(products[nextIndex].sequence)
            : thisSequence;
        if (prevSequence >= thisSequence || thisSequence > nextSequence) {
          var rounder = Math.pow(10, 2);
          thisSequence =
            prevSequence < nextSequence
              ? (prevSequence + nextSequence) / 2
              : prevSequence + 1;
          thisSequence = Math.round(thisSequence * rounder) / rounder;
          if (prevSequence === thisSequence) {
            thisSequence = prevSequence + 1;
          }
        }
        productSequencesToUpdate.push({
          productId: product.productId,
          sequence: product.sequence,
        });
        updatedProductList.push({
          ...product,
          sequence: thisSequence,
        });
        prevSequence = thisSequence;
      });
      const payload = {
        categoryId: categoryId,
        productIds: productIdsArray,
      };
      dispatch(updateProductSequenceList(payload));
    }
    if (shouldUpdateNewPinnedProductsFlag) {
      const payload = {
        storeId: storeId || "",
        catalogId: catalogId,
        categoryId: categoryId,
        pinnedProducts: newPinnedProducts,
      };
      dispatch(updatePinnedProducts(payload));
    }
  };

  const revertProductsPosition = () => {
    dispatch(revertProductSequence());
    dispatch(resetSavedLockedUnlockedProducts());
  };
  useEffect(() => {
    if (productListSequenceRequestId && isUpdateSeqFnInvoked) {
      dispatch(
        getProductSequenceList({
          requestId: productListSequenceRequestId,
          isUpdateFunctionInvoked: isUpdateSeqFnInvoked,
        }),
      );
    }
  }, [productListSequenceRequestId, dispatch, isUpdateSeqFnInvoked]);

  return (
    <>
      <ButtonComponent
        color="secondaryButtonColorCTABlue"
        variant="outlined"
        disabled={!enableSaveMode}
        onClick={revertProductsPosition}
      >
        {intl.formatMessage({
          id: "topPanelRevertAllButton.label",
          defaultMessage: "Revert All",
        })}
      </ButtonComponent>
      <Tooltip
        TransitionComponent={Zoom}
        placement="top"
        tooltipTitle={intl.formatMessage({
          id: "topPanelSaveButton.saveProductsLabel",
          defaultMessage: "Save Product Sequences",
        })}
      >
        <ButtonComponent
          color="primary"
          variant="contained"
          disabled={!enableSaveMode}
          justifyContent="center"
          onClick={saveActionHandler}
        >
          {intl.formatMessage({
            id: "topPanelSaveButton.label",
            defaultMessage: "Save",
          })}
        </ButtonComponent>
      </Tooltip>
    </>
  );
};

export default TopPanelSaveButton;
