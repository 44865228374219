import React, { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProductListInState,
  updateProductListSequenceMode,
} from "../store/product-list/ProductListActions";
import {
  selectEditSequenceList,
  selectLockedProducts,
} from "../store/product-list/ProductListSelectors";
import { Typography, FormControl } from "@mui/material";
import { useIntl } from "react-intl";
import {
  SmartSortList,
  SEQUENCE_MODE_TYPE,
  OVERLAY_TABS,
  BoostBuryList,
} from "../utils/Constants";
import { styles, useStyles } from "./SmartSort.styles";
import DropdownMenu from "./common/DropdownMenu";
import {
  selectIsClusterResponseFetched,
  selectProductClusterListFetched,
  selectedSmartSortValue,
} from "store/ai-cluster/AiClusterSelectors";
import { getUpdatedProductList } from "utils/SmartSortUtils";
import { setSelectedSmartSort } from "store/ai-cluster/AiClusterAction";
import ButtonComponent from "./common/ButtonComponent";
import { selectOverlay } from "store/overlay/OverlaySelectors";
import AppState from "store/AppState";
import { selectConfigValue } from "store/app-config/AppConfigSelectors";
import { selectProducts } from "store/product/ProductSelectors";
import { selectCurrentCatalogId } from "store/catalog/CatalogSelectors";
import { selectCurrentCategory } from "store/category/CategorySelectors";
import { ProductSequenceData } from "store/product-list/ProductListTypes";

const SmartSort = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { bodyWrap } = styles();
  const dispatch = useDispatch();
  const products = useSelector(selectEditSequenceList);
  const selectedSmartSort = useSelector(selectedSmartSortValue);
  const productSimilarity = useSelector(selectProductClusterListFetched);
  const selectedOverlay = useSelector(selectOverlay);
  const isClusterRespFetched = useSelector(selectIsClusterResponseFetched);
  const isBoostBuryEnabled = useSelector((state: AppState) =>
    selectConfigValue(state, "enableBoostBury"),
  );
  const allProducts = useSelector(selectProducts);
  const categoryId = useSelector(selectCurrentCategory);
  const catalogId = useSelector(selectCurrentCatalogId);
  const lockedProducts = useSelector((state: AppState) =>
    selectLockedProducts(state, categoryId, catalogId),
  );

  const showBoostBury = useMemo(
    () =>
      isBoostBuryEnabled === "true" &&
      selectedOverlay === OVERLAY_TABS.SMAI &&
      isClusterRespFetched,
    [isBoostBuryEnabled, selectedOverlay, isClusterRespFetched],
  );
  const sortOptions = useMemo(
    () =>
      showBoostBury ? [...SmartSortList, ...BoostBuryList] : SmartSortList,
    [showBoostBury],
  );

  const handleSmartSortChange = useCallback(
    (event) => {
      dispatch(setSelectedSmartSort(event.target.value));
    },
    [dispatch],
  );

  const applySmartSort = useCallback(() => {
    // Create a map of locked positions using one-based positions
    const lockedPositionsMap = new Map(
      lockedProducts?.map((lock) => [lock.position - 1, lock.productId]),
    );

    // Get updated list from smart sort
    const updatedProductList = getUpdatedProductList(
      products,
      productSimilarity,
      allProducts,
      selectedSmartSort,
    );

    const usedProducts = new Set();

    // First, handle locked products
    lockedProducts?.forEach((lock) => {
      usedProducts.add(lock.productId);
    });

    // Create final list preserving locked products positions
    const finalProductList = products?.map((_, index) => {
      // If this position is locked, keep the original product
      if (lockedPositionsMap.has(index)) {
        const lockedProductId = lockedPositionsMap.get(index);
        return products.find((p) => p.productId === lockedProductId);
      }

      // Find the next unused product from the updated list
      let nextProduct;
      for (const product of updatedProductList) {
        if (!usedProducts.has(product.productId)) {
          nextProduct = product;
          usedProducts.add(product.productId);
          break;
        }
      }
      return nextProduct;
    });

    const hasSequenceChanged = products.some(
      (product, index) =>
        product.productId !== finalProductList?.[index]?.productId,
    );
    if (hasSequenceChanged) {
      dispatch(
        updateProductListInState({
          products: finalProductList as ProductSequenceData[],
          isSaveAction: true,
        }),
      );
      dispatch(updateProductListSequenceMode(SEQUENCE_MODE_TYPE.EDIT));
    }
  }, [
    products,
    productSimilarity,
    allProducts,
    selectedSmartSort,
    dispatch,
    lockedProducts,
  ]);

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.sortClass}>
          <FormControl sx={bodyWrap}>
            <label>
              <Typography variant="subHeader">
                {intl.formatMessage({
                  id: "smartSort.label",
                  defaultMessage: "Smart Sort",
                })}
              </Typography>
            </label>
            <DropdownMenu
              menuId="SmartSortTools"
              value={selectedSmartSort}
              changeHandler={handleSmartSortChange}
              smartSortMenuList={sortOptions}
            />
          </FormControl>
          <ButtonComponent
            color="primary"
            variant="contained"
            disabled={!selectedSmartSort}
            onClick={applySmartSort}
          >
            {intl.formatMessage({
              id: "smartSortButton.label",
              defaultMessage: "Move",
            })}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SmartSort);
