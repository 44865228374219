import React, { useContext } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Theme, Typography, useTheme } from "@mui/material";
import {
  selectCurrencyCode,
  selectCurrentStoreId,
} from "../store/store-list/StoreListSelectors";
import AppState from "../store/AppState";
import { selectOverlay } from "../store/overlay/OverlaySelectors";
import {
  CURRENCY_MAP,
  PRODUCT_IMAGE_BADGE_MARGIN_TOP,
} from "../utils/Constants";
import { useIntl } from "react-intl";
import { ViewContext } from "../context/view-context";
import Tooltip from "./common/ToolTip";
import {
  selectAccountFeatures,
  selectCallerAccountId,
  selectProductImagePrefix,
} from "store/app-config/AppConfigSelectors";
import { isAbsolutePath, isListPriceGreaterThanPrice } from "utils/ProductUtil";
import NoImageIcon from "../assets/NoImage.png";
import { BadgeChip } from "./product-title-content/ProductTileContentStyles";
import { LockIcon, ProductBadge } from "assets";
import { selectLockedProductInCurrentCategory } from "store/product-list/ProductListSelectors";
import { selectCurrentCatalogId } from "store/catalog/CatalogSelectors";
import { selectCurrentCategory } from "store/category/CategorySelectors";

const StyledProductDetail = styled.div<{
  selectedOverlay: string;
}>`
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
`;

const ImageContainer = styled.div<{
  height?: string | number;
  columns?: number;
}>`
  overflow: hidden;
  height: ${({ height }) => height};
  display: flex;
  justify-content: center;
  position: relative;
  & .image-wrapper {
    display: flex;
  }
  & .image {
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 15 / 10;
    object-fit: contain;
  }
`;

const StyledLabel = styled.div`
  ${({ theme }) => `
    color: ${theme.palette.secondary.main};
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: ${theme.palette.primary.main};
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    width: 200px;
    position: absolute;
    top: 10px;
    right: 5px;
    border: 1px;
    border-top-left-radius: 50px;
    padding: 2px;
  `}
`;

interface Props {
  name: string;
  price: string | null;
  priceRange: number[] | null;
  code?: string;
  type?: string;
  image?: string | null;
  productScore?: number | undefined;
  isRecentlyAdded?: boolean | undefined;
  badgeLabel?: string | null;
  productId?: string;
  sequence?: number;
}

const useStyles = (theme: Theme) => {
  return {
    productName: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "100%",
      display: "block",
      paddingLeft: 15,
      paddingRight: 15,
    },
    product: {
      paddingTop: 10,
      display: "flex",
      flexDirection: "column" as const,
    },
    lockedProductDiv: (columns: number) => ({
      backgroundColor: theme.palette.primary.main,
      padding: "4px",
      borderRadius: "2px",
      height: "25px",
      position: "absolute" as const,
      left:
        columns === 3
          ? "20%"
          : columns === 4
            ? "25%"
            : columns === 5
              ? "17%"
              : columns === 6
                ? "15%"
                : "30%",
      marginTop: 0,
    }),
    lockedProductIcon: {
      height: "16px",
    },
  };
};

const ProductDetail: React.FC<Props> = (props) => {
  const {
    name,
    price,
    priceRange,
    type,
    image,
    isRecentlyAdded,
    badgeLabel,
    productId,
    sequence,
  } = props;
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles(theme);
  const storeId = useSelector(selectCurrentStoreId);
  const productImagePrefix = useSelector((state: AppState) =>
    selectProductImagePrefix(state, storeId),
  );
  const selectedOverlay = useSelector((state: AppState) =>
    selectOverlay(state),
  );
  const currencyCode = useSelector(selectCurrencyCode);
  let currencySymbol = "$";
  if (CURRENCY_MAP[currencyCode]) {
    currencySymbol = CURRENCY_MAP[currencyCode];
  }
  const view = useContext(ViewContext);
  const columns = view.styles.column;
  const changeStyles =
    columns === 6 ? { fontSize: "0.875rem" } : { fontSize: "inherit" };
  const productImage = image
    ? isAbsolutePath(image)
      ? image
      : productImagePrefix + image
    : NoImageIcon;

  const isListPriceGreaterThanSalePrice = isListPriceGreaterThanPrice(
    price,
    priceRange,
  );
  const variantType = isListPriceGreaterThanSalePrice
    ? "discountedPrice"
    : "price";
  const lowPrice = priceRange ? `${currencySymbol}${priceRange[0]}` : null;
  const highPrice = priceRange ? `${currencySymbol}${priceRange[1]}` : null;
  const isLowAndHighPriceAreSame = lowPrice === highPrice;
  const imageMarginTop = `${PRODUCT_IMAGE_BADGE_MARGIN_TOP.get(columns) || 30}px`;
  const catalogId = useSelector(selectCurrentCatalogId);
  const currentCategoryId = useSelector(selectCurrentCategory);
  const isProductLockedInCurrentCategory = useSelector((state: AppState) =>
    selectLockedProductInCurrentCategory(
      state,
      currentCategoryId,
      catalogId,
      productId ?? "",
      sequence,
    ),
  );
  const accountId = useSelector(selectCallerAccountId);
  const features = useSelector((state: AppState) =>
    selectAccountFeatures(state, accountId),
  );
  const isLockedProductsFeatureEnabled = features?.["locked-products"] ?? false;
  return (
    <StyledProductDetail selectedOverlay={selectedOverlay}>
      <ImageContainer columns={columns}>
        {productImage ? (
          <div className="image-wrapper">
            {isLockedProductsFeatureEnabled &&
              isProductLockedInCurrentCategory && (
                <div style={classes.lockedProductDiv(columns)}>
                  <LockIcon style={classes.lockedProductIcon} />
                </div>
              )}
            <img
              className="image"
              src={`${productImage}`}
              alt={name}
              draggable={false}
            />
          </div>
        ) : null}
      </ImageContainer>
      {badgeLabel && (
        <BadgeChip
          icon={<ProductBadge />}
          label={badgeLabel}
          marginTop={imageMarginTop}
        />
      )}
      <div style={classes.product}>
        <Tooltip tooltipTitle={name} interactive enterDelay={500}>
          <Typography
            style={{ ...changeStyles, ...classes.productName }}
            variant="title"
          >
            {name}
          </Typography>
        </Tooltip>
        <div>
          <Typography style={changeStyles} variant="listPrice">
            {isListPriceGreaterThanSalePrice
              ? `${currencySymbol}${price}  `
              : ""}
          </Typography>
          <Typography style={changeStyles} variant={variantType}>
            {priceRange
              ? type === "Grouped Product" || type === "Product Set"
                ? `Starting ${type === "Product Set" ? "from" : "at"} ${lowPrice}`
                : lowPrice && highPrice
                  ? isLowAndHighPriceAreSame
                    ? `${lowPrice}`
                    : `${lowPrice}-${highPrice}`
                  : `${lowPrice}`
              : type?.includes("Bundle")
                ? price && `${currencySymbol}${price}`
                : ""}
          </Typography>
        </div>
        {isRecentlyAdded === true ? (
          <StyledLabel>
            {intl.formatMessage({
              id: "productDetail.recentlyAdded",
              defaultMessage: "Recently Added",
            })}
          </StyledLabel>
        ) : null}
      </div>
    </StyledProductDetail>
  );
};

export default ProductDetail;
