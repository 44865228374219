import React from "react";
import { useIntl } from "react-intl";

const ProductBadgeOverlayLabel = () => {
  const intl = useIntl();
  return (
    <>
      {intl.formatMessage({
        id: "overlayTabs.badgesLabel",
        defaultMessage: "Badges",
      })}
    </>
  );
};

export default React.memo(ProductBadgeOverlayLabel);
