import { all, fork, takeLatest } from "typed-redux-saga/macro";
import {
  watchLoadProductsFromCategory,
  watchUpdateProductListSequence,
  watchUpdatePinnedProducts,
  watchLoadSortingRulesList,
  watchSortedProductsList,
  watchRefreshProductAction,
  watchProductIdsFromCategory,
  watchUpdateProductSequence,
  watchGetProductSequenceList,
  watchFetchUpdatedProductAction,
  watchRefreshProductColorAction,
  watchLoadProductListAction,
  watchAddProductToWatchList,
  watchRemoveProductFromWatchList,
  watchCategoryChange,
  watchLockProduct,
  watchUnlockProduct,
  watchGetLocksForCategory,
} from "./product-list/ProductListOperations";
import {
  watchLoadProductDetails,
  watchLoadProductDetailsForReport,
  watchUpdateProductPublishState,
  watchUpdateProductDeleteState,
  watchLoadProductAnalytics,
  watchLoadCategoryByProductId,
  watchUpdateProductAttribute,
  watchUpdateLocaleProductPublish,
  watchCheckValidationProductCodes,
  watchUpdateProductColors,
  watchUpdateProductPublishByDefaultState,
  watchUpdateProductPublishStateByStore,
  watchUpdateSingleProductDeleteState,
  watchfetchProductsVariants,
  watchUpdateProductVariantPublishState,
  watchResetProductVariantsState,
  watchUpdateRecentlyAddedProducts,
  watchUpdateRecentlyAddedProductsNew,
  watchUpdateProductIsPublishState,
  watchProductIsPublishedState,
  watchAddProductInCategory,
  watchRemoveProductInCategory,
  watchVerifyClipboardProductCode,
  watchLoadSingleProductAnalytics,
  watchCloseInventoryManagementModal,
  watchLoadProductAnalyticsCategoryChange,
  watchReassignVariations,
} from "./product/ProductOperations";
import { watchResetColorManagementList } from "./modal/ModalOperations";
import {
  watchFetchColorManagementProductList,
  watchUpdatePublishFlagInColorModal,
  watchUpdateLeadingColorForProduct,
  watchResetUpdateLeadingColorState,
  watchUpdateProductColorIdSequence,
} from "./color-management/ColorManagementOperations";
import { watchLoadStoreList } from "./store-list/StoreListOperations";
import {
  watchLoadTopCategories,
  watchLoadTopCategoriesForReport,
  watchLoadChildCategories,
  watchLoadParentChildCategories,
  watchLoadChildCategoriesForReport,
  watchCloseAllCategorySubscriptions,
  watchResetTopCategoriesState,
  watchUpdateTopCategoriesLoadingState,
  watchLoadTopCategoriesForFindProducts,
  watchLoadChildCategoriesForFindProducts,
  watchLoadCategoryDetailsById,
} from "./category/CategoryOperations";
import {
  watchLoadCatalogList,
  watchLoadAllCatalogs,
} from "./catalog/CatalogOperations";
import {
  watchLoadFindProductsTopCategories,
  watchCloseAllFindProductsSubscriptions,
  watchLoadFindProductsChildCategories,
  watchLoadProductsForFindProductsCategory,
  watchUpdateProductsCategory,
  watchLoadFindProductModalProductList,
  watchProductIdsFromFindProductsCategory,
  watchCloseFindProductModal,
  watchCloseLoadFindProductModal,
  watchCloseSubcategoriesFindProductModal,
  watchCloseTopCategoriesFindProductModal,
} from "./find-products/FindProductsOperations";

import {
  watchDeleteFacetsFromCategory,
  watchAddFacetsToCategory,
  watchUpdateAttributeValueSequences,
  watchUpdateFacetSequences,
  watchUpdateInheritedFacet,
  watchUpdateSFCCFacetSequences,
  watchUpdateFacetBucketSequences,
  watchUpdateFacetBucketDescription,
  watchDeleteFacetBucketValues,
  watchAddFacetBucketValues,
  watchUpdateFacetBucket,
  watchAddFacetBucket,
} from "./facets/FacetOperations";
import {
  watchUpdateAttributeValues,
  watchUpdateAttributeValuesCode,
  watchAddAttributeValue,
  watchGetAttributeById,
} from "./attributes/AttributeOperations";
import { watchErrorGlobalAlert } from "./global-alert/GlobalAlertOperations";
import { watchGoogleAnalyticsEvent } from "./google-analytics/GoogleAnalyticsOperations";
import {
  watchAttributesList,
  watchDeleteBusinessRuleById,
  watchGetUnifiedBusinessRulesDetailsById,
  watchLoadJobsList,
  watchLoadAutomationRulesList,
  watchSaveUnifiedBusinessRulesDetails,
  watchUnifiedSortingRulesList,
  watchUpdateUnifiedBusinessRulesDetails,
  watchDeleteAutomationRuleByJobId,
  watchSaveAutomationRules,
  watchGetAutomationRuleByJobId,
  watchUpdateAutomationRule,
  watchFetchDataForMultipleCategoryIds,
  watchSystemGeneratedSortingRules,
  watchFetchAttributes,
} from "./businessRules-list/BusinessRulesListOperations";
import {
  watchLoadCatalogs,
  watchLoadStores,
  watchLoadAllTopCategories,
  watchLoadAllChildCategories,
  watchAddToProductCategories,
  watchResetProductAddedState,
  watchCloseChildCategoriesAddProductModal,
  watchCloseTopCategoriesAddProductModal,
  watchCloseCatalogAddProductModal,
} from "./add-product-categories/AddProductCategoriesOperations";

import {
  watchLoadCategoriesByProductIds,
  watchDeleteProductByCategories,
  watchResetProductRemoveState,
  watchCloseRemoveProductModal,
} from "./remove-product-categories/RemoveProductCategoriesOperations";

import {
  watchUpdateVariationGroup,
  watchAddVariationGroup,
  watchDeleteVariationGroup,
  watchGetVariationAttributes,
  watchGetVariationGroupId,
  watchGetVariationGroupDetails,
  watchResetVariantGroupDetails,
  watchResetVariantGroupDetailsByGroupId,
  watchResetVariationDeletedState,
  watchResetVariationAddedState,
  watchFetchVariationGroupProducts,
  watchLoadVariationProducts,
  watchUpdatePublishFlagInVariationModal,
  watchResetVariationGroupProductListState,
  watchCloseVariationManagementModal,
} from "./variation-management/VariationManagementOperations";
import {
  watchGetAllAccountDetails,
  watchGetAppConfig,
  watchGetCallerAccounts,
} from "./app-config/AppConfigOperations";
import {
  watchSetLeadingImageForProductInCategory,
  watchRemoveLeadingImageForProductInCategory,
  watchGetLeadingImageVariationAttributes,
  watchResetVariationAttributesForProduct,
  watchCloseImageManagementModal,
} from "./leading-image/LeadingImageOperations";
import { watchFetchProductSocialAnalytics } from "./product-social-analytics/SocialAnalyticsOperations";
import { watchGetAdapterConfig } from "./adapter-config/AdapterConfigOperations";
import { watchLoadProductAlertsList } from "./product-alerts-modal/ProductAlertsListOperations";
import {
  watchDeleteProductAlertsScheduleData,
  watchLoadProductAlertsScheduleData,
  watchUpdateProductAlertsScheduleData,
} from "./product-alerts-schedule-modal/ProductAlertsScheduleOperations";
import { CATEGORY_CHANGE } from "./product-list/ProductListTypes";
import { MODAL_CLOSED } from "./modal/ModalTypes";
import {
  watchAddClusterEmbeddings,
  watchFetchRefreshClusterList,
  watchLoadClusterList,
  watchfetchSimilarProductList,
} from "./ai-cluster/AiClusterOperations";
import {
  watchLoadProductBadgeList,
  watchLoadQueryProduct,
  watchUpdateBadgePrecedence,
  watchDeleteBadge,
  watchGetProductBadge,
  watchCreateProductBadge,
  watchUpdateProductBadge,
  watchUpdateProductBadgeAssignments,
  watchBadgeConfigureMessage,
} from "./product-badge/ProductBadgeOperations";

export default function* rootSaga(): Generator {
  yield* takeLatest(CATEGORY_CHANGE, watchCategoryChange);
  yield* takeLatest(CATEGORY_CHANGE, watchLoadProductAnalyticsCategoryChange);
  yield* takeLatest(MODAL_CLOSED, watchCloseChildCategoriesAddProductModal);
  yield* takeLatest(MODAL_CLOSED, watchCloseTopCategoriesAddProductModal);
  yield* takeLatest(MODAL_CLOSED, watchCloseCatalogAddProductModal);
  yield* takeLatest(MODAL_CLOSED, watchCloseTopCategoriesFindProductModal);
  yield* takeLatest(MODAL_CLOSED, watchCloseSubcategoriesFindProductModal);
  yield* takeLatest(MODAL_CLOSED, watchCloseLoadFindProductModal);
  yield* takeLatest(MODAL_CLOSED, watchCloseFindProductModal);
  yield* takeLatest(MODAL_CLOSED, watchCloseImageManagementModal);
  yield* takeLatest(MODAL_CLOSED, watchCloseInventoryManagementModal);
  yield* takeLatest(MODAL_CLOSED, watchCloseRemoveProductModal);
  yield* takeLatest(MODAL_CLOSED, watchCloseVariationManagementModal);
  yield* all(
    [
      watchLoadProductsFromCategory,
      watchFetchColorManagementProductList,
      watchLoadProductBadgeList,
      watchLoadQueryProduct,
      watchLoadCategoryByProductId,
      watchUpdatePublishFlagInColorModal,
      watchUpdateProductListSequence,
      watchLoadProductDetails,
      watchLoadProductDetailsForReport,
      watchLoadStoreList,
      watchLoadCatalogList,
      watchLoadTopCategories,
      watchResetTopCategoriesState,
      watchUpdateTopCategoriesLoadingState,
      watchLoadTopCategoriesForReport,
      watchLoadChildCategories,
      watchLoadChildCategoriesForReport,
      watchLoadParentChildCategories,
      watchCloseAllCategorySubscriptions,
      watchUpdateProductPublishState,
      watchUpdateProductPublishByDefaultState,
      watchUpdateProductPublishStateByStore,
      watchUpdateProductDeleteState,
      watchUpdateSingleProductDeleteState,
      watchUpdateLocaleProductPublish,
      watchLoadCategoryByProductId,
      watchUpdateProductAttribute,
      watchLoadProductAnalytics,
      watchLoadFindProductsTopCategories,
      watchCloseAllFindProductsSubscriptions,
      watchLoadFindProductsChildCategories,
      watchLoadProductsForFindProductsCategory,
      watchUpdateProductsCategory,
      watchCheckValidationProductCodes,
      watchUpdateProductColors,
      watchDeleteFacetsFromCategory,
      watchAddFacetsToCategory,
      watchUpdateAttributeValues,
      watchUpdateAttributeValueSequences,
      watchUpdateFacetSequences,
      watchUpdateAttributeValuesCode,
      watchAddAttributeValue,
      watchErrorGlobalAlert,
      watchUpdateInheritedFacet,
      watchUpdateSFCCFacetSequences,
      watchUpdatePinnedProducts,
      watchGetAttributeById,
      watchUpdateFacetBucketSequences,
      watchUpdateFacetBucketDescription,
      watchLoadAllCatalogs,
      watchAddFacetBucketValues,
      watchDeleteFacetBucketValues,
      watchUpdateFacetBucket,
      watchAddFacetBucket,
      watchGoogleAnalyticsEvent,
      watchUnifiedSortingRulesList,
      watchSaveUnifiedBusinessRulesDetails,
      watchAttributesList,
      watchGetUnifiedBusinessRulesDetailsById,
      watchUpdateUnifiedBusinessRulesDetails,
      watchDeleteBusinessRuleById,
      watchLoadSortingRulesList,
      watchSortedProductsList,
      watchLoadStores,
      watchLoadCatalogs,
      watchLoadAllTopCategories,
      watchLoadAllChildCategories,
      watchAddToProductCategories,
      watchResetProductAddedState,
      watchLoadCategoriesByProductIds,
      watchDeleteProductByCategories,
      watchResetProductRemoveState,
      watchLoadJobsList,
      watchLoadAutomationRulesList,
      watchfetchProductsVariants,
      watchUpdateProductVariantPublishState,
      watchResetProductVariantsState,
      watchUpdateRecentlyAddedProducts,
      watchDeleteAutomationRuleByJobId,
      watchSaveAutomationRules,
      watchGetAutomationRuleByJobId,
      watchUpdateAutomationRule,
      watchFetchDataForMultipleCategoryIds,
      watchGetAppConfig,
      watchUpdateProductSequence,
      watchGetProductSequenceList,
      watchUpdateRecentlyAddedProductsNew,
      watchUpdateVariationGroup,
      watchAddVariationGroup,
      watchDeleteVariationGroup,
      watchGetVariationAttributes,
      watchGetVariationGroupId,
      watchGetVariationGroupDetails,
      watchResetVariantGroupDetails,
      watchResetVariantGroupDetailsByGroupId,
      watchResetVariationDeletedState,
      watchResetVariationAddedState,
      watchRefreshProductAction,
      watchProductIdsFromCategory,
      watchUpdateProductIsPublishState,
      watchProductIsPublishedState,
      watchAddProductInCategory,
      watchRemoveProductInCategory,
      watchFetchUpdatedProductAction,
      watchFetchVariationGroupProducts,
      watchLoadVariationProducts,
      watchUpdatePublishFlagInVariationModal,
      watchResetVariationGroupProductListState,
      watchVerifyClipboardProductCode,
      watchSetLeadingImageForProductInCategory,
      watchRemoveLeadingImageForProductInCategory,
      watchGetLeadingImageVariationAttributes,
      watchUpdateLeadingColorForProduct,
      watchResetUpdateLeadingColorState,
      watchLoadTopCategoriesForFindProducts,
      watchLoadChildCategoriesForFindProducts,
      watchLoadFindProductModalProductList,
      watchProductIdsFromFindProductsCategory,
      watchUpdateProductColorIdSequence,
      watchResetColorManagementList,
      watchResetVariationAttributesForProduct,
      watchFetchProductSocialAnalytics,
      watchRefreshProductColorAction,
      watchSystemGeneratedSortingRules,
      watchGetAdapterConfig,
      watchGetCallerAccounts,
      watchGetAllAccountDetails,
      watchLoadProductListAction,
      watchLoadProductAlertsList,
      watchAddProductToWatchList,
      watchRemoveProductFromWatchList,
      watchLoadProductAlertsScheduleData,
      watchUpdateProductAlertsScheduleData,
      watchDeleteProductAlertsScheduleData,
      watchLoadSingleProductAnalytics,
      watchFetchAttributes,
      watchLoadClusterList,
      watchAddClusterEmbeddings,
      watchFetchRefreshClusterList,
      watchfetchSimilarProductList,
      watchUpdateBadgePrecedence,
      watchDeleteBadge,
      watchGetProductBadge,
      watchCreateProductBadge,
      watchUpdateProductBadge,
      watchUpdateProductBadgeAssignments,
      watchReassignVariations,
      watchBadgeConfigureMessage,
      watchLockProduct,
      watchUnlockProduct,
      watchGetLocksForCategory,
      watchLoadCategoryDetailsById,
    ].map(fork),
  );
}
