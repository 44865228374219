import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Item } from "../../store/product/ProductTypes";
import { Order } from "../../utils/SortTableUtils";
import TableComponent from "../common/Table";
import useStyles from "./VariationReassignmentStyles";
import { useTheme } from "@mui/material/styles";

interface VariationReassignmentListProps {
  items: Item[];
  handleCheck: (
    event: React.MouseEvent<unknown>,
    SKU: string,
    key: string,
  ) => void;
  selectedRows: string[];
}

const VariationReassignmentList = (props: VariationReassignmentListProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("");
  const { items, handleCheck, selectedRows } = props;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const tableClasses = {
    ...classes.noRecordsTablecell,
  };
  return (
    <div style={classes.root}>
      <Paper style={classes.paper}>
        <TableComponent
          tableId="VariationReassignmentList"
          classes={tableClasses}
          items={items}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
          handleCheck={handleCheck}
          selectedRows={selectedRows}
        />
      </Paper>
    </div>
  );
};

export default VariationReassignmentList;
