import React from "react";
import { TextField, TextFieldProps, InputAdornment } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { styled } from "@mui/system";
import PageviewIcon from "@mui/icons-material/Pageview";

// Define the styled search icon component
const StyledSearchIcon = styled(PageviewIcon)<{ disabled: boolean }>(
  ({ theme, disabled }) => ({
    color: disabled ? theme.palette.grey[400] : theme.palette.primary.main, // Use custom or default theme colors
    cursor: disabled ? "not-allowed" : "pointer", // Change cursor based on the disabled state
    fontSize: "3rem",
    transition: "color 0.3s ease",
    "&:hover": {
      color: !disabled && theme.palette.primaryHoverColor.main, // Darken color on hover if not disabled
    },
  }),
);

// Styled TextField component with custom focus and hover styles
const StyledTextField = styled(TextField)(({ theme }) => ({
  [`& .MuiInputBase-root`]: {
    height: "42px !important", // Default height
    paddingRight: "0px !important",
    "&.Mui-focused": {
      borderColor: theme?.palette?.secondaryButtonColorCTABlue?.main, // Active focus border color
    },
  },
  [`& .MuiOutlinedInput-root`]: {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme?.palette?.secondaryButtonColorCTABlue?.main, // Set focused outline border color
    },
  },
  [`& .MuiInputAdornment-root`]: {
    cursor: "pointer", // Custom cursor for adornment
  },
}));

type TextFieldComponentProps = TextFieldProps & {
  textFieldId: string;
  classes?: ClassNameMap;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: React.Dispatch<React.SetStateAction<boolean>>;
  handleFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholderText?: string;
  disabled?: boolean;
  handleSearchSubmit?: () => void; // Function to handle search icon click
  variant?: "outlined" | "standard" | "filled";
};

const TextFieldComponent: React.FC<TextFieldComponentProps> = (props) => {
  const {
    textFieldId,
    classes,
    onChange,
    value,
    placeholderText,
    onKeyDown,
    handleFocus,
    handleBlur,
    disabled,
    handleSearchSubmit,
    variant,
  } = props;

  switch (textFieldId) {
    case "SearchInput":
      return (
        <StyledTextField
          variant={variant ?? "outlined"}
          placeholder={placeholderText ?? "Search..."}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <StyledSearchIcon
                  fontSize="large"
                  onClick={value.trim() ? handleSearchSubmit : undefined}
                  disabled={!value.trim()} // Pass the disabled prop to control the state
                />
              </InputAdornment>
            ),
            classes: {
              root: classes?.root,
              input: classes?.input,
            },
          }}
          fullWidth
        />
      );

    default:
      return (
        <StyledTextField
          variant={variant ?? "outlined"}
          placeholder={placeholderText ?? "Enter value"}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled ?? false}
          fullWidth
        />
      );
  }
};

export default TextFieldComponent;
