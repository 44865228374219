import { Box, CircularProgress, IconButton, useTheme } from "@mui/material";
import ButtonComponent from "components/common/ButtonComponent";
import { useIntl } from "react-intl";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { ProductBadgeData } from "store/product-badge/ProductBadgeTypes";
import { selectStoreList } from "store/store-list/StoreListSelectors";
import {
  selectProductBadgeList,
  selectIsLoading,
} from "store/product-badge/ProductSelectors";
import { TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import debounce from "lodash/debounce";
import {
  updateBadgePrecedence,
  deleteBadge,
  getProductBadgeDetails,
  setSelectedStore,
} from "store/product-badge/ProductBadgeActions";
import ConfirmRemoveBadges from "./ConfirmRemoveBadges";
import { setModalState } from "store/modal/ModalActions";
import BadgeFormModal from "./BadgeFormModal";
import { StyledDiv, styles } from "./BadgeManagementStyles";

function BadgeManagementList() {
  const intl = useIntl();
  const theme = useTheme();
  const classes = styles(theme);
  const badges = useSelector(selectProductBadgeList);
  const stores = useSelector(selectStoreList);
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();

  // Create a debounced update function
  const debouncedUpdate = useMemo(
    () =>
      debounce((badgeDetails: ProductBadgeData) => {
        dispatch(updateBadgePrecedence(badgeDetails));
      }, 300),
    [dispatch],
  );

  // Add local state to manage editing values
  const [editingValues, setEditingValues] = useState<Record<string, number>>(
    {},
  );
  const [badgeToDelete, setBadgeToDelete] = useState<any>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [badgeId, setBadgeId] = useState<string>("");

  const rowData = useMemo(() => {
    const data = badges.length ? badges : [];
    return data?.map((badge) => ({
      id: badge.badgeId,
      store:
        stores?.find((store) => store.storeId === badge.storeId)?.code ||
        badge.storeId,
      badge: badge.label,
      precedence: badge.precedence,
    }));
  }, [badges, stores]);

  const [parsedRowData, setParsedRowData] = useState(rowData);

  const handleNewBadge = () => {
    if (isEditMode) setIsEditMode(false);
    if (badgeId !== "") setBadgeId("");
    dispatch(setModalState("BadgeFormModal", true));
  };

  const handleEditBadge = (row) => {
    if (!isEditMode) setIsEditMode(true);
    setBadgeId(row.id);
    const storeId =
      stores.find((store) => store.code === row.store)?.storeId ?? "";
    dispatch(setSelectedStore(storeId));
    dispatch(getProductBadgeDetails({ badgeId: row.id, storeId: storeId }));
    dispatch(setModalState("BadgeFormModal", true));
  };

  const handleDeleteBadge = (row) => {
    setBadgeToDelete(row);
    dispatch(setModalState("ConfirmRemoveBadge", true));
  };

  const handleCloseConfirmDeleteModal = useMemo(
    () => () => {
      dispatch(setModalState("ConfirmRemoveBadge", false));
      setBadgeToDelete(null);
    },
    [dispatch],
  );

  const handleConfirmDelete = useMemo(
    () => () => {
      if (badgeToDelete) {
        dispatch(
          deleteBadge({
            badgeId: badgeToDelete.id,
            storeId: badgeToDelete.store,
          }),
        );
        handleCloseConfirmDeleteModal();
      }
    },
    [badgeToDelete, dispatch, handleCloseConfirmDeleteModal],
  );

  const handleProcessRowUpdate = (newRow) => {
    const updatedRow = { ...newRow };
    setParsedRowData((prev) =>
      prev.map((row) => (row.id === updatedRow.id ? updatedRow : row)),
    );
    const badgeDetails: ProductBadgeData = {
      badgeId: newRow.id,
      storeId:
        stores.find((store) => store.code === newRow.store)?.storeId || null,
      label: newRow.badge,
      precedence: newRow.precedence,
      attributeId:
        badges?.find((badge) => badge.badgeId === newRow.id)?.attributeId || "",
      valueId:
        badges?.find((badge) => badge.badgeId === newRow.id)?.valueId || "",
    };

    // Call the debounced update
    debouncedUpdate(badgeDetails);
    return updatedRow;
  };

  useEffect(() => {
    setParsedRowData(
      badges.map((badge) => ({
        id: badge.badgeId ?? "",
        store:
          stores.find((store) => store.storeId === badge.storeId)?.code ||
          badge.storeId,
        badge: badge.label,
        precedence: badge.precedence,
      })),
    );
  }, [badges, stores]);

  const handlePrecedenceChange = (row: any, newValue: string) => {
    const value = parseInt(newValue, 10);
    if (!isNaN(value) && value >= 0) {
      setEditingValues((prev) => ({
        ...prev,
        [row.id]: value,
      }));
    }
  };

  const columns = [
    {
      field: "store",
      headerName: intl.formatMessage({
        id: "badgeForm.store",
        defaultMessage: "Store",
      }),
      width: 230,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "badge",
      headerName: intl.formatMessage({
        id: "badgeForm.badge",
        defaultMessage: "Badge",
      }),
      width: 240,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "precedence",
      headerName: intl.formatMessage({
        id: "badgeForm.precedence",
        defaultMessage: "Precedence",
      }),
      width: 170,
      filterable: false,
      disableColumnMenu: true,
      editable: true,
      type: "number",
      cellClassName: "data-grid-cell",
      preProcessEditCellProps: (params) => {
        const value = parseInt(params.props.value);
        if (isNaN(value) || value < 0) {
          return { ...params.props, error: true };
        }
        return { ...params.props, error: false };
      },
      renderCell: (params) => (
        <Box sx={classes.precedenceCell}>{params.value}</Box>
      ),
      renderEditCell: (params) => (
        <Box sx={classes.precedenceCell}>
          <TextField
            type="number"
            value={
              editingValues[params.row.id] !== undefined
                ? editingValues[params.row.id]
                : params.row.precedence
            }
            onChange={(e) => {
              handlePrecedenceChange(params.row, e.target.value);
            }}
            variant="standard"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              style: { textAlign: "center" },
            }}
            size="small"
            autoFocus
            sx={classes.precedenceTextField}
            onBlur={() => {
              const updatedValue =
                editingValues[params.row.id] !== undefined
                  ? editingValues[params.row.id]
                  : params.row.precedence;

              const updatedRow = { ...params.row, precedence: updatedValue };
              handleProcessRowUpdate(updatedRow);

              // Clear editing values
              setEditingValues((prev) => {
                const next = { ...prev };
                delete next[params.row.id];
                return next;
              });

              params.api.stopCellEditMode({
                id: params.row.id,
                field: "precedence",
              });
            }}
          />
        </Box>
      ),
    },
    {
      field: "",
      headerName: "",
      width: 210,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerClassName: "data-grid-header",
      cellClassName: "data-grid-cell",
      renderCell: (params) => (
        <>
          <IconButton
            size="small"
            onClick={() => handleEditBadge(params.row)}
            sx={classes.iconButton}
          >
            {intl.formatMessage({
              id: "badgeManagement.edit",
              defaultMessage: "edit",
            })}
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleDeleteBadge(params.row)}
            sx={classes.iconButton}
          >
            {intl.formatMessage({
              id: "badgeManagement.delete",
              defaultMessage: "delete",
            })}
          </IconButton>
        </>
      ),
    },
  ];
  return (
    <>
      <StyledDiv>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleNewBadge}
        >
          {intl.formatMessage({
            id: "badgeManagement.newBadge",
            defaultMessage: "New",
          })}
        </ButtonComponent>
      </StyledDiv>
      {isLoading && (
        <div style={classes.overlay}>
          <CircularProgress />
        </div>
      )}
      {parsedRowData.length ? (
        <DataGrid
          getRowId={(row) => row.id ?? ""}
          rows={parsedRowData}
          columns={columns}
          pageSizeOptions={[5]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          sx={classes.table}
          processRowUpdate={(newRow) => {
            // Update row data
            setParsedRowData((prev) =>
              prev.map((row) => (row.id === newRow.id ? newRow : row)),
            );

            return newRow; // Commit the updated row
          }}
          disableRowSelectionOnClick
        />
      ) : (
        <div style={classes.loaderStyle}>
          {intl.formatMessage({
            id: "badgeManagement.noBadges",
            defaultMessage: "No Badges found",
          })}
        </div>
      )}
      <ConfirmRemoveBadges
        handleClose={handleCloseConfirmDeleteModal}
        handleConfirm={handleConfirmDelete}
      />
      <BadgeFormModal isEditMode={isEditMode} badgeId={badgeId} />
    </>
  );
}

export default BadgeManagementList;
