import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { Item } from "../../store/product/ProductTypes";
import useStyles from "./VariationReassignmentStyles";
import { useTheme } from "@mui/material";
import { useIntl } from "react-intl";

type Order = "asc" | "desc";

interface VariationReassignmentTableHeaderProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  rows: Item[];
}

export default function VariationReassignmentTableHeader(
  props: VariationReassignmentTableHeaderProps,
) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const intl = useIntl();
  const { order, orderBy, onRequestSort, rows } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {rows[0] &&
          Object.keys(rows[0]).map((key) => (
            <TableCell
              key={key}
              sortDirection={orderBy === key ? order : false}
              align="center"
              style={classes.tableCell}
            >
              <TableSortLabel
                active={orderBy === key}
                direction={orderBy === key ? order : "asc"}
                onClick={createSortHandler(key)}
                style={classes.icon}
              >
                {key}
                {orderBy === key ? (
                  <span style={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        <TableCell align="center" style={classes.tableCell} padding="none">
          <span style={classes.icon}>
            {intl.formatMessage({
              id: "variationReassignmentModal.title",
              defaultMessage: "Reassign",
            })}
          </span>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
