import {
  FETCH_TOP_CATEGORIES,
  FETCH_CHILD_CATEGORIES,
  FETCH_PARENT_CHILD_CATEGORIES,
  RESET_CATEGORY,
  CategoryActionTypes,
  LoadTopCategoriesResponseAction,
  LoadChildCategoriesResponseAction,
  AttributeData,
  Translations,
  SetAdditionalAttributesAction,
  SET_ADDITIONAL_ATTRIBUTES,
  SET_CURRENT_CATEGORY,
  SetCurrentCategoryAction,
  FETCH_TOP_CATEGORIES_FOR_REPORT,
  FETCH_CHILD_CATEGORIES_FOR_REPORT,
  RESET_TOP_CATEGORIES_LOADING_STATE_VALUE,
  UPDATE_TOP_CATEGORIES_LOADING_STATE_VALUE,
  FETCH_TOP_CATEGORIES_FOR_FIND_PRODUCTS,
  FETCH_CHILD_CATEGORIES_FOR_FIND_PRODUCTS,
  SET_CATEGORY_EXPAND,
  RESET_CATEGORY_EXPAND,
  LOAD_TOP_CATEGORIES,
  LOAD_TOP_CATEGORIES_FOR_FIND_PRODUCTS,
  SET_TOP_CATEGORIES_PROGRESS_STATE,
  FETCH_CATEGORY_DETAILS_BY_ID,
  LoadCategoryDetailsByIdResponseAction,
} from "./CategoryTypes";

export interface CategoryState {
  topCategory: {
    categoryId: string;
    childCategoryCount: number;
    productCount: number;
    translations: Translations[];
    name: string;
    code: string;
    isPublished: boolean;
  }[];
  childCategory: ChildCategory;
  attributes: AttributeData[];
  unassignedAttributes: AttributeData[];
  variantAttributes: AttributeData[];
  currentCategory: string;
  topCategoriesFetched: boolean;
  findProductsModalTopCategory: {
    categoryId: string;
    childCategoryCount: number;
    productCount: number;
    translations: Translations[];
    name: string;
    code: string;
    isPublished: boolean;
  }[];
  findProductsModalChildCategory: ChildCategory;
  topCategoriesForFindProductsFetched: boolean;
  isCatalogOrStoreChanged: boolean;
  isTopCategoriesLoading: boolean;
}
export interface ChildCategory {
  [key: string]: {
    childCategories: Category[];
  };
}
export interface Category {
  parentId?: string;
  categoryId: string;
  childCategoryCount: number;
  translations: Translations[];
  productCount: number;
  code: string;
  name: string;
  isPublished: boolean;
}

const initialState: CategoryState = {
  topCategory: [],
  childCategory: {},
  attributes: [],
  variantAttributes: [],
  unassignedAttributes: [],
  currentCategory: "",
  topCategoriesFetched: false,
  findProductsModalTopCategory: [],
  findProductsModalChildCategory: {},
  topCategoriesForFindProductsFetched: false,
  isCatalogOrStoreChanged: false,
  isTopCategoriesLoading: false,
};

export const CategoryReducer = (
  state = initialState,
  action: CategoryActionTypes,
): CategoryState => {
  switch (action.type) {
    case FETCH_TOP_CATEGORIES_FOR_REPORT.SUCCESS: {
      const topCategoriesAction = action as LoadTopCategoriesResponseAction;
      return {
        ...state,
        topCategory: topCategoriesAction.payload.results.map((cat) => ({
          categoryId: cat.categoryId,
          childCategoryCount: cat.childCategoryCount,
          productCount: cat.productCount,
          translations: cat.translations,
          code: cat.code,
          name: cat.name,
          isPublished: cat.isPublished,
        })),
      };
    }
    case FETCH_TOP_CATEGORIES.FAILURE:
      return {
        ...state,
        isTopCategoriesLoading: false,
      };
    case FETCH_TOP_CATEGORIES.SUCCESS:
      const topCategoriesAction = action as LoadTopCategoriesResponseAction;
      let topCats: any = [];
      for (const v of topCategoriesAction.payload.results) {
        topCats.push({
          categoryId: v.categoryId,
          childCategoryCount: v.childCategoryCount,
          productCount: v.productCount,
          translations: v.translations,
          code: v.code,
          name: v.name,
          isPublished: v.isPublished,
        });
      }
      return {
        ...state,
        topCategory: topCats,
        topCategoriesFetched: true,
        isTopCategoriesLoading: false,
      };
    case FETCH_CHILD_CATEGORIES_FOR_REPORT.SUCCESS: {
      const childCategoriesAction = action as LoadChildCategoriesResponseAction;
      const categoryResults = childCategoriesAction.payload.results;
      const isTopCategoryExists = categoryResults?.some(
        (e) => e.parentId === "root",
      );
      const categoryId: string = !isTopCategoryExists
        ? categoryResults[0].categoryId
        : "";
      const childCategoriesResults = isTopCategoryExists
        ? categoryResults.filter((e) => e.parentId !== "root")
        : categoryResults?.some((e) => categoryId === e.parentId)
          ? categoryResults?.filter((e) => categoryId === e.parentId)
          : categoryResults;

      const parentCategoryId = childCategoriesResults[0].parentId;
      return {
        ...state,
        childCategory: {
          ...state.childCategory,
          [parentCategoryId]: {
            childCategories: childCategoriesResults.map((node) => ({
              categoryId: node.categoryId,
              childCategoryCount: node.childCategoryCount,
              translations: node.translations,
              productCount: node.productCount,
              code: node.code,
              name: node.name,
              isPublished: node.isPublished,
            })),
          },
        },
      };
    }
    case FETCH_CHILD_CATEGORIES.SUCCESS:
      const childCategoriesAction = action as LoadChildCategoriesResponseAction;
      let childCat: any = [];
      const categoryResults = childCategoriesAction.payload.results ?? [];
      const isTopCategoryExists = categoryResults?.some(
        (e) => e.parentId === "root",
      );
      const categoryId: string = !isTopCategoryExists
        ? categoryResults?.[0]?.categoryId
        : "";
      const childCategoriesResults = isTopCategoryExists
        ? categoryResults?.filter((e) => e.parentId !== "root")
        : categoryResults?.some((e) => categoryId === e.parentId)
          ? categoryResults?.filter((e) => categoryId === e.parentId)
          : categoryResults;

      for (const v of childCategoriesResults) {
        childCat.push({
          categoryId: v.categoryId,
          childCategoryCount: v.childCategoryCount,
          productCount: v.productCount,
          translations: v.translations,
          code: v.code,
          name: v.name,
          isPublished: v.isPublished,
          parentId: v.parentId,
        });
      }
      const existingChildCategories =
        state.childCategory?.[childCat?.[0]?.parentId]?.childCategories ?? [];
      const mergedChildCategories =
        existingChildCategories?.map((existing) => {
          const updated = childCat.find(
            (cat: Category) => cat.categoryId === existing.categoryId,
          );
          return updated || existing;
        }) ?? [];

      const newCategories = childCat?.filter(
        (cat: Category) =>
          !existingChildCategories.some(
            (existing) => existing.categoryId === cat.categoryId,
          ),
      );

      const childCategoryData = childCat[0]
        ? {
            ...state.childCategory,
            [childCat[0].parentId]: {
              childCategories: [...mergedChildCategories, ...newCategories],
            },
          }
        : {
            ...state.childCategory,
          };
      return {
        ...state,
        childCategory: childCategoryData,
      };
    case FETCH_PARENT_CHILD_CATEGORIES.SUCCESS:
      const parentChildCategoriesAction =
        action as LoadChildCategoriesResponseAction;
      let parentChildCat: any = [];
      const parentChildCategoriesResults =
        parentChildCategoriesAction.payload.results;
      for (const v of parentChildCategoriesResults) {
        parentChildCat.push({
          categoryId: v.categoryId,
          childCategoryCount: v.childCategoryCount,
          productCount: v.productCount,
          translations: v.translations,
          code: v.code,
          name: v.name,
          isPublished: v.isPublished,
          parentId: v.parentId,
        });
      }

      const filteredParentChildCat = state.childCategory[
        parentChildCat?.[0]?.parentId
      ]?.childCategories?.map((childCategoryData) => {
        let parentChildCategoryData: any = {};
        for (const childCat of parentChildCat) {
          if (childCat.categoryId === childCategoryData.categoryId) {
            parentChildCategoryData = childCat;
            return parentChildCategoryData;
          }
        }
        if (Object.keys(parentChildCategoryData).length === 0) {
          return childCategoryData;
        } else {
          return parentChildCategoryData;
        }
      });
      const childCategory = filteredParentChildCat
        ? {
            ...state.childCategory,
            [parentChildCat[0].parentId]: {
              childCategories: filteredParentChildCat,
            },
          }
        : {
            //if the category doesnt have child categories & the parent of this category doesnt saved in redux
            //then we are just loading previous redux state. When we open that category it will load the data
            ...state.childCategory,
          };
      return {
        ...state,
        childCategory,
      };
    case SET_ADDITIONAL_ATTRIBUTES:
      const additionalAttributesAction =
        action as SetAdditionalAttributesAction;
      const copiedAttributes = state.unassignedAttributes.slice();
      copiedAttributes.push(
        ...additionalAttributesAction.payload.additionalAttributesData,
      );
      return {
        ...state,
        unassignedAttributes: copiedAttributes,
      };
    case SET_CURRENT_CATEGORY:
      const categoryAction = action as SetCurrentCategoryAction;
      return {
        ...state,
        currentCategory: categoryAction.payload.categoryId,
      };
    case RESET_TOP_CATEGORIES_LOADING_STATE_VALUE:
      return {
        ...state,
        topCategoriesFetched: false,
      };
    case LOAD_TOP_CATEGORIES:
      return {
        ...state,
        topCategoriesFetched: false,
        isTopCategoriesLoading: true,
      };
    case UPDATE_TOP_CATEGORIES_LOADING_STATE_VALUE:
      return {
        ...state,
        topCategoriesFetched: true,
      };
    case RESET_CATEGORY:
      return initialState;
    case LOAD_TOP_CATEGORIES_FOR_FIND_PRODUCTS: {
      return {
        ...state,
        topCategoriesForFindProductsFetched: false,
      };
    }
    case FETCH_TOP_CATEGORIES_FOR_FIND_PRODUCTS.REQUEST: {
      return {
        ...state,
        topCategoriesForFindProductsFetched: false,
      };
    }
    case FETCH_TOP_CATEGORIES_FOR_FIND_PRODUCTS.SUCCESS: {
      const topCategoriesAction = action as LoadTopCategoriesResponseAction;
      return {
        ...state,
        topCategoriesForFindProductsFetched: true,
        findProductsModalTopCategory: topCategoriesAction.payload.results.map(
          (cat) => ({
            categoryId: cat.categoryId,
            childCategoryCount: cat.childCategoryCount,
            productCount: cat.productCount,
            translations: cat.translations,
            code: cat.code,
            name: cat.name,
            isPublished: cat.isPublished,
          }),
        ),
      };
    }
    case FETCH_CHILD_CATEGORIES_FOR_FIND_PRODUCTS.SUCCESS:
      const childCategoriesForFindProductsAction =
        action as LoadChildCategoriesResponseAction;
      let childCatsForFindProducts: any = [];
      const categories =
        childCategoriesForFindProductsAction.payload.results ?? [];
      const isTopCategoryAvailable = categories?.some(
        (e) => e.parentId === "root",
      );
      const category: string = !isTopCategoryAvailable
        ? categories[0].categoryId
        : "";
      const childCategoriesForFindProductsResults = isTopCategoryAvailable
        ? categories?.filter((e) => e.parentId !== "root")
        : categories?.some((e) => category === e.parentId)
          ? categories?.filter((e) => category === e.parentId)
          : categories;

      for (const v of childCategoriesForFindProductsResults) {
        childCatsForFindProducts.push({
          categoryId: v.categoryId,
          childCategoryCount: v.childCategoryCount,
          productCount: v.productCount,
          translations: v.translations,
          code: v.code,
          name: v.name,
          isPublished: v.isPublished,
          parentId: v.parentId,
        });
      }
      const existingCategories =
        state.childCategory?.[childCatsForFindProducts?.[0]?.parentId]
          ?.childCategories ?? [];
      const mergedCategories =
        existingCategories?.map((existing) => {
          const updated = childCatsForFindProducts.find(
            (cat: Category) => cat.categoryId === existing.categoryId,
          );
          return updated || existing;
        }) ?? [];

      const newChildCategories = childCatsForFindProducts?.filter(
        (cat: Category) =>
          !existingCategories.some(
            (existing) => existing.categoryId === cat.categoryId,
          ),
      );
      return {
        ...state,
        findProductsModalChildCategory: {
          ...state.findProductsModalChildCategory,
          [childCategoriesForFindProductsResults[0].parentId]: {
            childCategories: [...mergedCategories, ...newChildCategories],
          },
        },
      };
    case SET_CATEGORY_EXPAND:
      return {
        ...state,
        isCatalogOrStoreChanged: true,
      };
    case RESET_CATEGORY_EXPAND:
      return {
        ...state,
        isCatalogOrStoreChanged: false,
        topCategory: [],
      };
    case SET_TOP_CATEGORIES_PROGRESS_STATE:
      return {
        ...state,
        isTopCategoriesLoading: true,
      };
    case FETCH_CATEGORY_DETAILS_BY_ID.SUCCESS:
      const categoryDetails = action as LoadCategoryDetailsByIdResponseAction;
      const parentId = categoryDetails.payload?.parentId;

      // Return early if no parentId or no existing child categories
      if (!parentId || !state.childCategory[parentId]) {
        return state;
      }

      const childCategories =
        state.childCategory[parentId]?.childCategories ?? [];
      const updatedChildCategories = childCategories.map((existing) => {
        if (existing.categoryId === categoryDetails.payload?.categoryId) {
          return {
            ...existing,
            productCount: categoryDetails.payload?.productCount,
          };
        }
        return existing;
      });

      return {
        ...state,
        childCategory: {
          ...state.childCategory,
          [parentId]: {
            childCategories: updatedChildCategories,
          },
        },
      };
    default:
      return state;
  }
};
